import * as React from 'react';
import { cn } from '../../utils/cn';

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(
					'bg-transparent border border-white outline-none focus:border-mlk-light-blue py-3 px-4 pl-10 rounded-md w-full',
					className,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);
Input.displayName = 'Input';

export { Input };
