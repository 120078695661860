import React from 'react';
import {
	Route,
	BrowserRouter as Router,
	RouterProvider,
	Routes,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import './App.css';
import ErrorPage from './pages/error-page';
import Home from './pages/home';
import { SidebarContextProvider } from './context/sidebarContext';
import Intelligence from './pages/intelligence';
import Alerts from './pages/alerts';
import Login from './pages/login';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SupabaseAuthProvider, { useAuth } from './context/supabaseAuthContext';
import MFAPage from './pages/mfa';
import WelcomePage from './pages/welcome';
import Details from './pages/details';
import PrivateRoutes from './components/authentication/PrivateRoutes';
import PublicRoutes from './components/authentication/PublicRoutes';
import PasswordReset from './pages/password-reset';
import ResetConfirm from './pages/reset-confirm';
import ResetSuccess from './pages/reset-success';
import PasswordResetContext from './components/layout/passwordResetContext';
import data from './utils/appsData';
import { AppsServiceDataType, TDetailsType } from './types/index.types';
import RateYourDay from './pages/rate-your-day';
import { inhabitantById } from './requests/services/rate_your_day/inihabitants';
import InhabitantRating from './pages/inhabitant';
import { Toaster } from './components/ui/toaster';
import History from './pages/history';
import DataManager from './pages/data-manager';
import { getHierarchyData } from './requests/services/data-manager/query';
import DataImport from './pages/data-import';
import DataManagerHierarchyPage from './pages/hierarchy';
import UploadStatusPage from './pages/upload-status-page';

const queryClient = new QueryClient();

function App() {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route element={<PrivateRoutes />}>
					<Route path={'/'} element={<WelcomePage />} />
					<Route
						path={'/details/:appId'}
						element={<Details />}
						loader={({ params }) => {
							const appData = data
								.filter(el => el.id == params.appId)
								.at(0)?.details;
							return appData as TDetailsType;
						}}
					/>
					<Route path={'/intelligence'} element={<Intelligence />} />
					<Route
						path={'/intelligence/data-import'}
						element={<DataImport />}
					/>
					<Route
						path={'/intelligence/data-import/loading'}
						element={<UploadStatusPage />}
					/>
					<Route
						path={'/intelligence/data-import/hierarchy'}
						element={<DataManagerHierarchyPage />}
						loader={async () => await getHierarchyData()}
					/>
					<Route path={'/alerts'} element={<Alerts />} />
					<Route path={'/map'} element={<Home />} />
					<Route
						path={'/ratingApp'}
						element={<RateYourDay />}
						//loader={InhabitantsLoader(queryClient)}
					/>
					<Route
						path={'/data-manager'}
						element={<DataManager />}
						loader={async () => await getHierarchyData()}
					/>
					<Route
						path={'/ratingApp/:inhabitantId'}
						element={<InhabitantRating />}
						loader={({ params }) =>
							inhabitantById(params.inhabitantId as string)
						}
					/>
					<Route
						path={'/ratingApp/history/:inhabitantId'}
						element={<History />}
						loader={({ params }) =>
							inhabitantById(params.inhabitantId as string)
						}
					/>
				</Route>

				<Route element={<PublicRoutes />}>
					<Route
						path={'/password-reset-success'}
						element={<ResetSuccess />}
					/>
					<Route
						path={'/login'}
						element={<Login />}
						errorElement={<ErrorPage />}
					/>
					<Route
						path={'/mfa'}
						element={<MFAPage />}
						errorElement={<ErrorPage />}
					/>
				</Route>
				<Route element={<PasswordResetContext />}>
					<Route
						path={'/password-reset'}
						element={<PasswordReset />}
					/>
					<Route
						path={'/password-reset-confirm'}
						element={<ResetConfirm />}
					/>
				</Route>
			</>,
		),
	);
	return (
		<div className='App bg-mlk-dark-blue min-h-screen text-white'>
			<QueryClientProvider client={queryClient}>
				<SupabaseAuthProvider>
					<SidebarContextProvider>
						<RouterProvider router={router} />
						<Toaster />
					</SidebarContextProvider>
				</SupabaseAuthProvider>
			</QueryClientProvider>
		</div>
	);
}

export default App;
