import React from 'react';
import { Button } from '../ui/button';
import useDataManagerStore from '../../stores/datamanagerStore';
import clsx from 'clsx';
import { ArrowUp } from 'lucide-react';

const ToggleUp = ({ index }: { index: number }) => {
	const moveUp = useDataManagerStore(state => state.moveUp);
	//const data = useDataManagerStore(state => state.items);
	return (
		<Button
			variant='outline'
			type='button'
			onClick={() => {
				moveUp(index - 1, index);
			}}
			size='icon'
			className={clsx(
				index == 2 && 'hidden',
				'rounded-full border-2 hover:bg-mlk-light-blue hover:text-white text-mlk-light-blue font-semibold border-mlk-light-blue w-5 h-5 p-1',
			)}
		>
			<ArrowUp className='w-3 h-3' strokeWidth={5} />
		</Button>
	);
};

export default ToggleUp;
