import useHierarchyDefinitionStore from '../../../stores/hierarchyDefinitionStore';
import supabase from '../../../utils/supabase';

export const getHierarchyData = async (): Promise<
	{ id: number; label: string; level: number }[] | null
> => {
	const setLevels = useHierarchyDefinitionStore.getState().set_levels;
	let { data: hierarchy_definition, error } = await supabase
		.from('hierarchy_definition')
		.select('*')
		.order('id', { ascending: true });
	if (error) {
		throw new Error(error.message);
	}
	if (hierarchy_definition) {
		setLevels(hierarchy_definition.length);
	}

	return hierarchy_definition;
};
