import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { AuthError, AuthMFAChallengeResponse } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import supabase from '../utils/supabase';
import { Input } from '../components/form/textField';
import EnrollmentModal from '../components/authentication/2FAEnrollmentModal';
import Button from '../components/form/button';
import Spinner from '../components/loaders/spinner';
import { KeySvg } from '../components/svgs';
import Logo from '../assets/icon_logo.svg';

const MFAPage = () => {
	const [error, setError] = useState<string | null>();
	const [loading, setLoading] = useState(false);

	let navigate = useNavigate();
	const [factorId, setFactorId] = useState('');
	//const [factorIdToUse, setFactorIdToUse] = useState('');
	const onSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		setError(null);
		/**
		 * check to see if the user is already enrolled on mfa.
		 */
		const { data, error } =
			await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
		if (error) {
			setError(error.message);
			return;
		}
		if (
			data?.nextLevel === 'aal1' &&
			data.nextLevel === data.currentLevel
		) {
			//user does not have MFA enrolled. Use the factor Id from the qr modal

			// Invoke the mfa challenge and verify using the appropriate factorId
			const challengeAndVerify =
				await supabase.auth.mfa.challengeAndVerify({
					factorId,
					code: pinCode,
				});
			if (challengeAndVerify.error) {
				setError(challengeAndVerify.error.message);
				setLoading(false);
				return;
			}

			if (challengeAndVerify.data) {
				setLoading(false);
				//redirect to the dashboard on Successful verification
				navigate('/');
			}
		} else {
			/**
			 *  User has already been enrolled.
			 * Get factorIds from supabase and use the fist one for subsequent logins
			 */

			const factors = await supabase.auth.mfa.listFactors();
			if (factors.error) {
				setError(factors.error.message);
				return;
			}

			const totpFactor = factors.data?.totp[0];

			if (!totpFactor) {
				setError('No TOTP factors found!');
				setLoading(false);
				return;
			}

			// Invoke the mfa challenge and verify using the appropriate factorId
			const challengeAndVerify =
				await supabase.auth.mfa.challengeAndVerify({
					factorId: totpFactor.id,
					code: pinCode,
				});
			if (challengeAndVerify.error) {
				setError(challengeAndVerify.error.message);
				setLoading(false);
				return;
			}

			if (challengeAndVerify.data) {
				setLoading(false);
				//redirect to the dashboard on Successful verification
				navigate('/');
			}
		}
	};

	const [pinCode, setPinCode] = useState('');
	const [open, setOpen] = useState(false);
	useEffect(() => {
		/**
		 * Only show QrCode modal when the user has not been enrolled yet for mfa
		 * Dont show it for subsequent logins to prevent factorId maxed out error
		 */
		//
		(async () => {
			const { data, error } =
				await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
			if (error) {
				setError(error.message);
				return;
			}
			if (
				data?.nextLevel === 'aal1' &&
				data.nextLevel === data.currentLevel
			) {
				setOpen(true);
			}
		})();
	}, []);

	return (
		<div className='flex items-center justify-center h-screen w-full'>
			<div className='bg-[#1A2034] w-full max-w-xl shadow-xl border rounded-lg border-gray-700 py-10 px-8 flex flex-col justify-center items-center'>
				<div className='flex items-center space-x-2 mb-7'>
					<img src={Logo} alt='logo' className='w-14 mr-3' />{' '}
					<p className='font-bold text-5xl'>MLK Kilimanjaro</p>
				</div>
				<AnimatePresence initial={false}>
					<motion.div
						key='mfa'
						className='w-full max-w-sm'
						initial={{ opacity: 0, x: '30%' }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.5 }}
					>
						{/*Password form*/}
						<form className='w-full'>
							<h1 className='font-bold text-xl mb-8'>
								Enter the Authenticator code
							</h1>
							<div className='relative'>
								<p className='absolute left-5 top-0  -translate-y-1/2 z-2 bg-[#1A2034] px-1 font-medium text-mlk-light-blue'>
									2FA Code
								</p>
								<Input
									placeholder='6-digit code'
									type='password'
									value={pinCode}
									onChange={e =>
										setPinCode(e.target.value.trim())
									}
								/>

								<div className='pointer-events-none absolute top-1/2 -translate-y-1/2 left-2'>
									{<KeySvg />}
								</div>
							</div>

							{error && (
								<small className='text-red-500 my-2 block !h-4'>
									{error}
								</small>
							)}
							{/* <div className='mt-3 flex items-center space-x-2'>
					<input
						id='showPassword'
						aria-describedby='candidates-description'
						name='showPassword'
						type='checkbox'
						onChange={handleShowPassword}
						className='h-4 w-4 bg-transparent rounded border-gray-300 text-mlk-light-blue focus:ring-offset-mlk-blue focus:ring-mlk-light-blue'
					/>
					<label htmlFor='showPassword' className='font-medium'>
						Show password
					</label>
				</div> */}
							<small className='text-red-500 my-3 block !h-4'></small>
							<div className='flex space-x-5 justify-between items-center mt-3'>
								<div />
								<p className='text-mlk-light-blue'>
									Contact customer support
								</p>
								<Button
									type='submit'
									onClick={e => onSubmit(e)}
								>
									{loading && (
										<Spinner className='w-[18px]' />
									)}
									<span>Log in</span>
								</Button>
							</div>
						</form>
					</motion.div>
					<EnrollmentModal
						open={open}
						setOpen={setOpen}
						setFactorId={setFactorId}
						//setEnrollment={setIsInitialEnrollment}
					/>
				</AnimatePresence>
			</div>
		</div>
	);
};

export default MFAPage;
