import { useMutation, useQuery } from '@tanstack/react-query';
import supabase from '../../../utils/supabase';
import { THierarchyData } from '../../../types/index.types';

export const insertHierarchy = () => {
	//const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (param: {
			id: number;
			level: number;
			label: string;
		}) => {
			//console.log(param);
			const { data, error } = await supabase
				.from('hierarchy_definition')
				.insert({
					id: param.id,
					level: param.level,
					label: param.label,
				})
				.select();
			if (error) {
				console.log(error.message);
			}
			return data;
		},
	});
};

export const upsertHierarchy = () => {
	return useMutation({
		mutationFn: async (params: THierarchyData[]) => {
			const fixed_labels = [
				'vioscore',
				'dimension',
				'category',
				'attribute',
			];
			const modified = params.map((el, idx) => {
				return {
					...el,
					id: idx + 1,
					label:
						idx == 2
							? 'category'
							: idx == 3
							  ? 'attribute'
							  : idx > 3
							    ? `Level${' '}0${''}${idx + 1}`
							    : el.label,
					level: idx > 1 ? idx + 1 : el.level,
				};
			});

			//console.log(modified);

			const { data, error } = await supabase
				.from('hierarchy_definition')
				.upsert(modified);
			if (error) {
				console.log(error);
			}
		},
	});
};

export const fetchHierarchyData = () =>
	useQuery({
		queryKey: ['hierarchy_fetch'],
		queryFn: async () => {
			const { data, error } = await supabase
				.from('hierarchy_definition')
				.select();

			if (error) {
				console.log(error);
			}
			return data;
		},
	});
