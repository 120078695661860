import { create } from 'zustand';
import { PasswordResetSchema } from '../utils/schema';
import { persist, createJSONStorage } from 'zustand/middleware';
import { AuthChangeEvent } from '@supabase/supabase-js';

interface PasswordResetStore {
	event: AuthChangeEvent | null;
	data: PasswordResetSchema;
	update: (val: Partial<PasswordResetSchema>) => void;
}

const useResetStore = create<PasswordResetStore>()(
	persist(
		set => ({
			data: { password: '', confirm: '' },
			event: null,
			update: val =>
				set(state => ({
					data: { ...state.data, ...val },
				})),
		}),
		{
			name: 'password_reset_state',
			storage: createJSONStorage(() => localStorage),
		},
	),
);

export default useResetStore;
