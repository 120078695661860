import { viewColors } from '../utils/staticData';
import { TNode, ViewType } from '../types/index.types';

export const useLineageFunctions = () => {
	const getLineageName = (d: any, raw?: boolean) => {
		if (raw) {
			return d.name ?? d.secondary_label;
		}
		return d.data.name ?? d.data.secondary_label;
	};
	const getLineageColors = (
		d: any,
		view: ViewType,
		allBlockedNodes: string[],
		nonVioscoreNodes?: string[],
	) => {
		if (d.depth > 0) {
			return {
				bg: allBlockedNodes?.includes(d.data.index)
					? '#F9634E'
					: nonVioscoreNodes?.includes(d.data.index)
					  ? '#ff9902'
					  : viewColors[view as keyof any].mainColor,
				text: viewColors[view as keyof any].textColor,
			};
		} else {
			return { bg: '#454A5A', text: '#FFFFFF' };
		}
	};

	const getNodes: (d: any, indexes: string[], nodeChildren: any[]) => void = (
		d: any,
		indexes: string[],
		nodeChildren: any[],
	) => {
		if (!d) return;
		if (indexes?.includes(d.index)) {
			nodeChildren.push(...d.children);
		}

		if (d.children && d.children.length > 0) {
			for (const child of d.children) {
				getNodes(child, indexes, nodeChildren);
			}
		}
	};

	const loopChildren = (node: any, internalBlockedNodes: any[]) => {
		if (!internalBlockedNodes.includes(node.index)) {
			internalBlockedNodes.push(node.index);
		}
		if (node.children && node.children.length > 0) {
			for (const child of node.children) {
				loopChildren(child, internalBlockedNodes);
			}
		}
	};

	const getBlockedNodes = (d: any, blockNodeArray: string[]) => {
		const _blockedNodes: number[] = [];
		const nodeChildren: any[] = [];
		getNodes(d, blockNodeArray, nodeChildren);
		nodeChildren.forEach((node: any, index: number) => {
			loopChildren(node, _blockedNodes);
		});
		return [..._blockedNodes, ...blockNodeArray];
	};

	const getIntelligenceInfo = (index: string, data: any) => {
		const indexData: string[] = [];
		const splitIndex = index.toString().split('.');
		splitIndex.forEach((_index: string, idx) => {
			if (idx === 0) {
				indexData.push(_index);
			} else {
				indexData.push(`${indexData[idx - 1]}.${_index}`);
			}
		});

		const infoData: TNode[] = [];

		const addInfo = (d: any) => {
			const _options: string[] = [];
			d.forEach((option: any) => {
				!_options.includes(option) &&
					_options.push(option.secondary_label);
			});
			d.forEach((_d: any) => {
				if (indexData.includes(_d.index.toString())) {
					infoData.push({
						level: _d.index.split('.').length,
						label: _d.primary_label,
						value: _d.secondary_label,
						vioscore: _d.vioscore,
						vioscore_max: _d.possible_max,
						weighting: _d.weighting ?? null,
						polarity: _d.children.length === 0 ? _d.polarity : null,
						table: _d.table_name ?? null,
						options: _options,
					});
					_d.children && addInfo(_d.children);
				}
			});
			return;
		};

		infoData.push({
			level: data.index.split('.').length,
			label: data.primary_label,
			value: data.secondary_label,
			vioscore: data.vioscore,
			vioscore_max: data.possible_max,
			weighting: data.weighting ?? null,
			table: data.table_name ?? null,
			options: [data.secondary_label],
		});

		// get info if index === 1
		if (index !== '1') {
			data.children && addInfo(data.children);
		}

		return infoData;
	};

	const getLegend = (d: any) => {
		const legendData: { label: string; icon: string }[] = [];

		legendData.push({ label: d.legend_entry, icon: d.properties.icon });

		const addLegend = (d: any) => {
			d.forEach((_d: any) => {
				console.log(_d);
				!legendData
					.map(entry => entry.label)
					.includes(_d.legend_entry) &&
					legendData.push({
						label: _d.legend_entry,
						icon: _d.properties.icon,
					});
				_d.children && addLegend(_d.children);
			});
			return;
		};

		addLegend(d.children);

		return legendData;
	};

	const setContainerFlex = (
		svgContainerRef: React.MutableRefObject<HTMLDivElement | null>,
	) => {
		setTimeout(() => {
			if (svgContainerRef.current) {
				if (svgContainerRef.current) {
					if (
						svgContainerRef.current.scrollWidth >
						svgContainerRef.current.clientWidth
					) {
						svgContainerRef.current.style.alignItems = 'flex-start';
					} else {
						svgContainerRef.current.style.alignItems = 'center';
					}
					if (
						svgContainerRef.current.scrollHeight >
						svgContainerRef.current.clientHeight
					) {
						svgContainerRef.current.style.justifyContent =
							'flex-start';
					} else {
						svgContainerRef.current.style.justifyContent = 'center';
					}
				}
			}
		}, 200);
	};

	const getOldAncestry = (data: any) => {
		const ancestry: any = {};
		//add key and value to ancestry object
		data?.forEach((d: any) => {
			ancestry[d.label.toLowerCase()] = d.value;
			ancestry[d.label.toLowerCase() + '_weighting'] = parseFloat(
				d.weighting,
			);
			d.polarity &&
				(ancestry[d.label.toLowerCase() + '_polarity'] = d.polarity);
		});
		return ancestry;
	};

	return {
		getLineageColors,
		getLineageName,
		getBlockedNodes,
		getIntelligenceInfo,
		getLegend,
		setContainerFlex,
		getOldAncestry,
	};
};
