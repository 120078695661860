import React, { useEffect, useState } from 'react';
import { Button } from '../components/ui/button';
import VDonought from '../components/rate-your-day/vioscore-donought';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '../components/ui/dialog';
import InhibitantsForm from '../components/rate-your-day/inhibitantsForm';
import VioScoreLookupTable from '../components/rate-your-day/vioscore-lookup-table';
import HouseHoldLookupForm from '../components/rate-your-day/household-lookup-form';
import useInhabitantStore from '../stores/inhabitantStore';
import parsePostcode from '../utils/parsePostcode';
import clsx from 'clsx';
import { fetchInhabitantsByPostcode } from '../requests/services/rate_your_day/inihabitants';
import EditInhabitantModal from '../components/rate-your-day/edit-inhabitant-modal';

const RateYourDay = () => {
	const currentPostcode = useInhabitantStore(state => state.postcode);
	const currentVioscore = useInhabitantStore(state => state.vioscore);
	const { data: inhabitantsData } = fetchInhabitantsByPostcode();
	const [open, setOpen] = useState(false);

	return (
		<div
			className='py-6 px-10 flex flex-col'
			//onSubmit={handleSubmit(onSubmit)}
		>
			<HouseHoldLookupForm />

			<div className='flex flex-col gap-6 pl-[21px] w-full flex-shrink-0'>
				<p
					className={clsx(
						currentPostcode ? 'block' : 'hidden',
						'font-roboto font-normal text-2xl leading-[28.13px] text-white',
					)}
				>
					{parsePostcode(currentPostcode)}
				</p>
				{currentVioscore ? (
					<VDonought
						innerRadius={49}
						outerRadius={60}
						vioscore={Math.round(currentVioscore as number)}
						labeltextSize='23'
					/>
				) : null}

				{/* Only show the table when inkabitant info exists */}
				{inhabitantsData && inhabitantsData.length > 0 ? (
					<VioScoreLookupTable />
				) : inhabitantsData &&
				  inhabitantsData.length === 0 &&
				  currentPostcode &&
				  currentVioscore ? (
					<p className='font-roboto font-bold text-sm leading-[16.41px] text-white'>
						No inhabitants found
					</p>
				) : null}

				{currentPostcode && currentVioscore && (
					<Dialog open={open} onOpenChange={setOpen}>
						{/* <DialogTrigger asChild> */}
						<Button
							className='bg-mlk-light-blue rounded-[4px] w-[168px]'
							onClick={() => setOpen(true)}
						>
							ADD AN INHIBITANT
						</Button>
						{/* </DialogTrigger> */}
						<DialogContent className='sm:max-w-[425px] text-white border-gray-600 md:w-[342px] bg-[#1A2034]'>
							<DialogHeader>
								<DialogTitle>Add an Inhabitant</DialogTitle>
							</DialogHeader>
							<InhibitantsForm setClose={setOpen} />
						</DialogContent>
					</Dialog>
				)}
			</div>
		</div>
	);
};

export default RateYourDay;
