import React from 'react';
import { Card, CardContent, CardHeader } from '../ui/card';
import { Button } from '../ui/button';
import { useSearchParams } from 'react-router-dom';
import useDataImportStore from '../../stores/dataImportStore';

const DatabaseLoadingCard = () => {
	const [_, setSearchParams] = useSearchParams();
	const setHeaders = useDataImportStore(state => state.setHeaders);
	const setRows = useDataImportStore(state => state.setRows);
	return (
		<Card className='w-[620px] h-[183px] rounded-[4px] border-[#313648] bg-mlk-blue '>
			<CardHeader className='font-grotesk text-[20px] font-semibold leading-[24.69px]'>
				{/* {`Importing ${file?.name}`} */}
			</CardHeader>
			<CardContent>
				{/* {isPending ? (
					<div className='flex items-center gap-3 mb-4'>
						<RefreshCw className='text-mlk-light-blue w-6 h-6 animate-spin' />
						<p className='font-semibold text-[12px] font-roboto text-[#E0E0E0]'>
							Importing file into Storage Bucket
						</p>
					</div>
				) : isSuccess ? (
					<div className='flex items-center gap-3 mb-4'>
						<CheckCircleIcon className='text-[#27AE60] w-6 h-6' />
						<p className='font-semibold text-[12px] font-roboto text-[#E0E0E0]'>
							Imported into Storage Bucket Successfully
						</p>
					</div>
				) : isError ? (
					<div className='flex items-center gap-3 mb-4'>
						<XCircle className='text-red-600 w-6 h-6' />
						<p className='font-semibold text-[12px] font-roboto text-[#E0E0E0]'>
							{`Error Importing into Storage Bucket. 4th-ir:
									${error}`}
						</p>
					</div>
				) : null} */}

				<Button
					onClick={() => {
						setHeaders([]);
						setRows([]);
						setSearchParams(undefined);
					}}
					className='bg-mlk-light-blue w-[137px] h-9 rounded-[4px] hover:bg-mlk-light-blue/90 font-grotesk font-bold text-[12px] leading-[14.81px]'
					//disabled={isPending ? true : false}
				>
					DONE
				</Button>
			</CardContent>
		</Card>
	);
};

export default DatabaseLoadingCard;
