import React from 'react';
import { PieChart, Pie, Legend, ResponsiveContainer, Label } from 'recharts';

const data = [
	{ name: 'Health', value: 500, fill: '#FF2858', bg: 'bg-eurologySkyBlue' }, //
	{ name: 'Credit', value: 100, fill: '#FFBD02', bg: 'bg-eurologySkyBlue' }, //
	{ name: 'VioScore', value: 640, fill: '#242c47', bg: 'bg-eurologySkyBlue' },
	{
		name: 'Environment',
		value: 100,
		fill: '#84cc16',
		bg: 'bg-eurologySkyBlue',
	}, //"
	{ name: 'World', value: 40, fill: '#00B8F6', bg: 'bg-eurologySkyBlue' },
	// "#00B8F6",
];

const VDonought = ({
	innerRadius,
	outerRadius,
	cx,
	cy,
	labeltextSize,
	vioscore,
}: {
	innerRadius: number;
	outerRadius: number;
	cx?: number;
	cy?: number;
	labeltextSize?: string;
	vioscore: number;
}) => {
	return (
		<PieChart
			width={175}
			height={150}
			//className="flex flex-row border border-red-600"
		>
			<Pie
				data={data}
				cx={cx || 55}
				cy={cy || 70}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				stroke='none'
				fill='none'
				paddingAngle={0}
				nameKey='name'
				legendType='circle'
				// className="border border-eurologyDarkGray"
				// paddingAngle={0}
				dataKey='value'
			>
				<Label
					width={20}
					value={vioscore}
					fontSize={labeltextSize || '30px'}
					position='center'
					fill='#FFFFFF'
					//content={renderLabel}
				/>
			</Pie>
		</PieChart>
	);
};

export default VDonought;
