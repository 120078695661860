const getInitials = (email: string | undefined): string => {
	if (email) {
		const name = email.substring(0, email.lastIndexOf('@'));
		const firstLetterFromName = name.charAt(0);
		return firstLetterFromName.toUpperCase();
	}
	return 'U';
};

export default getInitials;
