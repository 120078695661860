import React, { FC } from 'react';
import { AlertStreamType, DimensionSubType } from '../types/index.types';
import { useUtilityFunctions } from '../hooks/utilityFunctions';
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
} from '@tanstack/react-table';
import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from './ui/table';
import { ScrollArea } from './ui/scrollArea';
import { DataTablePagination } from './pagination';

interface DataTableProps {
	columns: ColumnDef<AlertStreamType>[];
	data: AlertStreamType[];
}
const AlertTable = ({ columns, data }: DataTableProps) => {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		// defaultColumn: {
		// 	minSize: 180,
		// 	size: Number.MAX_SAFE_INTEGER,
		// 	maxSize: Number.MAX_SAFE_INTEGER,
		// },
	});
	return (
		<div className='flex h-full flex-col py-2'>
			{/* <ScrollArea className='h-[600px] overflow-auto'> */}
			<Table
				className='w-full '
				// style={{
				// 	width: table.getCenterTotalSize(),
				// }}
			>
				<TableHeader>
					{table.getHeaderGroups().map(headerGroup => (
						<TableRow
							className='border-gray-700 text-white'
							key={headerGroup.id}
						>
							{headerGroup.headers.map(header => {
								return (
									<TableHead
										style={{
											width: header.column.getSize(),
										}}
										className='font-semibold text-white z-20'
										key={header.id}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef
														.header,
													header.getContext(),
											  )}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map(row => (
							<TableRow
								className='border-gray-700 text-sm text-gray-300'
								key={row.id}
								data-state={row.getIsSelected() && 'selected'}
							>
								{row.getVisibleCells().map(cell => (
									<TableCell key={cell.id}>
										{flexRender(
											cell.column.columnDef.cell,
											cell.getContext(),
										)}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={columns.length}
								className='h-48 text-center border border-gray-700 text-sm text-gray-300'
							>
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
				<TableFooter></TableFooter>
			</Table>
			{/* </ScrollArea> */}

			<div className=' mt-[50px] px-5'>
				<DataTablePagination table={table} />
			</div>
		</div>
	);
};

export default AlertTable;
