import React, { useContext } from 'react';
import bgImage from '../enso-i.png';
import AppsCard from '../components/welcome/appsCard';
import data from '../utils/appsData';
import { SidebarContext } from '../context/sidebarContext';
import clsx from 'clsx';
import { ScrollArea, ScrollBar } from '../components/ui/scrollArea';
const WelcomePage = () => {
	const { navCollapsed } = useContext(SidebarContext);

	return (
		<>
			<div className='m-6 flex flex-col gap-8 scrollbar-none'>
				<div
					className='w-full h-[265px] bg-cover bg-center'
					style={{ backgroundImage: `url(${bgImage})` }}
				>
					<div className='flex items-start flex-col gap-[15px] px-6 w-1/3 mt-5'>
						<p className='font-medium text-5xl leading-[58.25px] text-white font-roboto'>
							Apps & Services
						</p>
						<p className='text-white font-normal text-xs w-[345px] font-grotesk'>
							Get more from your data, add functionality, and
							enhance your Kilimanjaro experience with our range
							of apps and services. Find out more about each
							offering below, and contact our Sales Team to
							discuss pricing and installation options.
						</p>
					</div>
				</div>

				<div className='grid grid-cols-3 lg:grid-cols-4 grid-flow-row-dense gap-4'>
					{data.map(service => (
						<div key={service.id}>
							<AppsCard
								url={service.url}
								id={service.id}
								icon={service.icon}
								description={service.description}
								subtitle={service.subtitle}
							/>
						</div>
					))}
				</div>
			</div>
		</>
	);
};
export default WelcomePage;
