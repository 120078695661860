import React, { useEffect, useRef, useState } from 'react';
import {
	MinusIcon,
	PlusIcon,
	ViewfinderCircleIcon,
} from '@heroicons/react/24/outline';
import LineageGraph from '../components/graphs/lineageGraph';
import IntelligenceNav from '../components/intelligence/intelligenceNav';
import { TNode, ViewType } from '../types/index.types';
import { useLineageFunctions } from '../hooks/useLineageFunctions';
import IntelligenceKey from '../components/intelligence/intelligenceKey';
import IntelligenceInfoPane from '../components/intelligence/intelligenceInfoPane';
import {
	useFetchAttributesLineage,
	useFetchDataLineage,
	useFetchLineageLegend,
} from '../requests/services/data-lineage/hooks';
import AttributesInfoPane from '../components/intelligence/attributesInfoPane';
import Spinner from '../components/loaders/spinner';


const Intelligence = () => {
	const svgContainerRef = useRef<HTMLDivElement | null>(null);
	const [selectedFilters] = React.useState({
		country: { name: 'Nederland', code: 'NL00' },
		province: { name: '', code: '' },
	});
	const [selectedNodes, setSelectedNodes] = React.useState<TNode[] | null>(
		[],
	);
	const [, setProvinces] = React.useState([]);
	const [lineageData, setLineageData] = React.useState<any>();
	const [filterLoading, setFilterLoading] = useState(true);
	const [view, setView] = useState<ViewType>('Data');
	const [scale, setScale] = useState(1);
	const [initialLoad, setInitialLoad] = useState(true);
	const [legend, setLegend] = useState<{ label: string; icon: string }[]>();
	const [dataLoading, setDataLoading] = useState(true);

	const { getIntelligenceInfo } = useLineageFunctions();
	const {
		data: dataLineageData,
		refetch: refetchDataLineage,
		isSuccess: lineageFetchSuccess,
	} = useFetchDataLineage({ code: 'NL00', view });
	const {
		data: attributesLineageData,
		refetch: refetchAttributesLineage,
		isSuccess: attributesLineageFetchSuccess,
	} = useFetchAttributesLineage({ code: 'NL00', view });
	const { data: legendData } = useFetchLineageLegend(view);

	const clickSelectedNode = (node: any) => {
		setSelectedNodes(getIntelligenceInfo(node.index, lineageData));
	};

	const resetContainerFlex = () => {
		if (svgContainerRef.current) {
			svgContainerRef.current.style.alignItems = 'center';
			svgContainerRef.current.style.justifyContent = 'center';
		}
	};

	const selectView = (view: ViewType) => {
		setView(view);
	};

	const resetAndGetLineageData = async () => {
		setLineageData(null);
		resetContainerFlex();
		setSelectedNodes(null);
		setScale(1);

		// selectedFilters.province.name
		// 	? selectedFilters.province
		// 	: selectedFilters.country,
		if (view === 'Data') {
			refetchDataLineage().then(() => {
				setLineageData(dataLineageData);
			});
		} else {
			refetchAttributesLineage().then(() => {
				setLineageData(attributesLineageData);
			});
		}
	};
	//console.log(lineageData);
	useEffect(() => {
		// if (!filterLoading) {
		// 	console.log('we are starting')
		//console.log('once');
		resetAndGetLineageData().then();
		// }
		//eslint-disable-next-line
		// }, [selectedFilters, filterLoading]);
	}, []);

	useEffect(() => {
		if (view === 'Data' && lineageFetchSuccess) {
			setLineageData(dataLineageData);
			setDataLoading(false);
		}
		if (view === 'Attributes' && attributesLineageFetchSuccess) {
			setLineageData(attributesLineageData);
			setDataLoading(false);
		}
	}, [dataLineageData, attributesLineageData]);

	useEffect(() => {
		if (!initialLoad) {
			setDataLoading(true);
			setLineageData(null);
			resetContainerFlex();
			setSelectedNodes(null);
			setTimeout(() => {
				if (view === 'Data') {
					refetchDataLineage().then(() => {
						setLineageData(dataLineageData);
						setDataLoading(false);
					});
				} else {
					refetchAttributesLineage().then(() => {
						setLineageData(attributesLineageData);
						setDataLoading(false);
					});
				}
			}, 200);
		}
		setInitialLoad(false);
		//eslint-disable-next-line
	}, [view]);

	// useEffect(() => {
	// 	// getProvinces({
	// 	// 	country: selectedFilters.country.name,
	// 	// 	code: selectedFilters.country.code,
	// 	// }).then((res: any) => {
	// 	// 	setProvinces(
	// 	// 		res.data?.map((x: any) => ({
	// 	// 			name: x.region_name,
	// 	// 			code: x.region_code,
	// 	// 		})),
	// 	// 	);
	// 	// 	setFilterLoading(false);
	// 	// });
	// 	// // eslint-disable-next-line
	// }, [selectedFilters.country]);

	const zoomIn = () => {
		setScale(scale + 0.1);
	};
	const zoomOut = () => {
		setScale(scale - 0.1);
	};

	const resetZoom = () => {
		setScale(1);
	};

	useEffect(() => {
		if (legendData) {
			const _legendData: { label: string; icon: string }[] = [];
			for (const [key, value] of Object.entries(legendData)) {
				_legendData.push({ label: key, icon: value as string });
			}
			setLegend(_legendData);
		}
	}, [legendData]);

	// console.log(lineageData);

	return (
		<div className='bg-[#F0F0F0] flex flex-col w-full'>
			<i className='text-[#F9634E] hidden' />
			<IntelligenceNav
				selectView={selectView}
				resetAndGetLineageData={resetAndGetLineageData}
			/>
			<div className='w-full flex relative'>
				{/*<IntelligenceFilter setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} provinces={provinces}/>*/}
				<div
					ref={svgContainerRef}
					className='relative flex flex-col justify-center items-center h-[calc(100vh_-_145px)] w-full overflow-y-auto overflow-x-auto min-h-[calc(100vh_-_145px)]  p-10 duration-200'
				>
					{dataLoading ? (
						<div>
							<Spinner className='text-mlk-dark-blue' />
						</div>
					) : (
						lineageData && (
							<LineageGraph
								data={lineageData}
								clickSelectedNode={clickSelectedNode}
								scale={scale}
								svgContainerRef={svgContainerRef}
								view={view}
							/>
						)
					)}
				</div>
				<div className='absolute left-10 top-10 space-y-2'>
					<button
						className='bg-white rounded-md p-2 flex items-center justify-center hover:bg-mlk-dark-blue group duration-300'
						onClick={zoomIn}
					>
						<PlusIcon className='group-hover:text-white duration-300 w-4 h-4 text-mlk-blue' />
					</button>
					<button
						className='bg-white rounded-md p-2 flex items-center justify-center hover:bg-mlk-dark-blue group duration-300'
						onClick={zoomOut}
					>
						<MinusIcon className='w-4 h-4 text-mlk-blue group-hover:text-white duration-300' />
					</button>
					<button
						className='bg-white rounded-md p-2 flex items-center justify-center hover:bg-mlk-dark-blue group duration-300'
						onClick={resetZoom}
					>
						<ViewfinderCircleIcon className='w-4 h-4 text-mlk-blue group-hover:text-white duration-300' />
					</button>
				</div>
				{legend && <IntelligenceKey legend={legend} />}
				{selectedNodes && view === 'Data' && (
					<IntelligenceInfoPane
						selectedNodes={selectedNodes}
						view={view}
						resetAndGetLineageData={resetAndGetLineageData}
					/>
				)}
				{selectedNodes && view === 'Attributes' && (
					<AttributesInfoPane
						selectedNodes={selectedNodes}
						view={view}
						resetAndGetLineageData={resetAndGetLineageData}
					/>
				)}
			</div>
		</div>
	);
};

export default Intelligence;
