import React, { FC, useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { TNode, ViewType } from '../../types/index.types';

const AttributesInfoPane: FC<{
	selectedNodes: TNode[];
	view: ViewType;
	resetAndGetLineageData: () => Promise<void>;
}> = ({ selectedNodes, view, resetAndGetLineageData }) => {
	const [nodes, setNodes] = useState<TNode[]>([]);
	const [viewCodeButton, setViewCodeButton] = useState(false);

	useEffect(() => {
		const _selectedNodes = selectedNodes;
		setNodes(_selectedNodes);
	}, [selectedNodes]);

	useEffect(() => {
		const lastSelectedNode = nodes[nodes.length - 1];
		setViewCodeButton(lastSelectedNode?.label === 'Code');
	}, [nodes]);

	const viewCode = () => {};

	return (
		<div className='min-w-[300px] w-[300px] duration-300 flex flex-col bg-white h-[calc(100vh_-_145px)] text-mlk-blue pt-5 sticky top-[164px] right-0 overflow-y-auto'>
			<div className='pb-7'>
				<div className='text-black flex space-x-2 px-5'>
					<div className='flex'>
						<InformationCircleIcon className='w-6' />
					</div>
					<h4 className='font-semibold text-xl'>
						{nodes[nodes.length - 1]?.value ?? ''}
					</h4>
				</div>
				<div className='mt-8 space-y-6 px-5'>
					{nodes.map((node, index) => (
						<div key={index} className='space-y-1.5'>
							<p className='text-stone-500 text-sm'>
								Level {node.level}:{' '}
								{node.label === 'Vioscore'
									? 'VioScore™/Non-VioScore™'
									: node.label ?? ''}
							</p>
							<p className='font-semibold'>{node.value}</p>
						</div>
					))}
				</div>
			</div>
			{viewCodeButton && (
				<button
					onClick={viewCode}
					className='cursor-pointer w-fit ml-5 border py-2 px-4 rounded-md border-mlk-light-blue text-mlk-light-blue uppercase font-semibold text-sm'
				>
					View Code
				</button>
			)}
		</div>
	);
};

export default AttributesInfoPane;
