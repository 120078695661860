import { createClient } from '@supabase/supabase-js';
import raise from './envValidation';

// Create a single supabase client for interacting with your database
const supabase = createClient(
	process.env.REACT_APP_SUPABASE_URL ?? raise('supabase url not set'),
	process.env.REACT_APP_SUPABASE_ANON_KEY ??
		raise('supabase anon key not set'),
);

export default supabase;
