import React, { useEffect, useState } from 'react';
import { Input } from './form/textField';

type TProps = {
	value: string;
	onChange: (val: string) => void;
	debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

const DebouncedSearchInput = ({
	value: initialValue,
	onChange,
	debounce = 400,
	...props
}: TProps) => {
	const [value, setValue] = useState(initialValue);
	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value);
		}, debounce);

		return () => clearTimeout(timeout);
	}, [value]);

	return (
		<Input
			{...props}
			value={value}
			onChange={event => setValue(event.target.value)}
			className='max-w-sm border-gray-700 px-5 focus:border-gray-700 focus:ring-gray-700 py-2'
		/>
	);
};

export default DebouncedSearchInput;
