import React, { FC } from 'react';

const IntelligenceKey: FC<{ legend: { label: string; icon: string }[] }> = ({
	legend,
}) => {
	return (
		<div className='shadow rounded-md bg-white p-4 absolute bottom-5 left-10 text-mlk-dark-blue text-sm space-y-2 min-w-[12rem]'>
			{legend.map((item, index) => (
				<div key={index}>
					<div className='flex items-center space-x-2' key={index}>
						<div className='intelligence-icon text-mlk-dark-blue'>
							<div
								dangerouslySetInnerHTML={{ __html: item.icon }}
							/>
						</div>
						<p>{item.label}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default IntelligenceKey;
