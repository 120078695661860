import { Calendar, Text } from 'lucide-react';
import { ReactNode } from 'react';

export const supabase_datatypes: {
	icon: ReactNode;
	label: string;
	value: string;
	text: string;
}[] = [
	{
		icon: <p>#</p>,
		label: '# int2',
		text: 'Signed two-byte integer',
		value: 'smallint',
	},
	{
		icon: <p>#</p>,
		label: '# int4',
		text: 'Signed four-byte integer',
		value: 'integer',
	},
	{
		icon: <p>#</p>,
		label: '# int8',
		text: 'Signed eight-byte integer',
		value: 'bigint',
	},
	{
		icon: <p>#</p>,
		label: 'float4',
		text: 'Single precision floating-point number(4 bytes)',
		value: 'real',
	},
	{
		icon: <p>#</p>,
		label: 'float8',
		text: 'Double precision floating-point number(8 bytes)',
		value: 'double precision',
	},
	{
		icon: <Text />,
		label: 'text',
		text: 'Variable-length character string',
		value: 'text',
	},
	{
		icon: <Text />,
		label: 'varchar',
		text: 'Variable-length character string',
		value: 'character varying',
	},
	{
		icon: <Calendar />,
		label: 'date',
		text: 'Calendar date (year, month, day)',
		value: 'date',
	},
	{
		icon: <Text />,
		label: 'bool',
		text: 'Logical boolean (true/false)',
		value: 'boolean',
	},
];
