import React, { useMemo } from 'react';
import { ITableData } from '../../stores/dataImportStore';

const SelectedCount = ({ row }: { row: ITableData }) => {
	//console.log(row);
	const count = useMemo(
		() =>
			[row.default, row.unique, row.identity, row.is_json]
				.map(el => el)
				.filter(el => el !== 'null' && el !== 'NOT NULL').length,
		[row.default, row.unique, row.is_json, row.identity],
	);
	return <p>{count >= 1 ? count : null}</p>;
};

export default SelectedCount;
