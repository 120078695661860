import React, { FC, useEffect, useRef, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import LineageGraph from '../graphs/lineageGraph';
import Spinner from '../loaders/spinner';
import { useMutationState } from '@tanstack/react-query';

const IntelligencePreviewModal: FC<{
	closePreview: () => void;
	lineageData: any;
}> = ({ lineageData, closePreview }) => {
	const svgContainerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const html = document.querySelector('html');
		html?.classList.add('overflow-hidden');
		return () => {
			html?.classList.remove('overflow-hidden');
		};
	}, []);

	//console.log(lineageData);
	return (
		<div className='fixed top-0 left-0 bg-black bg-opacity-70 w-screen h-screen z-40 flex items-center justify-center'>
			<div className='relative bg-white flex flex-col items-center justify-center h-[400px] w-full max-w-5xl  rounded-md'>
				<div
					className='w-full h-full flex flex-col items-center justify-center overflow-auto'
					ref={svgContainerRef}
				>
					{lineageData ? (
						<LineageGraph
							data={lineageData}
							svgContainerRef={svgContainerRef}
						/>
					) : (
						<Spinner className='text-mlk-dark-blue' />
					)}
				</div>
				<div className='absolute inline-block -right-14 -top-8 text-white'>
					<XCircleIcon
						className='w-8 h-8 text-white'
						onClick={closePreview}
					/>
				</div>
			</div>
		</div>
	);
};

export default IntelligencePreviewModal;
