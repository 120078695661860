import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import supabase from '../../utils/supabase';
import { Input } from '../form/textField';
import Logo from '../../assets/icon_logo.svg';
const EnrollmentModal = ({
	open,
	setOpen, //setEnrollment,
	setFactorId,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setFactorId: React.Dispatch<React.SetStateAction<string>>;
	//setEnrollment: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const [qr, setQR] = useState('');
	const [error, setError] = useState('');
	const [secret, setSecret] = useState('');
	useEffect(() => {
		(async () => {
			const { data, error } = await supabase.auth.mfa.enroll({
				factorType: 'totp',
			});
			if (error) {
				throw error;
			}

			setSecret(data.totp.secret);

			setFactorId(data.id);
			// setEnrollment(true);
			// Supabase Auth returns an SVG QR code which you can convert into a data
			// URL that you can place in an <img> tag.
			setQR(data.totp.qr_code);
		})();
	}, []);
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as='div' className='relative z-20' onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-50 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 z-10 overflow-y-auto'>
					<div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						>
							<Dialog.Panel className='relative transform overflow-hidden border border-gray-700 shadow-2xl rounded-md bg-mlk-blue px-4 pb-4 pt-5 text-left transition-all sm:my-4 sm:w-full sm:max-w-md sm:px-3 sm:py-4'>
								<div className='flex flex-col gap-3 text-center w-full'>
									<div className='w-full h-16  justify-center items-center gap-3.5 inline-flex'>
										<img
											className='w-14 h-8 relative'
											alt='logo'
											src={Logo}
										/>
										<div className='w-px h-[50px] bg-white' />
										<div className='w-[267px] h-16'>
											<span className="text-white text-[34px] font-bold font-['Schibsted Grotesk'] leading-[61px]">
												MLK
											</span>
											<span className="text-white text-[34px] font-medium font-['Schibsted Grotesk'] leading-[61px]">
												{' '}
												Kilimanjaro
											</span>
										</div>
									</div>
									<div className='text-white text-sm font-bold w-[250px] text-center flex flex-wrap self-center'>
										Scan the QR Code with your authenticator
										app or use the set-up key
									</div>
									<img
										src={qr}
										className='w-[267px] h-[263px] self-center'
									/>
									<Input
										value={secret}
										className='text-white flex self-center w-[350px] pl-0 px-4'
										readOnly
										onChange={() => {}}
									/>
									<div className='flex flex-row justify-between items-center w-[350px] self-center'>
										<a
											href='#'
											className="text-mlk-light-blue text-xs font-normal font-['Roboto']"
										>
											Contact customer support
										</a>
										<button
											type='button'
											className=' w-[105px] bg-mlk-light-blue border-mlk-light-blue text-sm font-medium text-white border rounded-md py-1.5 px-3 order-1'
											onClick={() => setOpen(false)}
										>
											Continue
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default EnrollmentModal;
