import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/supabaseAuthContext';
import useMfa from '../../hooks/useMfa';

const PublicRoutes = () => {
	const { user } = useAuth();
	const { userVerified } = useMfa();

	return userVerified && user ? <Navigate to='/' /> : <Outlet />;
};

export default PublicRoutes;
