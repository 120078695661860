import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAuth } from '../../context/supabaseAuthContext';
import { useNavigate } from 'react-router-dom';

const LogOutModal: FC<{
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
	const { signOut, user } = useAuth();
	const navigate = useNavigate();
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as='div' className='relative z-20' onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-50 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 z-10 overflow-y-auto'>
					<div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						>
							<Dialog.Panel className='relative transform overflow-hidden border border-gray-700 shadow-2xl rounded-md bg-mlk-blue px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-sm sm:px-6 sm:py-7'>
								<div className='grid grid-cols-1 gap-4'>
									<button
										type='button'
										className='bg-transparent border-mlk-light-blue text-mlk-light-blue border rounded-md py-1.5 px-3 order-2'
										onClick={() => setOpen(false)}
									>
										Cancel
									</button>
									<button
										type='button'
										className='bg-mlk-light-blue border-mlk-light-blue text-white border rounded-md py-1.5 px-3 order-1'
										onClick={signOut}
									>
										Log Out
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default LogOutModal;
