import React from 'react';
import { Button } from '../ui/button';

const Contact = () => {
	const openEmail = () => {
		// create an a tag that opens a mailto
		const a = document.createElement('a');
		a.href = 'mailto:info@mylifekit.io';
		a.click();
		// remove the a tag
		a.remove();
	};

	const openPhone = () => {
		// create an a tag that opens a mailto
		const a = document.createElement('a');
		a.href = 'tel:+44 7932 721997';
		a.click();
		// remove the a tag
		a.remove();
	};

	return (
		<div
			className='flex flex-col items-start gap-3 justify-center py-10'

			//id='contact'
		>
			<h4 className='font-medium text-3xl leading-[35.16px]'>
				Contact Us
			</h4>
			<h5 className='font-normal mb-2 text-sm leading-[16.41px]'>
				If you are ready to order, or would like more information about
				this product, use one of the buttons below and our sales team
				will contact you shortly.
			</h5>
			<div className='flex gap-2 mt-3'>
				<Button
					onClick={openEmail}
					variant='outline'
					className='border font-grotesk rounded-[4px] font-bold text-xs leading-[14.81px] text-white border-mlk-light-blue w-[137px] bg-mlk-light-blue h-9'
				>
					EMAIL SALES
				</Button>
				<Button
					onClick={openPhone}
					variant='outline'
					className='font-grotesk rounded-[4px] font-bold text-xs leading-[14.81px] text-mlk-light-blue hover:text-white w-[137px]bg-mlk-light-blue h-9 hover:bg-mlk-light-blue border border-mlk-light-blue bg-[#1A2034]'
				>
					+44 7932 721997
				</Button>
			</div>
		</div>
	);
};

export default Contact;
