import { create } from 'zustand';
import { PasswordResetSchema } from '../utils/schema';
import { persist, createJSONStorage } from 'zustand/middleware';
import { AuthChangeEvent } from '@supabase/supabase-js';

interface IPostCode {
	postcode: string;
	updateCode: (code: string) => void;
	creator_id: string;
	vioscore: number | null;
	updateVioscore: (val: number) => void;
	updateId: (val: string) => void;
}

const useInhabitantStore = create<IPostCode>()(
	persist(
		set => ({
			postcode: '',
			creator_id: '',
			vioscore: 0,
			updateVioscore: val => set({ vioscore: val }),
			updateId: val => set({ creator_id: val }),
			updateCode: val =>
				set({
					postcode: val,
				}),
		}),
		{
			name: 'lastest_lookup',
			storage: createJSONStorage(() => localStorage),
		},
	),
);

export default useInhabitantStore;
