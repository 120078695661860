import {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from 'react';

interface SidebarContextType {
	navCollapsed: boolean | null;
	setNavCollapsed: Dispatch<SetStateAction<boolean | null>>;
}

export const SidebarContext = createContext<SidebarContextType>({
	navCollapsed: null,
	setNavCollapsed: () => {},
});

export const SidebarContextProvider: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [navCollapsed, setNavCollapsed] = useState<boolean | null>(null);
	useEffect(() => {
		setNavCollapsed(localStorage.getItem('mlkKiliNav') === 'true');
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		navCollapsed !== null &&
			localStorage.setItem(
				'mlkKiliNav',
				JSON.stringify(navCollapsed) || 'false',
			);
	}, [navCollapsed]);

	return (
		<SidebarContext.Provider value={{ navCollapsed, setNavCollapsed }}>
			{navCollapsed !== null && children}
		</SidebarContext.Provider>
	);
};
