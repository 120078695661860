import { create } from 'zustand';

interface Status {
	is_start_import: boolean;
	is_table_created: boolean;
	is_columns_created: boolean;
	is_rows_created: boolean;
	is_added_to_intelligence: boolean;
	is_imported_to_storage: boolean;
	start_process: (val: boolean) => void;
	reset_store: () => void;
}

const initialState = {
	is_start_import: false,
	is_table_created: false,
	is_columns_created: false,
	is_rows_created: false,
	is_added_to_intelligence: false,
	is_imported_to_storage: false,
};

const useLoadigStatusStore = create<Status>()(set => ({
	...initialState,
	start_process: val =>
		set(state => ({
			is_start_import: val,
		})),
	reset_store: () => set(initialState),
}));

export default useLoadigStatusStore;
