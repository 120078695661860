import {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import { AlertStreamType, WebsocketConnectionType } from '../types/index.types';

interface AlertContextType {
	alerts: {
		today: AlertStreamType[] | null;
		current: AlertStreamType[] | null;
	};
	setAlerts: Dispatch<
		SetStateAction<{
			today: AlertStreamType[] | null;
			current: AlertStreamType[] | null;
		}>
	>;
	//connection: WebsocketConnectionType;
}

export const AlertContext = createContext<AlertContextType>({
	alerts: { today: null, current: null },
	setAlerts: () => {},
	//connection: 'Not Connected',
});

export const AlertContextProvider: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const TODAY_WS_URL = process.env.REACT_APP_WS_TODAY_URL!;
	const CURRENT_WS_URL = process.env.REACT_APP_WS_CURRENT_URL!;
	const [alerts, setAlerts] = useState<{
		today: AlertStreamType[] | null;
		current: AlertStreamType[] | null;
	}>({ today: null, current: null });
	// const [connection, setConnection] =
	// 	useState<WebsocketConnectionType>('Not Connected');
	// const clientRef = useRef<any>(null);
	// const [waitingToReconnect, setWaitingToReconnect] =
	// 	useState<boolean>(false);
	// const [todayData, setTodayData] = useState<any>();
	// const [currentData, setCurrentData] = useState<any>();
	// const [messages, setMessages] = useState<any>([]);

	// const addMessage = (message: any) => {
	//     setMessages([...messages, message]);
	// }

	// useEffect(() => {
	// 	if (waitingToReconnect) {
	// 		return;
	// 	}

	// 	// Only set up the websocket once
	// 	if (!clientRef.current) {
	// 		// we are using today as the main connection
	// 		const client = new WebSocket(TODAY_WS_URL);
	// 		const currentClient = new WebSocket(CURRENT_WS_URL);
	// 		clientRef.current = client;

	// 		// @ts-ignore
	// 		window.client = client;

	// 		// client.onerror = (e) => console.error(e);

	// 		client.onopen = () => {
	// 			setConnection('Connected');
	// 		};

	// 		client.onclose = () => {
	// 			if (clientRef.current) {
	// 				// Connection failed
	// 				setConnection('Not Connected');
	// 				// console.log('ws closed by server');
	// 			} else {
	// 				// Cleanup initiated from app side, can return here, to not attempt a reconnect
	// 				setConnection('Not Connected');
	// 				// console.log('ws closed by app component unmount');
	// 				return;
	// 			}

	// 			if (waitingToReconnect) {
	// 				return;
	// 			}

	// 			// Parse event code and log
	// 			setConnection('Disconnected');
	// 			// console.log('ws closed');

	// 			// Setting this will trigger a re-run of the effect,
	// 			// cleaning up the current websocket, but not setting
	// 			// up a new one right away
	// 			setWaitingToReconnect(true);

	// 			// This will trigger another re-run, and because it is false,
	// 			// the socket will be set up again
	// 			setTimeout(() => setWaitingToReconnect(false), 5000);
	// 		};

	// 		client.onmessage = message => {
	// 			setTodayData(JSON.parse(message.data));
	// 			// addMessage(`received '${message.data}'`);
	// 		};
	// 		//
	// 		currentClient.onmessage = message => {
	// 			setCurrentData(JSON.parse(message.data));
	// 			// addMessage(`received '${message.data}'`);
	// 		};

	// 		return () => {
	// 			// console.log('Cleanup');
	// 			// Dereference, so it will set up next time
	// 			clientRef.current = null;

	// 			client.close();
	// 		};
	// 	}
	// }, [waitingToReconnect, CURRENT_WS_URL, TODAY_WS_URL]);

	// useEffect(() => {
	// 	setAlerts({ ...alerts, today: todayData, current: currentData });

	// 	//eslint-disable-next-line
	// }, [todayData, currentData]);

	return (
		<AlertContext.Provider value={{ alerts, setAlerts }}>
			{children}
		</AlertContext.Provider>
	);
};
