import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDataLineage } from './data-lineage.service';
import { ViewType } from '../../../types/index.types';

export const useFetchDataLineage = ({
	code,
	view,
}: {
	code: string;
	view: ViewType;
}) => {
	const { getDataLineageData } = useDataLineage();
	return useQuery({
		queryKey: ['data_lineage', code],
		queryFn: () => getDataLineageData({ code }),
		enabled: view === 'Data',
	});
};

export const useFetchAttributesLineage = ({
	code,
	view,
}: {
	code: string;
	view: ViewType;
}) => {
	const { getAttributesLineageData } = useDataLineage();
	return useQuery({
		queryKey: ['attributes_lineage', code],
		queryFn: () => getAttributesLineageData({ code }),
		enabled: view === 'Attributes',
	});
};

export const useFetchLineageLegend = (view: ViewType) => {
	const { getLineageLegend } = useDataLineage();
	return useQuery({
		queryKey: ['lineage_legend', view],
		queryFn: () => getLineageLegend(view),
	});
};

export const useEditIntelligence = (resetFunc: () => Promise<void>) => {
	const { editIntelligenceView, refreshTables } = useDataLineage();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: editIntelligenceView,
		onSuccess: async () => {
			await refreshTables();
			await resetFunc();
		},
	});
};
