import { Table } from '@tanstack/react-table';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from './ui/select';
import { Button } from './ui/button';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import clsx from 'clsx';

interface DataTablePaginationProps<TData> {
	table: Table<TData>;
}

export function DataTablePagination<TData>({
	table,
}: DataTablePaginationProps<TData>) {
	const { pageIndex, pageSize } = table.getState().pagination;
	//console.log(pageIndex, table.getRowCount());
	return (
		<div className='flex items-center px-2 gap-20 w-full'>
			<div className='text-sm text-muted-foreground'>
				{pageIndex == 0 ? 1 : pageIndex * pageSize + 1}
				{' - '}
				{pageIndex == 0 && table.getRowCount() >= pageSize
					? pageSize
					: pageIndex == 0 && table.getRowCount() < pageSize
					  ? table.getRowCount()
					  : !table.getCanNextPage()
					    ? table.getRowCount()
					    : (pageIndex + 1) * pageSize}{' '}
				of {table.getRowCount()}
			</div>
			<div className='flex items-center flex-1 space-x-6 lg:space-x-8'>
				<div className='flex items-center space-x-2'>
					<p className='text-xs font-medium'>Rows per page</p>
					<Select
						value={`${table.getState().pagination.pageSize}`}
						onValueChange={value => {
							table.setPageSize(Number(value));
						}}
					>
						<SelectTrigger className='h-8 w-[72px] ring-offset-mlk-dark-blue focus:ring-mlk-dark-blue focus:ring-offset-0 bg-mlk-dark-blue border border-mlk-dark-blue'>
							<SelectValue
								placeholder={
									table.getState().pagination.pageSize
								}
							/>
						</SelectTrigger>
						<SelectContent
							side='top'
							className='bg-white text-mlk-dark-blue mb-2'
						>
							{[10, 25, 30, 50, 75, 100].map(pageSize => (
								<SelectItem
									key={pageSize}
									value={`${pageSize}`}
								>
									{pageSize}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className='flex items-center space-x-2'>
				<Button
					//variant='outline'
					className='rounded-[4px] h-8 w-8 p-0 bg-mlk-dark-blue text-mlk-light-blue border-mlk-dark-blue'
					onClick={() => table.setPageIndex(pageIndex - 1)}
					disabled={!table.getCanPreviousPage()}
				>
					<span className='sr-only'>Go to previous page</span>
					<ChevronLeftIcon className='h-4 w-4' />
				</Button>
				{Array.from({ length: table.getPageCount() }, (_, idx) => (
					<Button
						key={idx}
						//variant='outline'
						className={clsx(
							'rounded-[4px] focus:text-mlk-dark-blue h-8 w-8 p-0 bg-mlk-dark-blue text-mlk-light-blue border-mlk-dark-blue',
							idx == pageIndex && 'bg-white text-mlk-dark-blue',
						)}
						onClick={() => table.setPageIndex(idx)}
						//disabled={!table.getCanPreviousPage()}
					>
						{idx + 1}
					</Button>
				))}
				<Button
					variant='outline'
					className='rounded-[4px] h-8 w-8 p-0 bg-mlk-dark-blue text-mlk-light-blue border-mlk-dark-blue'
					onClick={() => table.setPageIndex(pageIndex + 1)}
					disabled={!table.getCanNextPage()}
				>
					<span className='sr-only'>Go to next page</span>
					<ChevronRightIcon className='h-4 w-4' />
				</Button>
			</div>
		</div>
	);
}
