import React, { useMemo, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../ui/table';
import useInhabitantStore from '../../stores/inhabitantStore';
import { fetchInhabitantsByPostcode } from '../../requests/services/rate_your_day/inihabitants';
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
	getFilteredRowModel,
} from '@tanstack/react-table';
import { LookupColumns } from './lookupTableColumns';
import { DataTablePagination } from '../pagination';
import DebouncedSearchInput from '../debouncedSearchInput';

const VioScoreLookupTable = () => {
	const { data } = fetchInhabitantsByPostcode();
	const currentVioscore = useInhabitantStore(state => state.vioscore);
	const tableData = useMemo(() => {
		let res;
		if (data) {
			res = data.flatMap(el => [
				{ ...el, vioscore: Math.round(currentVioscore!) },
			]);
			return res;
		}
		return [];
	}, [data, currentVioscore]);
	const [globalFilter, setGlobalFilter] = useState('');

	const table = useReactTable({
		data: tableData,
		columns: LookupColumns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onGlobalFilterChange: setGlobalFilter,
		state: {
			globalFilter,
		},
	});

	return (
		<div className='flex flex-col w-full'>
			<div className=' pb-6'>
				<DebouncedSearchInput
					value={globalFilter ?? ''}
					onChange={value => setGlobalFilter(value)}
					placeholder='Search Inhabitants...'
				/>
			</div>
			<Table
				className='w-full'
				// style={{
				// 	width: table.getCenterTotalSize(),
				// }}
			>
				<TableHeader className='bg-inherit font-roboto font-bold text-sm leading-8 w-full'>
					{table.getHeaderGroups().map(headerGroup => (
						<TableRow
							className='border-gray-700 text-white text-left'
							key={headerGroup.id}
						>
							{headerGroup.headers.map(header => {
								return (
									<TableHead
										colSpan={
											header.id === 'actions' ? 2 : 1
										}
										style={{
											width: header.column.getSize(),
										}}
										className='font-semibold text-white z-20 p-0'
										key={header.id}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef
														.header,
													header.getContext(),
											  )}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map(row => (
							<TableRow
								className='border-gray-700 text-sm text-gray-300'
								key={row.id}
								data-state={row.getIsSelected() && 'selected'}
							>
								{row.getVisibleCells().map(cell => (
									<TableCell key={cell.id} className='pl-0'>
										{flexRender(
											cell.column.columnDef.cell,
											cell.getContext(),
										)}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={LookupColumns.length}
								className='h-48 text-center border border-gray-700 text-sm text-gray-300'
							>
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<div className='w-full mt-[20px]'>
				<DataTablePagination table={table} />
			</div>
		</div>
	);
};

export default VioScoreLookupTable;
