import React from 'react';

const Pricing = () => {
	return (
		<div
			//data-section
			//	id='pricing'
			className='flex flex-col items-start gap-2 border-b border-b-white/20 justify-center py-10'
			//id='pricing'
		>
			<h4 className='font-medium text-3xl leading-[35.16px]'>Pricing</h4>
			<h5 className='font-normal mb-2 text-xl leading-[23.44px]'>
				Pricing available on request
			</h5>
			<p className='font-normal text-sm leading-[16.41px]'>
				Billed annually
			</p>
		</div>
	);
};

export default Pricing;
