import React, { useContext, useEffect, useState } from 'react';
import AlertTable from '../components/alertTable';
import Spinner from '../components/loaders/spinner';
import EmptyAlert from '../assets/empty_alert.svg';
import clsx from 'clsx';
import { useFetchTodaysAlerts } from '../requests/services/alerts/hooks';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { columns } from '../components/columns';
import { AlertStreamType } from '../types/index.types';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

const Alerts = () => {
	const { data: alerts, isLoading, isSuccess } = useFetchTodaysAlerts();

	return (
		<div className='py-7 px-10'>
			<div
				className={clsx(
					!alerts ? 'justify-center' : '',
					'bg-mlk-blue border rounded-md border-gray-600 py-7  flex flex-col items-center',
				)}
			>
				{isLoading ? (
					<div className='text-mlk-light-blue h-[calc(100vh_-_195px)] flex items-center justify-center'>
						<Spinner />
					</div>
				) : (
					<div className='flex flex-col justify-between w-full h-full'>
						<div>
							<div className='flex items-center px-3 gap-2'>
								<Link
									to='/'
									className='rounded-full w-8 h-8 hover:border hover:border-white/10 flex items-center justify-center p-1'
								>
									<ArrowLeft
										size={24}
										className='text-mlk-light-blue'
									/>
								</Link>
								<h1 className='text-3xl'>Today's Alerts</h1>
							</div>
							{alerts && alerts.length > 0 ? (
								<AlertTable data={alerts} columns={columns} />
							) : (
								<div className='flex w-full flex-col items-center justify-center h-[calc(100vh_-_195px)]'>
									<img
										src={EmptyAlert}
										alt='world globe'
										className='w-24'
									/>
									<p className='mt-5 text-gray-300'>
										No alerts yet
									</p>
								</div>
							)}
						</div>
						{/* {getTodayData(alerts).length > 10 && (

							<div className='mt-10'>
								<Pagination setDisplayData={setDisplayData} />
							</div>
						)} */}
					</div>
				)}
			</div>
		</div>
	);
};

export default Alerts;
