import React, {
	Dispatch,
	FC,
	FormEventHandler,
	SetStateAction,
	useState,
} from 'react';
import { motion } from 'framer-motion';
import Button from '../form/button';
import { PadLockSvg } from '../svgs';
import Spinner from '../loaders/spinner';
import { FormCurrentState } from '../../types/index.types';
import { useFormContext } from 'react-hook-form';
import { Login } from '../../utils/schema';
import { Input } from '../form/textField';
import supabase from '../../utils/supabase';
import { AuthError } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';

const PasswordForm = ({
	setFormState,
}: {
	setFormState: Dispatch<SetStateAction<FormCurrentState>>;
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleShowPassword: React.ChangeEventHandler<
		HTMLInputElement
	> = e => {
		const { checked } = e.target;
		checked ? setShowPassword(true) : setShowPassword(false);
	};
	const [error, setError] = useState<AuthError | null>();
	const navigate = useNavigate();
	const {
		formState: { errors },
		trigger,
		register,
		handleSubmit,
		getValues,
	} = useFormContext<Login>();
	const onSubmit = async (e: any) => {
		e.preventDefault();
		const validated = await trigger('password');
		const { username, password } = getValues();
		if (validated) {
			const { data, error } = await supabase.auth.signInWithPassword({
				email: username,
				password,
			});
			if (error) {
				setError(error);
				return;
			}

			navigate('/mfa');
		}
	};

	return (
		<motion.div
			className='w-full max-w-sm'
			initial={{ opacity: 0, x: '30%' }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.5 }}
		>
			{/*Password form*/}
			<form className='w-full'>
				<h1 className='font-bold text-xl mb-8'>Enter your password</h1>
				<div className='relative'>
					<p className='absolute left-5 top-0  -translate-y-1/2 z-2 bg-[#1A2034] px-1 font-medium text-mlk-light-blue'>
						Password
					</p>
					<Input
						type={showPassword ? 'text' : 'password'}
						{...register('password')}
						placeholder='Your password'
					/>

					<div className='pointer-events-none absolute top-1/2 -translate-y-1/2 left-2'>
						{<PadLockSvg />}
					</div>
				</div>
				{errors.password && (
					<small className='text-red-500 my-2 block !h-4'>
						{errors.password.message as string}
					</small>
				)}
				{error && (
					<small className='text-red-500 my-2 block !h-4'>
						{error.message}
					</small>
				)}
				<div className='mt-3 flex items-center space-x-2'>
					<input
						id='showPassword'
						aria-describedby='candidates-description'
						name='showPassword'
						type='checkbox'
						onChange={handleShowPassword}
						className='h-4 w-4 bg-transparent rounded border-gray-300 text-mlk-light-blue focus:ring-offset-mlk-blue focus:ring-mlk-light-blue'
					/>
					<label htmlFor='showPassword' className='font-medium'>
						Show password
					</label>
				</div>
				<small className='text-red-500 my-3 block !h-4'></small>
				<div className='flex space-x-5 justify-between items-center mt-3'>
					<div />
					<p className='text-mlk-light-blue'>
						Forgotten your password?
					</p>
					<Button type='submit' onClick={e => onSubmit(e)}>
						{/* {loading && <Spinner className='w-[18px]' />} */}
						<span>Next</span>
					</Button>
				</div>
			</form>
		</motion.div>
	);
};

export default PasswordForm;
