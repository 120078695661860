const parsePostcode = (val: string): string => {
	const valWithoutWhiteSpaces = val.replace(/ /g, '').toUpperCase();
	//const postcodeArr = valWithoutWhiteSpaces.split('');
	const postcodeWithSpacing =
		valWithoutWhiteSpaces.substring(0, 4) +
		' ' +
		valWithoutWhiteSpaces.substring(4, 6) +
		' ' +
		valWithoutWhiteSpaces.substring(6);
	return postcodeWithSpacing;
};

export default parsePostcode;
