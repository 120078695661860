import {
	UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { IHistory, ILookup, Inhabitant } from '../../../types/index.types';
import supabase from '../../../utils/supabase';
import useInhabitantStore from '../../../stores/inhabitantStore';
import capitalize from '../../../utils/capitalize';
import { toast } from '../../../components/ui/use-toast';

export const insertLookupTable = async (
	params: Omit<ILookup, 'created_at' | 'id'>,
) => {
	const { error } = await supabase
		.from('household_lookup')
		.insert({ ...params });
	if (error) {
		console.log(error.message);
	}
};

export const updateLookupTable = async (
	existingPostcodeId: string,
	params: Partial<ILookup>,
) => {
	const { error } = await supabase
		.from('household_lookup')
		.update({ ...params })
		.eq('id', existingPostcodeId);
	if (error) {
		console.log(error);
	}
};
export const checkPostcodeExistence = async (
	postCode: string,
): Promise<ILookup | null> => {
	const { data, error } = await supabase
		.from('household_lookup')
		.select()
		.eq('postcode', postCode)
		.maybeSingle();

	if (error) {
		console.log(error);
	}

	return data;
};

const checkInhabitantExistence = async (params: Partial<Inhabitant>) => {
	const currentPostcode = useInhabitantStore.getState().postcode;

	const { data, error } = await supabase
		.from('inhabitants')
		.select()
		.match({
			first_name: capitalize(params.first_name!),
			last_name: capitalize(params.last_name!),
			dob: params.dob,
			postcode: currentPostcode.replace(/ /g, '').toUpperCase(),
		})
		.maybeSingle();

	if (error) {
		console.log(error);
	}
	//console.log(data);
	return data;
};

export const insertInhabitant = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: Partial<Inhabitant>) => {
			//does the new entry already exist ...?
			const isInhabitantExist = (await checkInhabitantExistence(
				params,
			)) as Inhabitant | null;

			if (!isInhabitantExist) {
				//doesnt exist
				const { data, error } = await supabase
					.from('inhabitants')
					.insert({ ...params });
				if (error) {
					console.log(error);
				}
				return;
			}

			if (isInhabitantExist) {
				//	console.log(`${isInhabitantExist?.first_name} ${isInhabitantExist.last_name} already Exists`);
				throw new Error(
					`${isInhabitantExist?.first_name} ${isInhabitantExist.last_name} already Exists`,
				);
			}
		},
		onSuccess: () => {
			return queryClient.invalidateQueries({
				queryKey: ['get_inhabitants_by_postcode'],
			});
		},
	});
};

export const inhabitantById = async (id: string) => {
	const { data, error } = await supabase
		.from('inhabitants')
		.select()
		.eq('id', id)
		.maybeSingle();

	if (error) {
		console.log(error);
	}
	return data;
};

export const fetchInhabitantById = (
	id: string,
): UseQueryResult<Inhabitant | null> =>
	useQuery({
		queryKey: ['fetch_by_id', id],
		queryFn: async () => {
			const { data, error } = await supabase
				.from('inhabitants')
				.select()
				.eq('id', id)
				.maybeSingle();

			if (error) {
				console.log(error);
			}
			return data;
		},
		//enabled: id.length > 0, //This is to ensure the query only fires when id is available
	});

export const updateInhabitant = (id: string) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (params: Partial<Inhabitant>) => {
			// const isInhabitantExist = (await checkInhabitantExistence(
			// 	params,
			// )) as Inhabitant | null;

			// if (isInhabitantExist) {
			// 	//	console.log(`${isInhabitantExist?.first_name} ${isInhabitantExist.last_name} already Exists`);
			// 	throw new Error(
			// 		`Cant Edit with details as inhabitant already Exists`,
			// 	);
			// }

			const { data, error } = await supabase
				.from('inhabitants')
				.update({ ...params })
				.eq('id', id);
			if (error) {
				console.log(error);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get_inhabitants_by_postcode'],
			});
			queryClient.invalidateQueries({ queryKey: ['fetch_by_id', id] });
		},
	});
};

export const updateInhabitantRating = (id: string) => {
	const queryClient = useQueryClient();
	const vioscore = useInhabitantStore.getState().vioscore;
	return useMutation({
		mutationFn: async (params: number) => {
			const jsonData = {
				created_at: new Date(),
				rating: params,
				vioscore,
			};
			const { error } = await supabase.rpc(
				'update_inhabitant_rating_history',
				{
					inhabitant_id: id,
					rating_data: jsonData,
				},
			);

			if (error) {
				console.log(error);
			}
		},
		onSuccess: () => {
			return queryClient.invalidateQueries({
				queryKey: ['get_inhabitants_data', 'get_user_rating_history'],
			});
		},
	});
};

export const fetchRatingHistory = (
	id: string,
): UseQueryResult<Pick<Inhabitant, 'rating_history'> | null> => {
	//1. fetch all the lookups for the current user and get their inhabitants ratings ;
	//const creatorId = useInhabitantStore(state => state.creator_id);
	//const postcode = useInhabitantStore(state => state.postcode);
	return useQuery({
		queryKey: ['get_user_rating_history', id],
		queryFn: async () => {
			const { data, error } = await supabase
				.from('inhabitants')
				.select('rating_history')
				.eq('id', id)
				.maybeSingle();

			if (error) {
				console.log(error);
			}
			return data;
		},
		//enabled: !!id, //This is to ensure the query only fires when id is available
	});
};

export const fetchInhabitantsByPostcode = (): UseQueryResult<
	Omit<Inhabitant, 'rating_history' | 'postcode' | 'created_at'>[]
> => {
	const postcode = useInhabitantStore(state => state.postcode);
	return useQuery({
		queryKey: ['get_inhabitants_by_postcode', postcode],
		queryFn: async () => {
			const { data, error } = await supabase
				.from('inhabitants')
				.select('id, first_name, last_name,dob, gender')
				.eq('postcode', postcode.replace(/ /g, '').toUpperCase())
				.order('created_at', { ascending: false });
			if (error) {
				console.log(error);
			}
			return data;
		},
		//enabled: !!id, //This is to ensure the query only fires when id is available
	});
};

export const deleteInhabitant = (id: string) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async () => {
			const { data, error } = await supabase
				.from('inhabitants')
				.delete()
				.eq('id', id);
			if (error) {
				console.log(error);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get_inhabitants_by_postcode'],
			});
			//queryClient.invalidateQueries({ queryKey: ['fetch_by_id', id] });
		},
	});
};
