import React, { FC, useContext, useState } from 'react';
import Notification from '../../../assets/icon_notification.svg';
import { LogOutSvg, NetworkSvg } from '../../svgs';
import Logo from '../../../assets/icon_logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import LogOutModal from '../../authentication/LogOutModal';
import { useFetchTodaysAlerts } from '../../../requests/services/alerts/hooks';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '../../ui/dropdown-menu';
import { LogOut } from 'lucide-react';
import { Avatar, AvatarFallback } from '../../ui/avatar';
import { useAuth } from '../../../context/supabaseAuthContext';
import getInitials from '../../../utils/getInitials';

const Nav: FC = () => {
	const [openLogOutModal, setOpenLogOutModal] = useState(false);
	const { pathname } = useLocation();
	//const route = pathname.split('/')[1].replaceAll('-', ' ');
	const navigate = useNavigate();
	const { user } = useAuth();
	const [openDropdown, setOpenDropdown] = useState(false);

	const { data: alerts } = useFetchTodaysAlerts();
	const openAlert = () => {
		navigate('/alerts', { replace: true });
	};
	return (
		<header className='flex items-center justify-between py-6 px-5 border border-transparent border-b-gray-600 bg-mlk-blue fixed top-0 left-0 w-full z-20'>
			<div className='flex items-center space-x-2'>
				<img src={Logo} alt='logo' className='w-10' />{' '}
				<p className='font-bold text-2xl'>
					MLK Kilimanjaro{' '}
					<span className='text-mlk-light-blue'> |</span>{' '}
					<span className='font-light capitalize'>
						{pathname == '/'
							? 'Apps & Services'
							: pathname.includes('/details')
							  ? 'Apps & Services'
							  : pathname == '/map'
							    ? 'Map'
							    : pathname == '/intelligence'
							      ? 'Intelligence'
							      : pathname.includes('/ratingApp')
							        ? 'Rate Your Day'
							        : pathname == '/alerts'
							          ? 'Alerts'
							          : pathname.includes('/data-import')
							            ? 'Data Manager'
							            : 'Home'}
					</span>
				</p>
			</div>
			<div className='flex space-x-5 items-center'>
				<button
					className='relative inline-flex items-center'
					onClick={openAlert}
				>
					{alerts && alerts.length > 0 && (
						<div className='text-xs bg-[#F95B45] absolute inline-flex w-5 h-5 rounded-full items-center justify-center -top-2 -end-1 font-bold'>
							{alerts.length}
						</div>
					)}
					<img
						className='w-7'
						src={Notification}
						alt='notification'
					/>
				</button>
				<DropdownMenu
					open={openDropdown}
					onOpenChange={() => setOpenDropdown(false)}
				>
					<DropdownMenuTrigger asChild>
						<Avatar
							onMouseEnter={() => setOpenDropdown(true)}
							className={clsx(
								openDropdown
									? ' bg-mlk-light-blue text-white'
									: 'bg-white text-mlk-dark-blue',
								'w-8 h-8',
							)}
						>
							<AvatarFallback className='text-[15px] font-normal font-roboto'>
								{getInitials(user?.email)}
							</AvatarFallback>
						</Avatar>
					</DropdownMenuTrigger>
					<DropdownMenuContent
						className='w-32 mr-5 border-none bg-white rounded-[4px]'
						onMouseLeave={() => setOpenDropdown(false)}
					>
						<DropdownMenuItem
							onClick={() => setOpenLogOutModal(true)}
							className='hover:cursor-pointer font-roboto font-normal text-sm leading-[16.41px] text-[background: #000000;
]'
						>
							<LogOut className='mr-2 h-4 w-4' />
							<span>Log out</span>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>

				{/* <div
					className={clsx(
						connection === 'Connected'
							? 'text-[#00A59A]'
							: connection === 'Disconnected'
							  ? 'text-mlk-red'
							  : 'text-gray-700',
					)}
				>
					<NetworkSvg />
				</div> */}
			</div>
			<LogOutModal open={openLogOutModal} setOpen={setOpenLogOutModal} />
		</header>
	);
};

export default Nav;
