import React, { useEffect } from 'react';
import { uploadToBucket } from '../../requests/services/data-import/hook';
import { CheckCircleIcon, RefreshCw, XCircle } from 'lucide-react';
import clsx from 'clsx';
import useLoadigStatusStore from '../../stores/loadingStatusStore';

const ImportToStorage = () => {
	const prevDone = useLoadigStatusStore(
		state => state.is_added_to_intelligence,
	);
	const { isLoading, data, isError, error, isPending } =
		uploadToBucket(prevDone);
	useEffect(() => {
		if (data) {
			useLoadigStatusStore.setState({ is_imported_to_storage: true });
		}
	}, [data]);
	return (
		<div className='flex gap-2 items-center font-roboto font-semibold text-[12px]'>
			<div className='w-6'>
				{isLoading ? (
					<RefreshCw className='text-mlk-light-blue w-6 h-6 animate-spin' />
				) : data ? (
					<CheckCircleIcon className='text-[#27AE60] w-6 h-6' />
				) : isError ? (
					<XCircle className='text-red-600 w-6 h-6' />
				) : null}
			</div>

			<p
				className={clsx(
					'font-roboto text-[12px] font-bold leading-7',
					isPending ? 'text-gray-600' : 'text-[#E0E0E0]',
				)}
			>
				{isLoading
					? 'Importing file into Storage Bucket'
					: data
					  ? 'Imported file into Storage Bucket'
					  : isError
					    ? error.message
					    : 'Import file into Storage Bucket'}
			</p>
		</div>
	);
};

export default ImportToStorage;
