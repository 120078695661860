import React, { FC, useContext, useEffect, useState } from 'react';
import { SidebarContext } from '../../context/sidebarContext';
import { AlertStreamType, MapTypes } from '../../types/index.types';
import SlideOver from '../home/slideOver';
import raise from '../../utils/envValidation';

const { tableau } = window;

let viz: tableau.Viz;
const CountryGraph: FC<{
	mapType: MapTypes;
	setViz: (item: tableau.Viz | undefined) => void;
}> = ({ mapType, setViz }) => {
	const [selectedAlert, setSelectedAlert] = useState<AlertStreamType>();

	const [showLegend, setShowLegend] = useState(false);
	const [openSlideOver, setOpenSlideOver] = useState(false);
	const getMapUrl = () => {
		switch (mapType) {
			case 'World':
				return (
					process.env.REACT_APP_TABLEAU_WORLD ??
					raise('env not provided')
				);
			case 'Netherlands':
				return (
					process.env.REACT_APP_TABLEAU_NETHERLANDS ??
					raise('env not provided')
				);
			case 'Provinces':
				return (
					process.env.REACT_APP_TABLEAU_PROVINCE ??
					raise('env not provided')
				);
			case 'Municipalities':
				return (
					process.env.REACT_APP_TABLEAU_MUNICIPALITY ??
					raise('env not provided')
				);
			case 'Districts':
				return (
					process.env.REACT_APP_TABLEAU_DISTRICTS ??
					raise('env not provided')
				);
			case 'Neighbourhoods':
				return (
					process.env.REACT_APP_TABLEAU_NEIGHBOURHOODS ??
					raise('env not provided')
				);
		}
	};
	const { navCollapsed } = useContext(SidebarContext);

	//  let viz: tableau.Viz;
	const options = {
		device: 'desktop',
		hideToolbar: true,
		hideTabs: true,
		onFirstInteractive: function () {
			console.log(
				`Run this code when the ${mapType} viz has finished loading.`,
			);
		},
	};

	function displaySelectedMarksData(marks: any) {
		// Loop through selected marks and display data
		marks.forEach(function (mark: any) {
			const data = mark.impl.$3.$4;
			const title = data.filter(
				(item: any) => item.fieldName === 'Title',
			)[0]?.value;
			const location = data.filter(
				(item: any) => item.fieldName === 'Location',
			)[0]?.value;
			const sub_type = data.filter(
				(item: any) => item.fieldName === 'Sub Type',
			)[0]?.value;
			const type = data.filter(
				(item: any) => item.fieldName === 'Type',
			)[0]?.value;
			const previous_value = data.filter(
				(item: any) => item.fieldName === 'ATTR(Previous Value)',
			)[0]?.value;
			const current_value = data.filter(
				(item: any) => item.fieldName === 'ATTR(Current Value)',
			)[0]?.value;
			const previous_rating = data.filter(
				(item: any) => item.fieldName === 'Previous Rating',
			)[0]?.value;
			const change_direction = data.filter(
				(item: any) => item.fieldName === 'Change Direction',
			)[0]?.value;
			const change_magnitude = data.filter(
				(item: any) => item.fieldName === 'ATTR(Change Magnitude)',
			)[0]?.value;
			const created_at = data.filter(
				(item: any) => item.fieldName === 'Timestamp',
			)[0]?.value;
			const current_rating = data.filter(
				(item: any) => item.fieldName === 'Current Rating',
			)[0]?.value;
			const latitude = data.filter(
				(item: any) => item.fieldName === 'Latitude',
			)[0]?.value;
			const longitude = data.filter(
				(item: any) => item.fieldName === 'Longitude',
			)[0]?.value;
			const dimension = data.filter(
				(item: any) => item.fieldName === 'Longitude',
			);
			setSelectedAlert({
				title,
				location,
				sub_type,
				type,
				previous_value,
				current_value,
				previous_rating,
				change_direction,
				change_magnitude,
				created_at,
				current_rating,
				latitude,
				dimension,
				longitude,
			});
			//open slide over
			setOpenSlideOver(true);
		});
	}
	//console.log(selectedAlert);
	const initViz = () => {
		const container = document.getElementById(
			'vizContainer',
		) as HTMLElement;
		viz = new tableau.Viz(container, getMapUrl(), options);
		setViz(viz);
		mapType === 'World' &&
			viz?.addEventListener(
				tableau.TableauEventName.MARKS_SELECTION,
				(e: any) => {
					e.getMarksAsync().then(function (marks: any) {
						// Handle the selected marks data here
						displaySelectedMarksData(marks);
					});
				},
			);
		viz?.addEventListener(tableau.TableauEventName.VIZ_RESIZE, () => {
			setShowLegend(true);
		});
		// console.log(viz.getWorkbook);
	};

	const loadViz = () => {
		if (viz) {
			viz.dispose();
			setViz(undefined);
		}
		initViz();
	};

	useEffect(() => {
		setShowLegend(false);

		loadViz();
		return () => {
			viz.dispose();
			setViz(undefined);
		};
		// eslint-disable-next-line
	}, [navCollapsed, mapType]);

	return (
		<div>
			<div className='relative mx-auto'>
				<div id='vizContainer' className='w-full h-max' />

				<div className='w-full h-full border-[14px] border-mlk-dark-blue pointer-events-none absolute top-0 left-0' />
				<div className='absolute bottom-3 h-[32px] w-full bg-mlk-dark-blue' />
				{/*Legend*/}
				{showLegend && (
					<div className='text-sm space-y-6 absolute bottom-0 left-3 z-20 bg-mlk-dark-blue pl-2 pr-5 mb-[32px]'>
						<div>
							<h5 className='font-bold'>VIOSCORE KEY</h5>
							<ul className='text-xs mt-2 space-y-1'>
								<li className='flex items-center space-x-1.5'>
									<span className='w-3 h-3 rounded-full bg-[#F1F1F1] inline-block' />
									<span>EXCELLENT</span>
								</li>
								<li className='flex items-center space-x-1.5'>
									<span className='w-3 h-3 rounded-full bg-[#A8CADD] inline-block' />
									<span>GOOD</span>
								</li>
								<li className='flex items-center space-x-1.5'>
									<span className='w-3 h-3 rounded-full bg-[#6DA7C4] inline-block' />
									<span>OK</span>
								</li>
								<li className='flex items-center space-x-1.5'>
									<span className='w-3 h-3 rounded-full bg-[#3987B1] inline-block' />
									<span>POOR</span>
								</li>
								<li className='flex items-center space-x-1.5'>
									<span className='w-3 h-3 rounded-full bg-[#106A9D] inline-block' />
									<span>BAD</span>
								</li>
							</ul>
						</div>
						<div>
							<h5 className='font-bold'>SYMBOLS KEY</h5>
							<ul className='text-xs mt-2 space-y-1'>
								<li className='flex items-center space-x-1.5'>
									<span className='w-3 h-3 rounded-full bg-[#9E9FA4] inline-block border border-white' />
									<span>Most significant alert</span>
								</li>
								<li className='flex items-center space-x-1.5'>
									<span className='w-3 h-3 rounded-full bg-[#9E9FA4] inline-block border border-dashed border-white' />
									<span>Second most significant alert</span>
								</li>
								<li className='flex items-center space-x-1.5'>
									<span className='flex justify-center items-center w-3 h-3'>
										<span className='w-1 h-1 rounded-full bg-white inline-block' />
									</span>
									<span>Province Capital</span>
								</li>
								<li className='flex items-center space-x-1.5'>
									<span className='w-3 h-3 rounded-full bg-[#FFFFFF] inline-block' />
									<span>Amsterdam</span>
								</li>
							</ul>
						</div>
					</div>
				)}
			</div>
			{selectedAlert && (
				<SlideOver
					open={openSlideOver}
					setOpen={setOpenSlideOver}
					selectedAlert={selectedAlert}
				/>
			)}
		</div>
	);
};

export default CountryGraph;
