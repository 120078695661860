import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/supabaseAuthContext';
import { SidebarContext } from '../../context/sidebarContext';
import { useContext } from 'react';
import SideBar from '../layout/dashboardLayout/sideBar';
import clsx from 'clsx';
import Nav from '../layout/dashboardLayout/nav';
import useResetStore from '../../stores/passwordResetStore';
import { Toaster } from '../ui/toaster';

const PrivateRoutes = () => {
	const { user } = useAuth();
	const { navCollapsed } = useContext(SidebarContext);
	const event = useResetStore(state => state.event);

	if (!user) {
		return <Navigate to='/login' replace />;
	}

	if (user && event == 'PASSWORD_RECOVERY') {
		//htis is to check if its a password recovery request
		return <Navigate to='password-reset' replace />;
	}
	return (
		<div className='min-h-screen'>
			<Nav />
			{/* <div className='flex max-w-full'> */}
			<SideBar />
			<div
				className={clsx(
					navCollapsed ? 'ml-20' : 'ml-44',
					'grid grid-cols-1 min-h-screen pt-[80px]',
				)}
			>
				<Outlet />
			</div>
			{/* </div> */}
		</div>
	);
};

export default PrivateRoutes;
