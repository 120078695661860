import React, { useContext, useState } from 'react';
import Tab from '../components/home/tab';
import { AlertStreamType, CategoryType, MapTypes } from '../types/index.types';
import { AlertContext } from '../context/alertContext';
import SlideOver from '../components/home/slideOver';
import { useUtilityFunctions } from '../hooks/utilityFunctions';
import CountryGraph from '../components/graphs/countryGraph';
// import {
// 	useFetchDistrictsQuery,
// 	useFetchMunicipalitiesQuery,
// 	useFetchNeighborhoodQuery,
// 	useFetchProvincesQuery,
// } from '../requests/services/filters/hooks';
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '../components/home/dropdown';
import { useFetchRecentAlerts } from '../requests/services/alerts/hooks';
//import NetherlandGraph from "../components/graphs/netherlandGraph";

let worksheet: any;
const Home = () => {
	const { alerts } = useContext(AlertContext);
	const [province, setProvinceValue] = useState('Province');
	const [municipal, setMunicipal] = useState('Municipal');
	// const [hood, setHoodValue] = useState("Hood");
	//	const [district, setDistrictValue] = useState('District');
	const [openSlideOver, setOpenSlideOver] = useState(false);
	const [selectedAlert, setSelectedAlert] = useState<AlertStreamType>();
	const [selectedMap, setSelectedMap] = useState<MapTypes>('World');
	const [viz, setViz] = useState<tableau.Viz>();
	//const { data: countryProvinces } = useFetchProvincesQuery('Nederland');
	//const { data: municipalities } = useFetchMunicipalitiesQuery(province);
	//const { data: districts } = useFetchDistrictsQuery(municipal);
	//	const { data: neighborhoods } = useFetchNeighborhoodQuery(district);
	//const [countrySelector, setCountry] = useState(false);
	const [provinceSelector, setProvince] = useState(false);
	//const [municipalitySelector, setMunicipality] = useState(false);
	//const [districtSelector, setDistrict] = useState(false);
	const [hoodSelector, setHood] = useState(false);

	const [_, setStreet] = useState(false);
	const { data } = useFetchRecentAlerts();

	const alertData = ['(All)', 'World', 'Health', 'Credit', 'Environment'];

	let selectData = (scope: string, filter: string) => {
		if (viz) {
			const sheetName = viz
				.getWorkbook()
				.getPublishedSheetsInfo()[0]
				.getName();
			viz.getWorkbook()
				.activateSheetAsync(sheetName)
				.then(sheet => {
					worksheet = sheet;
					// console.log(sheet);
					//sheet.
				})
				.then(() => {
					return worksheet.applyFilterAsync(
						scope,
						filter,
						tableau.FilterUpdateType.REPLACE,
					);
				});
		}
	};
	const selectAlert = (alert: AlertStreamType) => {
		setSelectedAlert(alert);
		setOpenSlideOver(true);
	};

	// const handleMapChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
	// 	const { value } = e.target;
	//
	// 	setSelectedMap(value as MapTypes);
	// };

	const handleCountryChange: React.ChangeEventHandler<
		HTMLSelectElement
	> = e => {
		const { value } = e.target;

		setSelectedMap(value as MapTypes);
		setProvince(true);
	};
	const handleProvinceChange: React.ChangeEventHandler<
		HTMLSelectElement
	> = e => {
		const { value } = e.target;
		const province = JSON.parse(value);
		//fetch provinces
		setProvinceValue(province.region_name);

		selectData('Province Name', province.region_name);

		//tableau filter here

		//setMunicipality(true);
	};
	const handleMunicipalityChange: React.ChangeEventHandler<
		HTMLSelectElement
	> = e => {
		const { value } = e.target;
		//fetch municipalities
		const municipality = JSON.parse(value);
		setMunicipal(municipality.region_name);

		//tableau filter here
		selectData('Municipality', municipality.region_name);
		//setDistrict(true);
	};
	const handleDistrictChange: React.ChangeEventHandler<
		HTMLSelectElement
	> = e => {
		const { value } = e.target;
		//fetch districts
		const district = JSON.parse(value);
		//setDistrictValue(district.region_name);

		//tableau filter here
		selectData('District', district.region_name);
		setHood(true);
	};
	const handleHoodChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
		const { value } = e.target;
		//fetch neighborhoods
		const hood = JSON.parse(value);
		setHood(hood.region_name);

		//tableau filter here
		selectData('Neighbourhood', hood.region_name);
		setStreet(true);
	};
	//console.log(data);
	return (
		<div className='w-full py-8 px-10 scrollbar-none'>
			<div className='grid grid-cols-4 gap-x-5 max-w-[2000px] mx-auto'>
				{/*Health*/}
				<Tab
					alerts={data?.health}
					category='Health'
					selectAlert={selectAlert}
				/>
				{/*Credit*/}
				<Tab
					alerts={data?.credit}
					category='Credit'
					selectAlert={selectAlert}
				/>
				{/*Environment*/}
				<Tab
					alerts={data?.environment}
					category='Environment'
					selectAlert={selectAlert}
				/>
				{/*World*/}
				<Tab
					alerts={data?.world}
					category='World'
					selectAlert={selectAlert}
				/>
			</div>
			<div className='mt-10 mx-auto max-w-[2000px]'>
				<div className='flex gap-[14px] items-center'>
					{/* Alerts */}
					{/* <DropdownMenu>
						<DropdownMenuTrigger asChild>
							<button className='mb-12 inline-flex py-2.5 justify-between px-3 text-white text-sm border border-white border-opacity-10 rounded-md bg-mlk-dark-blue font-medium focus:border-white focus:outline-none focus:ring-0 cursor-pointer w-40 h-9 items-center'>
								All Alerts{' '}
								<svg
									className='w-2.5 h-2.5 ms-3 opacity-10'
									aria-hidden='true'
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 10 6'
								>
									<path
										stroke='currentColor'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='m1 1 4 4 4-4'
									/>
								</svg>
							</button>
						</DropdownMenuTrigger>
						<DropdownMenuContent className='w-40 bg-white font-medium text-mlk-blue text-sm border border-white border-opacity-10'>
							{alertData.map((el, idx) => (
								<DropdownMenuCheckboxItem
									key={idx}
									onSelect={event => event.preventDefault()}
								>
									{el}
								</DropdownMenuCheckboxItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu> */}
					{/* Country */}
					<select
						name='world_map'
						id='world_map'
						className='mb-12 rounded-md h-9 border border-white border-opacity-10 items-center text-white text-sm bg-mlk-dark-blue font-medium focus:border-white focus:outline-none focus:ring-0 cursor-pointer w-40'
						onChange={handleCountryChange}
					>
						<option defaultValue={'World'}>World</option>

						<option value='Netherlands'>Netherlands</option>
					</select>
					{/* Province
					{provinceSelector && selectedMap === 'Netherlands' && (
						<select
							name='province_map'
							id='province_map'
							className='mb-12 rounded-md h-9 items-center text-white text-sm bg-mlk-dark-blue font-medium focus:border-white focus:outline-none focus:ring-0 cursor-pointer w-40'
							//onChange={handleProvinceChange}
						>
							<option defaultValue={'Province'}>Province</option>
							{/* {countryProvinces?.map((province , idx) => (
								<option
									key={idx}
									value={JSON.stringify(province)}
								>
									{province.region_name}
								</option>
							))} */}
					{/* </select> */}
					{/* )} */}
					{/* Municipality */}
					{/* {municipalitySelector && selectedMap === 'Netherlands' && (
						<select
							name='municipality_map'
							id='municipality_map'
							className='mb-12 rounded-md h-9 items-center text-white text-sm bg-mlk-dark-blue font-medium focus:border-white focus:outline-none focus:ring-0 cursor-pointer w-40'
							onChange={handleMunicipalityChange}
						>
							<option defaultValue={'Municipality'}>
								Municipality
							</option>
							{municipalities?.map((municipality, idx) => (
								<option
									key={idx}
									value={JSON.stringify(municipality)}
								>
									{municipality.region_name}
								</option>
							))}
						</select>
					)} */}
					{/* District */}
					{/* {districtSelector && selectedMap === 'Netherlands' && (
						<select
							name='district_map'
							id='district_map'
							className='mb-12 rounded-md h-9 items-center text-white text-sm bg-mlk-dark-blue font-medium focus:border-white focus:outline-none focus:ring-0 cursor-pointer w-40'
							onChange={handleDistrictChange}
						>
							<option defaultValue={'District'}>District</option>
							{districts?.map((district, idx) => (
								<option
									key={idx}
									value={JSON.stringify(district)}
								>
									{district.region_name}
								</option>
							))}
						</select>
					)} */}
					{/* Neighborhood */}
					{/* {hoodSelector && selectedMap === 'Netherlands' && (
						<select
							name='neighborhood_map'
							id='neighborhood_map'
							className='mb-12 rounded-md h-9 items-center text-white text-sm bg-mlk-dark-blue font-medium focus:border-white focus:outline-none focus:ring-0 cursor-pointer w-40'
							onChange={handleHoodChange}
						>
							<option defaultValue={'Neighbourhood'}>
								Neighbourhood
							</option>
							{neighborhoods?.map((hood, idx) => (
								<option key={idx} value={JSON.stringify(hood)}>
									{hood.region_name}
								</option>
							))}
						</select>
					)} */}
					{/* Street */}
					{/* {streetSelector && selectedMap === "Netherlands" && (
            <select
              name="streetmap"
              id="street"
              className="mb-12 rounded-md h-9 items-center text-white text-sm bg-mlk-dark-blue font-medium focus:border-white focus:outline-none focus:ring-0 cursor-pointer w-40"
              onChange={handleMapChange}
            >
              <option defaultValue={"Street"}>Street</option>
              <option value="World">World</option>
            </select>
          )} */}
					{hoodSelector && selectedMap === 'Netherlands' && (
						<svg
							width='21'
							className='mb-12 hover:cursor-pointer'
							height='21'
							viewBox='0 0 21 21'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<g id='&#240;&#159;&#166;&#134; icon &#34;arrow circle right&#34;'>
								<g id='Group'>
									<path
										id='Vector'
										d='M10.1272 19.2544C15.168 19.2544 19.2544 15.168 19.2544 10.1272C19.2544 5.08638 15.168 1 10.1272 1C5.08638 1 1 5.08638 1 10.1272C1 15.168 5.08638 19.2544 10.1272 19.2544Z'
										stroke='white'
										strokeOpacity='0.6'
										strokeWidth='1.57444'
										strokeMiterlimit='10'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										id='Vector_2'
										d='M8.97754 13.3491L12.1903 10.1272L8.97754 6.90527'
										stroke='white'
										strokeOpacity='0.6'
										strokeWidth='1.57444'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</g>
							</g>
						</svg>
					)}
				</div>
				<CountryGraph mapType={selectedMap} setViz={setViz} />
			</div>
			{selectedAlert && (
				<SlideOver
					open={openSlideOver}
					setOpen={setOpenSlideOver}
					selectedAlert={selectedAlert}
				/>
			)}
		</div>
	);
};

export default Home;
