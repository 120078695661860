import React, { useEffect, useState } from 'react';
import supabase from '../utils/supabase';
import { AuthMFAGetAuthenticatorAssuranceLevelResponse } from '@supabase/supabase-js';
import { useAuth } from '../context/supabaseAuthContext';

const useMfa = () => {
	const [userVerified, setUserVerified] = useState(false);
	const [userEnrolled, setUserEnrolled] = useState(true);
	const { user } = useAuth();

	useEffect(() => {
		(async () => {
			const res: AuthMFAGetAuthenticatorAssuranceLevelResponse =
				await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
			if (res.error) {
				throw res.error;
			}
			if (
				res.data.nextLevel === 'aal2' &&
				res.data.nextLevel === res.data.currentLevel
			) {
				setUserVerified(true);
			}
		})();
	}, [user]);

	return { userVerified, userEnrolled };
};

export default useMfa;
