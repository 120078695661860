const arrToObject = (arr: any[]) => {
	let res: Record<string, string> = {};
	for (let index = 0; index < arr.length; index++) {
		const el = arr[index];
		let key = `Level ${index + 1}`;
		res[key] = el.value;
	}
	return res;
};

export default arrToObject;
