import React from 'react';
import {
	AlertDialog,
	AlertDialogTrigger,
	AlertDialogTitle,
	AlertDialogDescription,
	AlertDialogContent,
	AlertDialogAction,
	AlertDialogHeader,
	AlertDialogCancel,
	AlertDialogFooter,
} from '../ui/alert-dialog';
import { Button } from '../ui/button';
import { Loader2 } from 'lucide-react';
import { useRevalidator } from 'react-router-dom';
import useDataManagerStore from '../../stores/datamanagerStore';
import { upsertHierarchy } from '../../requests/services/data-manager/hooks';

const HierarchyChangeConfrimationModal = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const { mutate, isPending } = upsertHierarchy();
	let revalidator = useRevalidator();
	const optimisticData = useDataManagerStore(state => state.items);
	const handleSave = () => {
		mutate(optimisticData, {
			onSuccess: data => {
				revalidator.revalidate();
				setOpen(false);
				//console.log(data);
			},
		});
	};
	return (
		<AlertDialog open={open} onOpenChange={setOpen}>
			{/* <AlertDialogTrigger asChild>
				<Button variant='outline'>Show Dialog</Button>
			</AlertDialogTrigger> */}
			<AlertDialogContent className='w-[342px] rounded-lg bg-white h-[195px]'>
				<AlertDialogHeader>
					<AlertDialogTitle>Save Changes</AlertDialogTitle>
					<AlertDialogDescription className='font-roboto font-normal text-sm leading-[16.41px]'>
						<span className='font-semibold'>Labels</span> will
						automatically change to match their{' '}
						<span className='font-semibold'>Level</span> in the
						adjusted hierarchy
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel className='w-[120px] h-9 rounded-[4px] border-mlk-light-blue text-mlk-light-blue'>
						CANCEL
					</AlertDialogCancel>
					<Button
						onClick={() => handleSave()}
						className='bg-mlk-light-blue w-[120px] h-9 rounded-[4px] border-mlk-light-blue hover:bg-mlk-light-blue/90 text-white'
					>
						{isPending ? (
							<Loader2 className='mr-2 h-4 w-4 animate-spin' />
						) : (
							'OK'
						)}
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export default HierarchyChangeConfrimationModal;
