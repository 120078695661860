import React, { Dispatch, FC } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseSvg } from '../svgs';
import { AlertStreamType } from '../../types/index.types';
import { useUtilityFunctions } from '../../hooks/utilityFunctions';
import { alertDescriptions } from '../../utils/staticData';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

const SlideOver: FC<{
	open: boolean;
	setOpen: Dispatch<React.SetStateAction<boolean>>;
	selectedAlert: AlertStreamType;
}> = ({ open, setOpen, selectedAlert }) => {
	const { getDimension, getFormattedTime } = useUtilityFunctions();
	const getSubDescription = () => {
		switch (selectedAlert.type) {
			case 'Weather':
				return (
					selectedAlert.sub_type +
					' rating has ' +
					(selectedAlert.change_direction === 'UP'
						? 'risen'
						: 'dropped') +
					' from ' +
					selectedAlert.previous_rating +
					' to ' +
					selectedAlert.current_rating
				);
			case 'Interest Rate':
				return (
					'The ' +
					selectedAlert.title +
					' interest rate has ' +
					(selectedAlert.change_direction === 'UP'
						? 'increased'
						: 'decreased') +
					' by ' +
					selectedAlert.change_magnitude +
					'% from ' +
					selectedAlert.previous_value +
					'% to ' +
					selectedAlert.current_value +
					'%'
				);
			case 'Stock Exchange':
				return (
					<div className='text-right flex flex-col items-end space-y-1'>
						<p className='uppercase text-gray-400'>
							{selectedAlert.sub_type}
						</p>
						<h5 className='text-5xl'>
							{selectedAlert.current_value.toFixed(2)}
						</h5>
						<div
							className={clsx(
								selectedAlert.change_direction === 'UP'
									? 'text-mlk-green'
									: 'text-red-500',
								'space-x-10 flex items-center',
							)}
						>
							<div className='space-x-1 flex items-center'>
								{selectedAlert.change_direction === 'UP' ? (
									<ChevronUpIcon className='w-4' />
								) : (
									<ChevronDownIcon className='w-4' />
								)}{' '}
								<p>
									{Math.abs(
										selectedAlert.previous_value -
											selectedAlert.current_value,
									).toFixed(2)}
								</p>
							</div>
							<p>
								{selectedAlert?.change_magnitude?.toFixed(2)}%
							</p>
						</div>
						<p className='text-sm text-gray-400 !mt-3'>
							Last updated:{' '}
							{getFormattedTime(selectedAlert.created_at)} EDT
						</p>
					</div>
				);
		}
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as='div' className='relative z-20' onClose={setOpen}>
				<div className='fixed inset-0' />

				<div className='fixed inset-0 overflow-hidden'>
					<div className='absolute inset-0 overflow-hidden'>
						<div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
							<Transition.Child
								as={Fragment}
								enter='transform transition ease-in-out duration-500 sm:duration-700'
								enterFrom='translate-x-full'
								enterTo='translate-x-0'
								leave='transform transition ease-in-out duration-500 sm:duration-700'
								leaveFrom='translate-x-0'
								leaveTo='translate-x-full'
							>
								<Dialog.Panel className='pointer-events-auto w-screen max-w-sm'>
									<div className='flex h-full flex-col overflow-y-scroll bg-mlk-blue py-6 shadow-xl'>
										<div className='px-4 sm:px-10'>
											<div className='flex items-start justify-between'>
												<div className='flex h-7 items-center'>
													<button
														type='button'
														className='relative rounded-full text-white hover:text-mlk-light-blue focus:outline-none focus:text-mlk-light-blue'
														onClick={() =>
															setOpen(false)
														}
													>
														<span className='absolute -inset-2.5' />
														<span className='sr-only'>
															Close panel
														</span>
														<CloseSvg />
													</button>
												</div>
											</div>
										</div>
										<div className='relative mt-10 flex-1 px-4 sm:px-10 divide-y divide-gray-600 text-white space-y-5'>
											<Dialog.Title className='text-3xl font-semibold leading-10 text-white'>
												<span className='block'>
													{getDimension(
														selectedAlert.type,
													) === 'Environment' &&
														selectedAlert.location}
												</span>
												<span className='block'>
													{selectedAlert.title} Alert
												</span>
											</Dialog.Title>
											<div className='pt-5'>
												{getSubDescription()}
											</div>
											<div className='pt-5'>
												<p className='leading-7'>
													{
														alertDescriptions[
															getDimension(
																selectedAlert.type,
															)
														][selectedAlert.title]
													}
												</p>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default SlideOver;
