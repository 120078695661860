import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { AppsServiceDataType, TDetailsType } from '../../types/index.types';

const Overview = () => {
	let data = useLoaderData() as TDetailsType;
	//console.log(data);
	return (
		<div className='flex flex-col pb-10'>
			<h4 className='font-medium text-3xl leading-[35.16px]'>Overview</h4>
			<div>
				{data.overview.sections.map((section, idx) => (
					<p key={idx} className='font-normal text-xs leading-[18px]'>
						{section.text}
					</p>
				))}
			</div>
		</div>
	);
};
export default Overview;
