import { UseQueryResult, useQuery } from '@tanstack/react-query';
import supabase from '../../../utils/supabase';
import { handleSupabaseError } from '../../../utils/handleSupabaseError';
import { QueryData, QueryError } from '@supabase/supabase-js';
import { AlertStreamType } from '../../../types/index.types';
import { endOfDay, formatISO, getTime, startOfDay } from 'date-fns';

export const useFetchRecentAlerts = () =>
	useQuery({
		queryKey: ['recent_alerts'],
		queryFn: async () => {
			const { data, error } = await supabase.rpc('fetch_recent_alerts');
			if (error) {
				console.log(error);
			}
			const environment = data
				?.filter((el: any) => el.dimension == 'Environment')
				.slice(0, 2);

			const health = data?.filter((el: any) => el.dimension == 'Health');
			const credit = data?.filter((el: any) => el.dimension == 'Credit');
			const world = data
				?.filter((el: any) => el.dimension == 'World')
				.slice(0, 2);
			return { environment, health, credit, world };
		},
		refetchInterval: 30000, // every 5 mins
	});

export const useFetchAllAlerts = () =>
	useQuery({
		queryKey: ['all_alerts'],
		queryFn: async () => {
			const { data, error } = await supabase
				.from('alerts_table')
				.select('*')
				.order('created_at', { ascending: false });
			if (error) {
				console.log(error);
			}
			return data;
		},
		refetchInterval: 180000, // every 3 mins
	});
let currentTimeStamp = new Date().toISOString();
let start = startOfDay(currentTimeStamp).toISOString();

export const useFetchTodaysAlerts = () =>
	useQuery({
		queryKey: ['todays_alerts'],
		queryFn: async (): Promise<AlertStreamType[] | null> => {
			const { data, error } = await supabase
				.from('alerts_table')
				.select('*')
				.lte('created_at', currentTimeStamp)
				.gte('created_at', start)
				.order('created_at', { ascending: false });
			if (error) {
				console.log(error);
			}
			return data;
		},
		refetchInterval: 180000, // every 3 mins
	});
