import React, { FC } from 'react';

const AlertCardSkeleton: FC = () => {
	return (
		<div className='flex items-center space-x-2'>
			<div className='h-5 w-5 rounded bg-slate-700 animate-pulse' />
			<div className='w-full h-4 bg-slate-700 rounded animate-pulse' />
		</div>
	);
};

export default AlertCardSkeleton;
