import React, { useMemo } from 'react';
import { Button } from '../ui/button';
import { useSearchParams } from 'react-router-dom';
import useInhabitantStore from '../../stores/inhabitantStore';
import VDonought from './vioscore-donought';
import HistoryTable from './historyTable';
import { historyColumns } from './historyColumns';
import { array } from 'zod';
import { Alert, AlertDescription } from '../ui/alert';

const RateVioscore = ({
	tableData,
}: {
	tableData: { created_at: string; rating: number; vioscore: number }[];
}) => {
	let [searchParams] = useSearchParams();
	const vioscore = useInhabitantStore(state => state.vioscore);
	const currentRating = searchParams.get('rating');
	const sortedTableData = useMemo(
		() =>
			tableData?.sort(
				(a, b) => +new Date(b.created_at) - +new Date(a.created_at),
			),

		[tableData],
	);

	//console.log(tableData);
	return (
		<div className='flex w-full  flex-col h-full flex-1 py-10 justify-start items-center pb-[69px]'>
			<div className='flex flex-col w-[640px]'>
				<div className='flex px-5 justify-between mb-12'>
					<div className='flex flex-col gap-[26px] items-center'>
						<p className='font-roboto font-normal text-2xl leading-[28.13px]'>
							Rate Your Day
						</p>
						<Button
							variant='outline'
							size='icon'
							className='rounded-full text-2xl hover:cursor-none bg-mlk-light-blue w-[90px] h-[90px] border-mlk-light-blue'
						>
							{searchParams.get('rating')}
						</Button>
					</div>
					<div className='flex flex-col items-center'>
						<p className='font-roboto font-normal text-2xl leading-[28.13px]'>
							VioScore™
						</p>

						<VDonought
							innerRadius={40}
							outerRadius={48}
							cx={75}
							cy={64}
							labeltextSize='19px'
							vioscore={Math.round(vioscore!)}
						/>
					</div>
				</div>
				<Alert className='mb-12 w-[640px] px-[21px] py-6 font-roboto text-sm font-normal leading-[21px] border-[#313648] bg-mlk-dark-blue'>
					{currentRating && +currentRating < 5 ? (
						<AlertDescription>
							Based on this patient’s RYD rating we would
							recommend that they follow Plan 1, and, if their
							score is consistently this low or lower, they should
							arrange an appointment with their GP.
						</AlertDescription>
					) : currentRating && +currentRating >= 5 ? (
						<AlertDescription>
							Based on this patient’s RYD rating we would
							recommend that they follow Plan 2. However, if their
							score changes to be consistently lower than 5, they
							should arrange an appointment with their GP
						</AlertDescription>
					) : null}
				</Alert>
				{/* Table Goes Here ..Only show it when data is available*/}

				{tableData ? (
					<HistoryTable
						data={sortedTableData || []}
						columns={historyColumns}
					/>
				) : null}
			</div>
		</div>
	);
};

export default RateVioscore;
