import React from 'react';
import Logo from '../assets/icon_logo.svg';
import Button from '../components/form/button';
import { useNavigate } from 'react-router-dom';

const ResetSuccess = () => {
	const navigate = useNavigate();
	return (
		<div className='flex items-center justify-center h-screen w-full'>
			<div className='bg-[#1A2034] w-full max-w-xl shadow-xl border rounded-lg border-gray-700 py-10 px-8 flex flex-col justify-center items-center'>
				<div className='flex items-center space-x-2 mb-7'>
					<img src={Logo} alt='logo' className='w-14 mr-3' />{' '}
					<p className='font-bold text-5xl'>MLK Kilimanjaro</p>
				</div>

				<div className='w-[251px] mt-[27px]'>
					<p className='font-bold font-roboto text-sm leading-[18px] text-white mb-8'>
						Your password reset is successful. You can now log in
						with your new password.
					</p>

					<div className='flex justify-center mt-2'>
						<Button onClick={() => navigate('/login')}>
							<span>Login</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetSuccess;
