import React, { useEffect, useState } from 'react';
import { Button } from '../components/ui/button';
import { Loader2, LockKeyhole, Plus } from 'lucide-react';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../components/ui/table';
import { insertHierarchy } from '../requests/services/data-manager/hooks';
import { THierarchyData } from '../types/index.types';
import useDataManagerStore from '../stores/datamanagerStore';
import ToggleUp from '../components/data-manager/toggleup-button';
import ToggleDown from '../components/data-manager/toggledown-button';
import HierarchyChangeConfrimationModal from '../components/data-manager/hierarchy-change-confirmation-modal';

const DataManager = () => {
	let revalidator = useRevalidator();
	let data = useLoaderData() as THierarchyData[];
	const optimisticData = useDataManagerStore(state => state.items);
	//console.log(optimisticData);
	const resetHdata = useDataManagerStore(state => state.resetItems);

	const columns = [
		'Level',
		'Label',
		'VioScore™ Example',
		'Non-VioScore™ Example',
	];
	const { mutate, isPending } = insertHierarchy();
	const [open, setOpen] = useState(false);

	const addNewHierarchy = () => {
		const last_item = data[data.length - 1];
		const next_label_id =
			last_item.id <= 8
				? `Level${' '}0${''}${last_item.id + 1}`
				: `Level${' '}${last_item.id + 1}`;
		mutate(
			{
				id: last_item.id + 1,
				level: last_item.id + 1,
				label: next_label_id,
			},
			{
				onSuccess: data => {
					revalidator.revalidate();
					//console.log(data);
				},
			},
		);
	};

	useEffect(() => {
		resetHdata(data);
	}, [data]);

	return (
		<div className='py-6'>
			<div className=' px-8 w-full flex items-center  justify-center'>
				<div
					className='bg-mlk-blue border flex flex-col gap-6 rounded-md border-gray-600 w-full min-h-[516px] py-6'
					//style={{ minHeight: `calc(100vh - 8rem)` }}
				>
					<p className='ml-[17px] font-roboto font-normal text-2xl leading-[28.13px]'>
						Hierarchy Definition
					</p>
					<Button
						onClick={addNewHierarchy}
						className='ml-[17px] bg-mlk-light-blue text-xs leading-[14.52px] font-bold hover:bg-mlk-light-blue/90 w-[150px] h-8 rounded-[4px] gap-[10px]'
					>
						{isPending ? (
							<Loader2 className='mr-2 h-4 w-4 animate-spin' />
						) : (
							<>
								{' '}
								INSERT LEVEL
								<Plus className='mr-2 h-4 w-4' />
							</>
						)}
					</Button>
					<div className='w-full flex-1'>
						<Table>
							<TableHeader>
								<TableRow className='border- border-white/30'>
									{columns.map((column, idx) => (
										<TableHead
											key={idx}
											className=' font-roboto font-bold text-xs leading-[14.06px]'
										>
											{column}
										</TableHead>
									))}
								</TableRow>
							</TableHeader>
							<TableBody>
								{optimisticData.map((item, idx) => (
									<TableRow
										key={item.id}
										className='border border-white/10 [&:nth-child(2)]:border-b-white/30 last:border-b-white/30'
									>
										<TableCell className='w-24 font-normal text-xs font-roboto leading-[14.06px]'>
											{idx + 1}
										</TableCell>
										<TableCell className='w-[160px] capitalize font-normal text-xs font-roboto leading-[14.06px]'>
											{item.label ||
												`Level $0{item.level}`}
										</TableCell>
										<TableCell className='w-[150px] font-normal text-xs font-roboto leading-[14.06px]'>
											{item.vioscore_example || 'NULL'}
										</TableCell>
										<TableCell className='w-[200px] font-normal text-xs font-roboto leading-[14.06px]'>
											{item.nonvioscore_example || 'NULL'}
										</TableCell>
										{idx <= 1 ? (
											<TableCell className='flex flex-1 justify-end font-normal text-xs font-roboto leading-[14.06px] placeholder-zinc-100'>
												<LockKeyhole className='w-4 h-[21px] text-white/80' />
											</TableCell>
										) : (
											<TableCell className='flex flex-1 justify-end font-normal text-xs font-roboto leading-[14.06px] placeholder-zinc-100'>
												<div className='flex gap-2'>
													<ToggleUp index={idx} />
													<ToggleDown
														index={idx}
														data={data}
													/>
												</div>
											</TableCell>
										)}
									</TableRow>
								))}
							</TableBody>
						</Table>
						<div className='flex items-center gap-3 pt-6 px-[17px]'>
							<Button
								variant='outline'
								className='border-mlk-light-blue w-[82px] rounded-[4px] text-mlk-light-blue h-8 font-bold text-xs leading-[14.52px]'
							>
								CANCEL
							</Button>
							<Button
								onClick={() => setOpen(true)}
								className='bg-mlk-light-blue text-white rounded-[4px] w-16 h-8 font-bold text-xs leading-[14.52px]'
							>
								SAVE
							</Button>
						</div>
					</div>
				</div>
				<HierarchyChangeConfrimationModal
					open={open}
					setOpen={setOpen}
				/>
			</div>
		</div>
	);
};

export default DataManager;
