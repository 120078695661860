import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { TDetailsType } from '../../types/index.types';

const Requirements = () => {
	let data = useLoaderData() as TDetailsType;
	return (
		<div
			//data-section
			//	id='requirements'
			className='flex flex-col items-start border-y border-y-white/20 justify-center pt-[30px] py-10'
			//id='requirements'
		>
			<h4 className='font-medium text-3xl leading-[35.16px] mb-3'>
				Requirements
			</h4>
			<p className='font-normal text-xs leading-[18px]'>
				MLK Kilmanjaro Core. <br />
				{data.requirements && data.requirements}
			</p>
		</div>
	);
};

export default Requirements;
