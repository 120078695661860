import React, { FC, useContext } from 'react';
import {
	BellSvg,
	BrainSvg,
	ClockSvg,
	ExpandSvg,
	FolderSvg,
	HomeSvg,
	MapSvg,
	RatingAppSvg,
	ScanSvg,
	SearchSvg,
	TechnologySvg,
} from '../../svgs';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { SidebarContext } from '../../../context/sidebarContext';

const SideBar: FC = () => {
	const { navCollapsed, setNavCollapsed } = useContext(SidebarContext);
	const pathname = useLocation().pathname;
	//console.log(pathname.startsWith('/details'));

	const sideBarLinks = [
		// {
		// 	link: '/',
		// 	svg: HomeSvg,
		// 	text: 'Home',
		// },

		{
			link: '/map',
			svg: MapSvg,
			text: 'Map',
		},
		{
			link: '/alerts',
			svg: BellSvg,
			text: 'Alerts',
		},
		{
			link: '/ratingApp',
			svg: RatingAppSvg,
			text: 'Rate Your Day',
		},
		{
			link: '/intelligence',
			svg: BrainSvg,
			text: 'Intelligence',
		},
		// {
		// 	link: '/rules',
		// 	svg: ScanSvg,
		// 	text: 'Rules',
		// },
		// {
		// 	link: '/data-science',
		// 	svg: TechnologySvg,
		// 	text: 'Data Science',
		// },
	];

	return (
		<nav
			className={clsx(
				navCollapsed ? 'w-20' : 'w-44',
				'flex-none bg-mlk-blue h-screen border border-transparent border-r-gray-600 py-5 flex flex-col justify-between fixed top-0 pt-[100px] z-10',
			)}
		>
			<div className=' space-y-3'>
				<div className='space-y-3 w-full'>
					<Link
						to='/'
						className={clsx(
							pathname === '/'
								? 'bg-mlk-light-blue'
								: ' text-mlk-light-blue hover:bg-mlk-dark-blue',
							navCollapsed ? 'justify-center' : '',
							'w-full py-3 px-3 flex space-x-3 items-center duration-300',
						)}
					>
						<span>{<HomeSvg />}</span>
						{!navCollapsed && <span>Home</span>}
					</Link>
					{sideBarLinks.map(option => (
						<Link
							to={option.link}
							key={option.link}
							className={clsx(
								pathname.startsWith(option.link)
									? 'bg-mlk-light-blue'
									: 'hidden text-mlk-light-blue hover:bg-mlk-dark-blue',
								navCollapsed ? 'justify-center' : '',
								'w-full py-3 px-3 flex space-x-3 items-center duration-300',
							)}
						>
							<span>{<option.svg />}</span>
							{!navCollapsed && <span>{option.text}</span>}
						</Link>
					))}
				</div>
				<div className='space-y-3 w-full pt-3'>
					{sideBarLinks.slice(4).map(option => (
						<Link
							to={option.link}
							key={option.link}
							className={clsx(
								pathname === option.link
									? 'bg-mlk-light-blue'
									: 'text-mlk-light-blue hover:bg-mlk-dark-blue',
								navCollapsed ? 'justify-center' : '',
								'w-full py-3 px-3 flex space-x-3 items-center duration-300',
							)}
						>
							<span>{<option.svg />}</span>
							{!navCollapsed && <span>{option.text}</span>}
						</Link>
					))}
				</div>
			</div>
			<button
				className={clsx(
					navCollapsed ? '' : 'rotate-180',
					navCollapsed ? 'mx-auto' : 'ml-3',
					'flex items-center justify-center text-mlk-light-blue w-fit duration-75',
				)}
				onClick={() => setNavCollapsed(!navCollapsed)}
			>
				<ExpandSvg />
			</button>
		</nav>
	);
};

export default SideBar;
