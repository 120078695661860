import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import Logo from '../assets/icon_logo.svg';
import { useFormContext } from 'react-hook-form';
import { Input } from '../components/form/textField';
import { PadLockSvg } from '../components/svgs';
import Button from '../components/form/button';
import { PasswordResetSchema } from '../utils/schema';
import useResetStore from '../stores/passwordResetStore';
import { useNavigate } from 'react-router-dom';
import supabase from '../utils/supabase';
import { useAuth } from '../context/supabaseAuthContext';

const ResetConfirm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useFormContext<PasswordResetSchema>();
	const { signOut } = useAuth();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const handleShowPassword: React.ChangeEventHandler<
		HTMLInputElement
	> = e => {
		const { checked } = e.target;
		checked ? setShowPassword(true) : setShowPassword(false);
	};
	const [error, setError] = useState<string>();
	const onSubmit = async (data: PasswordResetSchema) => {
		//submit the new password

		const { data: supabaseData, error } = await supabase.auth.updateUser({
			password: data.password,
		});
		if (error) {
			console.log(error);
			setError(error.message);
			return;
		} else {
			useResetStore.setState({ event: null });
			useResetStore.persist.clearStorage();
			signOut();
			navigate('/password-reset-success');
		}
	};
	return (
		<div className='flex items-center justify-center h-screen w-full'>
			<div className='bg-[#1A2034] w-full max-w-xl shadow-xl border rounded-lg border-gray-700 py-10 px-8 flex flex-col justify-center items-center'>
				<div className='flex items-center space-x-2 mb-7'>
					<img src={Logo} alt='logo' className='w-14 mr-3' />{' '}
					<p className='font-bold text-5xl'>MLK Kilimanjaro</p>
				</div>
				<AnimatePresence mode='wait' initial={false}>
					<motion.div
						className='w-full max-w-sm'
						initial={{ opacity: 0, x: '30%' }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.5 }}
					>
						{/*Password form*/}
						<form
							className='w-full'
							onSubmit={handleSubmit(onSubmit)}
						>
							<h1 className='font-bold text-xl mb-8'>
								Confirm your new password
							</h1>
							<div className='relative'>
								<p className='absolute left-5 top-0  -translate-y-1/2 z-2 bg-[#1A2034] px-1 font-medium text-mlk-light-blue'>
									Password
								</p>
								<Input
									type={showPassword ? 'text' : 'password'}
									{...register('confirm')}
									placeholder='Your password'
								/>

								<div className='pointer-events-none absolute top-1/2 -translate-y-1/2 left-2'>
									{<PadLockSvg />}
								</div>
							</div>
							{errors.confirm && (
								<small className='text-red-500 my-2 block !h-4'>
									{errors.confirm.message as string}
								</small>
							)}
							{error && (
								<small className='text-red-500 my-2 block !h-4'>
									{error}
								</small>
							)}
							<div className='mt-3 flex items-center space-x-2'>
								<input
									id='showPassword'
									aria-describedby='candidates-description'
									name='showPassword'
									type='checkbox'
									onChange={handleShowPassword}
									className='h-4 w-4 bg-transparent rounded border-gray-300 text-mlk-light-blue focus:ring-offset-mlk-blue focus:ring-mlk-light-blue'
								/>
								<label
									htmlFor='showPassword'
									className='font-medium'
								>
									Show password
								</label>
							</div>
							<small className='text-red-500 my-3 block !h-4'></small>
							<div className='flex space-x-5 justify-between items-center mt-2'>
								<div />

								<Button type='submit'>
									{/* {loading && <Spinner className='w-[18px]' />} */}
									<span>Next</span>
								</Button>
							</div>
						</form>
					</motion.div>
				</AnimatePresence>
			</div>
		</div>
	);
};

export default ResetConfirm;
