import React, { FC, useEffect, useState } from 'react';
import Logo from '../assets/icon_logo.svg';
import { AnimatePresence } from 'framer-motion';
import UsernameForm from '../components/authentication/UsernameForm';
import PasswordForm from '../components/authentication/PasswordForm';
import { useNavigate } from 'react-router-dom';
import supabase from '../utils/supabase';
import { FormProvider, useForm } from 'react-hook-form';
import { PersonSvg } from '../components/svgs';
import { FormCurrentState } from '../types/index.types';
import { Login, authSchema } from '../utils/schema';
import { zodResolver } from '@hookform/resolvers/zod';

const LoginPage: FC = () => {
	const methods = useForm<Login>({
		resolver: zodResolver(authSchema),
	});
	const [currentState, setCurrentState] = useState<FormCurrentState>('email');

	return (
		<div className='flex items-center justify-center h-screen w-full'>
			<div className='bg-[#1A2034] w-full max-w-xl shadow-xl border rounded-lg border-gray-700 py-10 px-8 flex flex-col justify-center items-center'>
				<div className='flex items-center space-x-2 mb-7'>
					<img src={Logo} alt='logo' className='w-14 mr-3' />{' '}
					<p className='font-bold text-5xl'>MLK Kilimanjaro</p>
				</div>
				<AnimatePresence mode='wait' initial={false}>
					<FormProvider {...methods}>
						{currentState === 'email' ? (
							<UsernameForm setFormState={setCurrentState} />
						) : currentState === 'password' ? (
							<PasswordForm setFormState={setCurrentState} />
						) : null}
					</FormProvider>
				</AnimatePresence>
			</div>
		</div>
	);
};

export default LoginPage;
