import { create } from 'zustand';
import { Inhabitant, THierarchyData } from '../types/index.types';
import { immer } from 'zustand/middleware/immer';

interface THierarchyStoreData {
	items: THierarchyData[];
	moveUp: (prev: number, current: number) => void;
	moveDown: (current: number, next: number) => void;
	resetItems: (items: THierarchyData[]) => void;
}

const useDataManagerStore = create<THierarchyStoreData>()(
	immer(set => ({
		items: [],
		moveUp: (prev, current) => {
			set(state => {
				[state.items[prev], state.items[current]] = [
					state.items[current],
					state.items[prev],
				];
			});
		},
		moveDown(current, next) {
			set(state => {
				[state.items[current], state.items[next]] = [
					state.items[next],
					state.items[current],
				];
			});
		},
		resetItems: items => set(state => ({ items: items })),
	})),
);

export default useDataManagerStore;
