import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Label } from '../ui/label';
import { Input } from '../form/textField';
import { RadioGroup, RadioGroupItem } from '../ui/radiogroup';
import { Button } from '../ui/button';
import {
	FieldError,
	FieldErrors,
	FieldErrorsImpl,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import {
	GenderValues,
	TInhibitantSchema,
	inhibitantSchema,
} from '../../utils/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogClose, DialogFooter } from '../ui/dialog';
import { format } from 'date-fns';
import { insertInhabitant } from '../../requests/services/rate_your_day/inihabitants';
import useInhabitantStore from '../../stores/inhabitantStore';
import { Info, Loader2 } from 'lucide-react';
import clsx from 'clsx';
import { useToast } from '../ui/use-toast';

const InhibitantsForm = ({
	setClose,
}: {
	setClose: Dispatch<SetStateAction<boolean>>;
}) => {
	const currentPostcode = useInhabitantStore(state => state.postcode);
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors },
	} = useForm<TInhibitantSchema>({
		resolver: zodResolver(inhibitantSchema),
	});

	const mutation = insertInhabitant();
	const { toast } = useToast();
	const onInhibitantSubmit: SubmitHandler<TInhibitantSchema> = data => {
		//setLoading(true);
		const parsedDob = new Date(
			+data.dob.year,
			+data.dob.month,
			+data.dob.day,
		).toISOString();
		//const toTimestamp = Date.parse(parsedDob) / 1000;

		mutation.mutate(
			{
				...data,
				dob: parsedDob,
				postcode: currentPostcode.replace(/ /g, '').toUpperCase(),
			},
			{
				onError(error, variables, context) {
					toast({
						title: (
							<div className='flex items-center w-full gap-1'>
								<Info />
								<span>Household Inhabitant Notice</span>
							</div>
						),
						variant: 'destructive',
						className: 'bg-red-400 border-red-400 py-3',
						description: `Inhabitant ${error.message}..!`,
					});
				},

				onSuccess(data, variables, context) {
					reset();
					setClose(false);
				},
			},
		);
	};
	const gender = watch('gender');

	return (
		<form
			className='grid flex-1 gap-[25px]'
			onSubmit={event => {
				event.stopPropagation();
				handleSubmit(onInhibitantSubmit)(event);
			}}
		>
			<div>
				<Label
					htmlFor='firstname'
					className='text-right text-[#E0E0E0] font-roboto font-normal text-[15px] leading-7'
				>
					First name
				</Label>
				<Input
					id='username'
					{...register('first_name')}
					className='w-[300px] text-sm font-normal leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'
				/>
				{errors.first_name && (
					<small className='text-red-500 my-2 block !h-4'>
						{errors.first_name.message as string}
					</small>
				)}
			</div>
			<div>
				<Label
					htmlFor='lastname'
					className='text-right text-[#E0E0E0] font-roboto font-normal text-[15px] leading-7'
				>
					Last name
				</Label>
				<Input
					id='last_name'
					{...register('last_name')}
					className='w-[300px] h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'
				/>
				{errors.last_name && (
					<small className='text-red-500 my-2 block !h-4'>
						{errors.last_name.message as string}
					</small>
				)}
			</div>

			<div>
				<Label
					htmlFor='dob'
					className='text-right text-[#E0E0E0] font-roboto font-normal text-[15px] leading-7'
				>
					Date of birth
				</Label>
				<div
					className='focus-within:border-white/10 focus-within:border inline-flex focus:ring-0 focus:ring-white/10'
					role='group'
				>
					<Input
						id='day'
						//type='number'

						maxLength={2}
						{...register('dob.day')}
						//onChange={e => setDay(e.target.value)}
						className='w-[77px] text-center h-9 px-2 rounded-r-none focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'
						placeholder='DD'
					/>

					<Input
						id='month'
						maxLength={2}
						//	readOnly={name === 'dob' ? true : false}
						{...register('dob.month')}
						//	defaultValue={month}
						//onChange={e => setMonth(e.target.value)}
						className='w-[77px] rounded-none text-center h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'
						placeholder='MM'
					/>

					<Input
						type='text'
						id='year'
						maxLength={4}
						{...register('dob.year')}
						//readOnly={name === 'dob' ? true : false}
						//	defaultValue={year}
						//	onChange={e => setYear(e.target.value)}
						className='w-[77px] rounded-l-none text-center h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'
						placeholder='YYYY'
					/>
				</div>
				{errors.dob && (
					<small className='text-red-500 my-2 block !h-4'>
						{
							errors.dob[
								Object.keys(
									errors.dob,
								)[0] as keyof FieldErrorsImpl<{
									day: string;
									month: string;
									year: string;
								}>
							]?.message
						}
					</small>
				)}
			</div>
			<div>
				<Label
					htmlFor='gender'
					className='text-right text-[#E0E0E0] font-roboto font-normal text-[15px] leading-7'
				>
					Gender
				</Label>
				<div>
					<Button
						id='Male'
						onClick={e => {
							e.preventDefault();
							setValue('gender', GenderValues[0], {
								shouldValidate: true,
							});
						}}
						className={clsx(
							'w-[99px] text-white/30 text-center h-9 px-2 rounded-r-none focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10',
							gender == 'Male' && 'bg-mlk-light-blue text-white',
						)}
					>
						Male
					</Button>

					<Button
						id='Female'
						onClick={e => {
							e.preventDefault();
							setValue('gender', GenderValues[1], {
								shouldValidate: true,
							});
						}}
						className={clsx(
							'w-[99px] text-white/30 text-center h-9 px-2 rounded-none focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10',
							gender == 'Female' &&
								'bg-mlk-light-blue text-white',
						)}
					>
						Female
					</Button>

					<Button
						id='Other'
						onClick={e => {
							e.preventDefault();
							setValue('gender', GenderValues[2], {
								shouldValidate: true,
							});
						}}
						className={clsx(
							'w-[99px] text-white/30 text-center h-9 px-2 rounded-l-none focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10',
							gender == 'Other' && 'bg-mlk-light-blue text-white',
						)}
					>
						Other
					</Button>
				</div>
				{errors.gender && (
					<small className='text-red-500 my-2 block !h-4'>
						{errors.gender.message as string}
					</small>
				)}
			</div>

			<div className='mr-1 flex gap-2 w-full mt-6'>
				<Button
					onClick={() => setClose(false)}
					variant='outline'
					className='border h-9 w-[85px] rounded-[4px] border-mlk-light-blue text-mlk-light-blue bg-mlk-dark-blue'
				>
					CANCEL
				</Button>

				<Button
					type='submit'
					className='bg-mlk-light-blue h-9 rounded-[4px] w-[69px] text-white border-mlk-light-blue'
				>
					{mutation.isPending ? (
						<Loader2 className='mr-2 h-4 w-4 animate-spin' />
					) : (
						'DONE'
					)}
				</Button>
			</div>
		</form>
	);
};

export default InhibitantsForm;
