import { useMutation, useQuery } from '@tanstack/react-query';
import supabase from '../../../utils/supabase';
import { useAuth } from '../../../context/supabaseAuthContext';
import useDataImportStore, {
	ITableData,
} from '../../../stores/dataImportStore';
import useHierarchyDefinitionStore, {
	Tlevel,
} from '../../../stores/hierarchyDefinitionStore';
import useLoadigStatusStore from '../../../stores/loadingStatusStore';

export const uploadToStorageBucket = () => {
	const { user } = useAuth();
	return useMutation({
		mutationFn: async (file: File) => {
			const { error } = await supabase.storage
				.from('mlk')
				.upload(`${user?.id!}/${file.name}`, file);
			if (error) {
				// Handle error
				//console.log(error);
				throw new Error(error.message);
			}
			//return data;
		},
	});
};

// export const rollbackIntelligenceDataAction = async (table_name: string) => {
// 	//delete any related columns ;
// 	const addedColumns = useDataImportStore.getState().added_rows_columns;
// 	//useLoadigStatusStore.setState({ is_added_to_intelligence: false });
// 	//Only do this if there were added columns
// 	if (addedColumns.length > 0) {
// 		const columns_to_delete = addedColumns.map(el => el.label);
// 		const { error } = await supabase.rpc(
// 			'rollback_intelligence_model_action',
// 			{
// 				columns_to_delete,
// 				table_name,
// 			},
// 		);
// 		if (error) console.log(error);
// 	}

// 	const ids_to_delete = useDataImportStore.getState().inserted_ids_in_model;

// 	const { error } = await supabase
// 		.from('intelligence_data_model')
// 		.delete()
// 		.in('id', ids_to_delete);

// 	if (error) console.error(error);

// 	return true;
// };

export const rollbackTableCreation = (trigger: boolean, table_name: string) =>
	useQuery({
		queryKey: ['rollback_table_creation', trigger],
		queryFn: async () => {
			const { error } = await supabase.rpc('rollback_dynamic_table', {
				table_name,
			});
			if (error) throw new Error(error.message);
			return true;
		},
		enabled: trigger,
	});

export const createDynamicTable = (begin: boolean) => {
	const table_name = useDataImportStore.getState().table_name;

	return useQuery({
		queryKey: ['create_table', begin],
		queryFn: async () => {
			const { data, error } = await supabase.rpc('create_dynamic_table', {
				table_name,
			});

			if (error) {
				throw new Error(error.message);
			}

			return true;
		},
		enabled: begin,
	});
};

export const createDynamicTableColumns = (prevDone: boolean) => {
	const table_name = useDataImportStore.getState().table_name;
	const data = useDataImportStore.getState().dynamic_table_data;

	const columns = data.map(el => {
		const constraint =
			el.pkey !== 'null'
				? `${el.unique} ${el.pkey} ${el.identity}`
				: el.identity !== 'null'
				  ? `${el.unique} ${el.pkey} ${el.identity} ${el.is_json}`
				  : `${el.default} ${el.unique} ${el.pkey} ${el.identity} ${el.is_json}`;
		//Remove nulls if present.. Also removes multiple spaces between text
		const parsed = constraint
			.replace(/null/g, '')
			.replace(/\s\s+/g, ' ')
			.trim();
		//console.log(parsed);
		return {
			column_name: el.column_name,
			data_type: el.data_type,
			constr: parsed,
		};
	});

	return useQuery({
		queryKey: ['create_table_columns', prevDone],
		queryFn: async () => {
			let { data, error } = await supabase.rpc('create_dynamic_columns', {
				table_name,
				columns,
			});

			if (error) {
				throw new Error(error.message);
			}

			return true;
		},

		enabled: prevDone,
		retry: 5,
	});
};

export const createDynamicTableRows = (prevDone: boolean) => {
	const table_name = useDataImportStore.getState().table_name;

	const rows = useDataImportStore.getState().table_rows;
	return useQuery({
		queryKey: ['create_dynamic_table_rows', prevDone],
		queryFn: async () => {
			// const rows: Record<string, unknown> = {};
			// data.forEach((el, idx) => {
			// 	//let col_key = el.column_name;
			// 	let val = values[idx];
			// 	rows[el] = val;
			// });
			// const rows: Record<string, unknown> = {};
			// data.forEach((el, idx) => {
			// 	//let col_key = el.column_name;
			// 	let val = values[idx];
			// 	rows[el] = val;
			// });
			const { error } = await supabase.from(table_name).insert(rows);
			if (error) {
				console.log(error.message);
				throw new Error(error.message);
			}
			return true;
		},
		enabled: prevDone,
		retry: 5,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});
};
export const updateIntelligence = (prevDone: boolean) => {
	const headers = useDataImportStore.getState().headers;
	const table_name = useDataImportStore.getState().table_name;
	const levels = useHierarchyDefinitionStore.getState().levels;
	const set_added_rowIds =
		useDataImportStore.getState().set_inserted_ids_in_model;
	return useQuery({
		queryKey: ['update_intelligence', prevDone],
		queryFn: async () => {
			//get the labels from hierarchy_definition
			const { data: level_names, error } = await supabase
				.from('hierarchy_definition')
				.select('label')
				.order('id', { ascending: true });
			if (error) {
				console.log(error);
			}
			const res: Record<string, string> = {};
			let last_key: string = '';
			let res_array = [];
			if (level_names) {
				levels.forEach((el, idx) => {
					res[level_names[idx].label] = el.value;
				});

				level_names.forEach(el => {
					if (!(el.label in res)) {
						last_key = el.label;
					}
				});
				res[last_key] = '';

				for (let i = 0; i < headers.length; i++) {
					const header = headers[i];
					res[last_key] = header;
					res_array.push({ ...res });
				}

				const { data: insertedRowIds, error } = await supabase
					.from('intelligence_data_model')
					.insert(res_array)
					.select('id');

				if (insertedRowIds) {
					const ids = insertedRowIds.map(el => el.id);
					set_added_rowIds(ids);
				}

				if (error) {
					throw new Error(error.message);
					//	await rollbackIntelligenceDataAction(table_name);
				}
				return true;
			}
			return true;
		},
		enabled: prevDone,
		//retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});
};

export const uploadToBucket = (prevDone: boolean) => {
	const { user } = useAuth();
	const file = useDataImportStore.getState().file;
	const table_name = useDataImportStore.getState().table_name;

	return useQuery({
		queryKey: ['upload_to_storage_bucket', prevDone],
		queryFn: async () => {
			if (file) {
				const { error } = await supabase.storage
					.from('mlk')
					.upload(`${user?.id!}/${file?.name}`, file);
				if (error) {
					throw new Error(error.message);
				}

				return true;
			}
		},
		enabled: prevDone,
	});
};

export const updateHierarchyTableAndModel = (current_count: number) => {
	const levels = useHierarchyDefinitionStore.getState().levels;
	const setAddedRows = useDataImportStore.getState().set_added_rows;

	return useQuery({
		queryKey: ['update_hierarchy_table_and_hierarchy_data_model'],
		queryFn: async () => {
			let last_level_id = current_count;
			let rows_to_add = levels.length - last_level_id + 1;
			let rows = [];
			for (let i = 0; i < rows_to_add; i++) {
				const level = last_level_id + (i + 1);
				rows.push({
					id: level,
					level: level,
					label:
						level > 9
							? `Level${' '}${level}`
							: `Level${' '}0${''}${level}`,
				});
				//keep track of the added columns here --- > string[]
			}

			setAddedRows(rows);

			const { data, error } = await supabase
				.from('hierarchy_definition')
				.insert(rows)
				.select();
			if (error) console.log(error);
			// //update the intelligence data_model table with new column
			//console.log(data);
			if (data) {
				let { error: dataModelError } = await supabase.rpc(
					'add_column_to_data_model',
					{
						columns: data,
					},
				);

				if (error) console.error(error);
			}
			return true;
		},
		enabled: false,
	});
};

export const getPreviewData = (data: {
	headers: string[];
	modalOpen: boolean;
	parsed_data: Record<string, string>;
}) => {
	return useQuery({
		queryKey: [
			'get_preview_data',
			data.modalOpen,
			data.headers,
			data.parsed_data,
		],
		queryFn: async () => {
			const res = await fetch(
				`${process.env.REACT_APP_UMCG_BASE_URL}/preview-intelligence/`,

				{
					method: 'post',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
					body: JSON.stringify({
						column_names: data.headers,
						input_hierarchy: data.parsed_data,
						region_code: 'NL00',
					}),
				},
			);
			if (!res.ok) {
				throw new Error('Network response was not ok');
			}
			return res.json();
		},
		enabled: data.modalOpen,
	});
};
