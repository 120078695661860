import { CategoryType, DimensionSubType } from '../types/index.types';

export const useUtilityFunctions = () => {
	const getDimension: (dimension: DimensionSubType) => CategoryType = (
		dimension: DimensionSubType,
	) => {
		switch (dimension) {
			case 'Weather':
				return 'Environment';
			case 'Stock Exchange':
				return 'World';
			case 'Interest Rate':
				return 'World';
			default:
				return 'Environment';
		}
	};

	const getFormattedTime = (time: string) => {
		const timestamp = new Date(time);

		return timestamp
			.toLocaleString('en-GB', {
				year: '2-digit',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			})
			.replaceAll(',', '');
	};

	const splitCapitalizedText = (text: string) => {
		// Use a regular expression to split the text based on capitalization
		const words = text.split(/(?=[A-Z])/);
		// Join the words with spaces and convert to lowercase
		return words.join(' ');
	};
	return { getDimension, getFormattedTime, splitCapitalizedText };
};
