export const alertDescriptions: any = {
	Environment: {
		'Air Quality Index':
			'The DEFRA index, often referred to as the DEFRA Air Quality Index (AQI), is a system used by the Department for Environment, Food & Rural Affairs in the United Kingdom to assess and communicate air quality. It provides a numerical rating based on various air pollutants like particulate matter (PM2.5 and PM10), nitrogen dioxide (NO2), ozone (O3), sulfur dioxide (SO2), and carbon monoxide (CO). The index categorizes air quality from "Low" to "Very High," helping the public understand the current pollution levels and take appropriate health precautions. It aids in promoting awareness and mitigating the adverse effects of poor air quality on health and the environment.',
		'UV Index':
			'The UV index is a numerical scale that measures the strength of ultraviolet (UV) radiation from the sun at a specific location and time. It typically ranges from 0 to 11 or higher, with higher values indicating more intense UV radiation. The index helps people assess the potential sunburn and skin damage risk, with recommendations for protective measures like sunscreen, clothing, and sunglasses. It also considers factors such as altitude, cloud cover, and ozone levels. Monitoring the UV index is crucial for sun safety, as excessive UV exposure can lead to skin cancer, premature aging, and eye damage.',
		Humidity:
			'Humidity can significantly impact health. High humidity can make it harder for the body to cool down through sweating, leading to heat-related illnesses like heat exhaustion and heatstroke. It can also exacerbate respiratory issues as moist air can carry allergens and mold spores, triggering allergies and asthma. Conversely, low humidity can dry out mucous membranes, increasing the risk of respiratory infections, sore throats, and skin irritation. Maintaining balanced indoor humidity levels between 30-50% can help mitigate these health risks and create a more comfortable living environment, especially for those with allergies or respiratory conditions.',
	},
	World: {
		FED: 'The Federal Reserve (Fed) interest rate, often referred to as the federal funds rate, is the benchmark interest rate at which banks and credit unions lend reserves to other depository institutions overnight. It is a key tool used by the Fed to influence the economy. By raising or lowering this rate, the Fed can stimulate or cool down economic activity. A lower rate encourages borrowing and spending, while a higher rate discourages it. The Fed adjusts this rate based on its assessment of economic conditions to achieve its dual mandate of stable prices and maximum sustainable employment.',
		EURIBOR:
			'The EURIBOR (Euro Interbank Offered Rate) is the interest rate at which Eurozone banks lend unsecured funds to each other in the interbank market. It serves as a benchmark for various financial products and contracts, including loans, mortgages, and derivatives. EURIBOR is determined daily by a panel of major European banks based on their lending rates. It comes in different maturities, such as 1-week, 1-month, 3-month, 6-month, and 12-month rates. EURIBOR plays a crucial role in the European financial system, influencing borrowing costs and financial stability within the Eurozone. It helps determine the cost of borrowing for businesses and consumers.',
		BOE: "The Bank of England (BOE) interest rate, often referred to as the Bank Rate or the Official Bank Rate, is the benchmark interest rate set by the BOE's Monetary Policy Committee (MPC). It influences borrowing and lending rates across the UK economy. The MPC adjusts this rate to achieve its primary objectives: maintaining price stability by targeting a 2% inflation rate and supporting sustainable economic growth and employment. A higher rate tends to cool down inflation but may slow economic activity, while a lower rate encourages borrowing and spending to stimulate economic growth. It's a crucial tool for BOE's monetary policy.",
		BACEN: "The BACEN interest rate refers to the Selic rate in Brazil, set by the Central Bank of Brazil (Banco Central do Brasil, BACEN). It serves as the country's benchmark interest rate and influences the cost of borrowing and lending in the Brazilian financial system. The Central Bank adjusts the Selic rate to achieve its monetary policy objectives, primarily to control inflation and foster economic stability. A higher rate can help curb inflation but may slow economic growth, while a lower rate can stimulate economic activity but may lead to higher inflation. The Selic rate plays a crucial role in Brazil's economic management.",
		PBC: "The PBC interest rate refers to the People's Bank of China's (PBC) benchmark lending rate, known as the Loan Prime Rate (LPR) in China. The PBC sets this LPR as a reference rate for banks in the country to use when pricing loans to their customers. It plays a key role in China's monetary policy, impacting borrowing costs for businesses and consumers, thus influencing economic growth and inflation. The PBC adjusts the LPR to achieve its policy goals, such as supporting economic stability and growth. It reflects the central bank's efforts to fine-tune China's monetary conditions and credit markets.",
		ECB: 'The European Central Bank (ECB) interest rate is the key monetary policy rate for the Eurozone. Known as the Main Refinancing Operations (MRO) rate, it is the interest rate at which Eurozone banks can borrow funds from the ECB in exchange for collateral. The ECB uses this rate to influence money market conditions, inflation, and economic growth in the Eurozone. A lower rate encourages borrowing and spending, while a higher rate can help control inflation. The ECB Governing Council adjusts this rate based on its assessment of economic conditions to achieve its primary objectives of price stability and economic stability within the Eurozone.',
		RBA: 'The Reserve Bank of Australia (RBA) interest rate, known as the "Official Cash Rate" or "Cash Rate Target," is the central bank\'s benchmark interest rate. It represents the cost of borrowing funds overnight in the Australian money market. The RBA sets this rate to influence the country\'s economic conditions, with a primary focus on achieving its dual mandate of price stability (maintaining inflation within a target range) and sustainable economic growth. Changes in the Official Cash Rate have a cascading effect on borrowing and lending rates across the Australian financial system, impacting the cost of credit for consumers, businesses, and financial markets.',
		RBI: "The Reserve Bank of India (RBI) interest rate, known as the Repo Rate, is the rate at which the RBI lends short-term funds to commercial banks in India. It plays a central role in the country's monetary policy. The RBI adjusts the Repo Rate to control inflation, promote economic growth, and ensure financial stability. A higher rate can help curb inflation but may slow economic activity, while a lower rate can stimulate borrowing and spending to boost economic growth. The Repo Rate influences lending and deposit rates throughout the Indian financial system and impacts borrowing costs for businesses and consumers.",
		CBR: "The Central Bank of Russia (CBR) interest rate, also known as the IntelligenceKey Rate, is the benchmark interest rate set by the CBR. It influences borrowing and lending rates in Russia's financial system. The CBR adjusts this rate to achieve its monetary policy objectives, including controlling inflation and supporting economic stability. A higher IntelligenceKey Rate can help combat inflation but may slow economic growth, while a lower rate can stimulate economic activity but may lead to higher inflation. The IntelligenceKey Rate is a critical tool for the CBR in managing Russia's monetary conditions and fostering economic stability and growth.",
		NYSE: "The NYSE Composite Index is a stock market index that represents the combined performance of all common stocks listed on the New York Stock Exchange (NYSE). It includes a wide range of companies from various sectors and market capitalizations, offering a comprehensive view of the NYSE's overall performance. The index is a valuable tool for investors and analysts to gauge the health of the broader U.S. equities market. It is weighted by market capitalization, meaning larger companies have a greater influence on its movements. The NYSE Composite Index provides insights into the overall trends and sentiment in the American stock market.",
		NASDAQ: 'The NASDAQ Composite Index is a stock market benchmark that represents the performance of all common stocks listed on the NASDAQ stock exchange. It encompasses a diverse range of technology, biotechnology, and other companies, making it a key indicator for the broader technology sector. The index is market-capitalization-weighted, meaning larger companies have a more significant impact on its movements. The NASDAQ Composite is renowned for its focus on tech-heavy companies, including giants like Apple, Amazon, and Microsoft, making it a crucial gauge of the performance of the U.S. technology and innovation sectors. Investors use it to assess tech industry trends and market sentiment.',
		'EURONEXT 100':
			'The Euronext Composite Index, also known as the Euronext 100, is a stock market index representing the combined performance of the 100 largest and most liquid stocks listed on Euronext exchanges. Euronext operates multiple stock exchanges in Europe, including Amsterdam, Brussels, Dublin, Lisbon, Milan, Oslo, and Paris. The index provides a comprehensive view of the European stock market, reflecting a wide range of sectors and industries. It is a key indicator for investors and analysts assessing the overall health and trends in European equities markets, making it a valuable tool for portfolio analysis and market analysis across multiple European countries.',
		SSEC: "The Shanghai Composite Index (SSE Composite) is a major stock market index in China, tracking the performance of all stocks listed on the Shanghai Stock Exchange (SSE). It includes a wide range of companies from various sectors, providing insight into China's domestic stock market. The index is market capitalization-weighted, with larger companies exerting more influence. Investors and analysts use it to gauge the overall health of the Chinese stock market and as a barometer for sentiment in China's economy. The SSE Composite is an essential benchmark for both domestic and international investors looking to understand trends and developments in Chinese equities.",
		AEX: 'Euronext Amsterdam is a prominent stock exchange in the Netherlands and a subsidiary of Euronext, a pan-European stock exchange operator. It provides a platform for trading a wide range of financial instruments, including stocks, bonds, derivatives, and exchange-traded funds (ETFs). Euronext Amsterdam plays a vital role in the Dutch and European financial markets, offering access to both domestic and international investors. It hosts several key indices like the AEX Index, representing the top Dutch companies, making it a significant hub for investors and businesses seeking capital and liquidity in the European financial landscape.',
		'FTSE 100':
			'The FTSE 100, often referred to as the "Footsie," is a stock market index representing the 100 largest companies listed on the London Stock Exchange (LSE) by market capitalization. It is a crucial benchmark for the UK\'s equity market and includes a diverse range of sectors, including finance, energy, and consumer goods. The index is widely followed by investors and serves as a barometer for the health and performance of the British economy. The FTSE 100 is important for global investors and analysts, providing insights into trends in the UK stock market and the broader economic landscape.',
		'BEL 20':
			"The BEL 20, or Brussels Stock Exchange 20, is the principal stock market index in Belgium. It represents the performance of the 20 largest and most actively traded companies listed on Euronext Brussels, the primary stock exchange in Belgium. The BEL 20 provides a snapshot of the Belgian stock market's health and serves as a key indicator of the country's economic performance. Investors and analysts rely on it to track the performance of major Belgian corporations across various industries, making it a valuable tool for assessing market trends and sentiment in the Belgian economy.",
		'PSI 20':
			"The PSI 20, or Portugal Stock Index 20, is the primary stock market index in Portugal. It consists of the 20 largest and most liquid companies listed on Euronext Lisbon, the main stock exchange in Portugal. The PSI 20 serves as a benchmark for the Portuguese stock market, reflecting the performance of major Portuguese corporations from various sectors. It's a vital tool for investors and analysts to assess the health of the Portuguese economy and track market trends. The index's composition can change over time based on market capitalization and liquidity, providing insights into the dynamics of Portugal's equities market.",
		'CAC 40':
			"The CAC 40, or Cotation Assistée en Continu, is the principal stock market index in France. It represents the performance of the 40 largest and most actively traded companies listed on Euronext Paris, the main stock exchange in France. The CAC 40 is a key indicator of the French stock market's health and reflects a diverse range of sectors, including finance, healthcare, and energy. Investors and analysts use it to gauge economic trends and sentiment in France, making it an essential benchmark for both domestic and international investors seeking insights into the French equities market and broader economic conditions.",
		SZSE: "SZSE stands for the Shenzhen Stock Exchange, one of China's major stock exchanges, located in Shenzhen. Established in 1990, it complements the Shanghai Stock Exchange (SSE) in providing a platform for trading stocks, bonds, and other financial instruments. SZSE is known for its focus on innovative and technology-driven companies, including many listed on its ChiNext board, akin to NASDAQ in the United States. It plays a crucial role in China's capital markets, serving as a hub for both domestic and international investors interested in accessing opportunities in Chinese equities, particularly those in the southern part of the country.",
	},
};

export const viewColors: any = {
	Data: {
		mainColor: '#586FC5', //blue
		textColor: '#FFFFFF',
	},
	Attributes: {
		mainColor: '#586FC5', //blue
		textColor: '#FFFFFF',
	},
	Permissions: {
		mainColor: '#586FC5', //"#15ACA3", //green
		textColor: '#FFFFFF',
		blocked: '#15ACA3', //orange
	},
};

export const blockedPermissionsNodes = ['1.2.1', '1.2.3'];
export const blockedNonVioScoreNodes = ['1.1'];
