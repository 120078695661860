import { ColumnDef } from '@tanstack/react-table';
import { AlertStreamType } from '../types/index.types';
import Timestamp from './timestamp';

export const columns: ColumnDef<AlertStreamType>[] = [
	{
		accessorKey: 'created_at',
		header: 'Timestamp',
		cell: ({ row }) => <Timestamp val={row.original.created_at} />,
		size: 170,
	},
	{
		accessorKey: 'location',
		header: 'Location',
		size: 200,
	},
	{
		accessorKey: 'dimension',
		header: 'Dimension',
		size: 130,
	},
	{
		accessorKey: 'type',
		header: 'Type',
		size: 130,
	},
	{
		accessorKey: 'sub_type',
		header: 'Sub Type',
		size: 120,
	},
	{
		accessorKey: 'change_direction',
		header: 'Description',
		size: 400,
		cell: ({ row }) => {
			const { original } = row;
			return (
				<div>
					{original.change_direction === 'UP'
						? 'Improved'
						: 'Dropped'}
					{'  '}
					from {original.previous_rating} to {original.current_rating}{' '}
				</div>
			);
		},
	},
];
