import { baseURL, dataLineageHttp } from '../../commons/apiRoute';
//import { useHttp } from '../commons/https-commons';
import axios from 'axios';
import { ViewType } from '../../../types/index.types';

export const useDataLineage = () => {
	//const { http } = useHttp();

	const getProvinces = async ({
		country = 'Nederland',
		code = 'NL00',
	}: {
		country: string;
		code: string;
	}) => {
		try {
			return await axios.get(
				`$https://api-dev.kilimanjaro-production.app/api/v1/country_province?country_name=${country}`,
			);
		} catch (e) {
			return e;
		}
	};

	const getDataLineageData = async ({ code }: { code: string }) => {
		try {
			//This is the new temporal route for intelligence, currently not returning anything
			const res = await dataLineageHttp.get(
				'/intelligence/?region_code=NL00',
			);
			return res.data;
		} catch (e) {
			return e;
		}
	};

	const getAttributesLineageData = async ({ code }: { code: string }) => {
		try {
			//This is the new temporal route for intelligence, currently not returning anything
			const res = await dataLineageHttp.get(
				'/attribute-view/?region_code=NL00',
			);

			return res.data;
		} catch (e) {
			return e;
		}
	};

	const refreshTables = async () => {
		try {
			return await dataLineageHttp.get('/refresh_tables/');
		} catch (e) {
			return e;
		}
	};

	const getLineageLegend = async (view: ViewType) => {
		const res = await dataLineageHttp.get(
			`/legend/?view=${view + ' ' + 'View'}`,
		);
		return res.data;
	};

	const editIntelligenceView = async (data: any) => {
		try {
			const res = await dataLineageHttp.post('/edit-intelligence/', {
				...data,
			});
			return res.data;
		} catch (e) {
			return e;
		}
	};

	return {
		getDataLineageData,
		refreshTables,
		getLineageLegend,
		editIntelligenceView,
		getAttributesLineageData,
	};
};
