import React, { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../ui/table';
import useHierarchyDefinitionStore from '../../stores/hierarchyDefinitionStore';
import useDataImportStore from '../../stores/dataImportStore';
import { Input } from '../form/textField';
import { Link2, Settings } from 'lucide-react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';
import { Checkbox } from '../ui/checkbox';
import { supabase_datatypes } from '../../utils/data-types';
import AdditionalSettings from './additional-settings';
import clsx from 'clsx';
import SelectedCount from './selected-count';

const HierarchyTable = () => {
	const rows = useDataImportStore(state => state.dynamic_table_data);
	const setValue = useDataImportStore(state => state.reset_table_data_value);
	//console.log(rows[2]);
	return (
		<Table className='overflow-x-hidden'>
			<TableHeader>
				<TableRow className='border-b-white/10 font-grotesk font-semibold text-[12px] leading-4'>
					<TableHead className='w-[100px]'>Name</TableHead>
					<TableHead>Type</TableHead>
					<TableHead>Default Value</TableHead>
					<TableHead>Primary</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				{rows.map((row, idx) => (
					<TableRow
						key={idx}
						className={clsx(
							row.pkey != 'null'
								? 'border-y border-y-white/10'
								: 'border-none',
							'font-roboto font-semibold text-[12px] leading-7',
						)}
					>
						<TableCell className='font-medium w-[274px] gap-2 flex items-center'>
							<Input
								readOnly
								defaultValue={row.column_name}
								className='w-[230px] text-[#E0E0E0] text-[12px] font-semibold font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'
							/>
							<div className='w-9 h-9 flex px-2 items-center justify-center rounded-[4px] bg-white/10'>
								<Link2 className='text-mlk-light-blue' />
							</div>
						</TableCell>
						<TableCell className='w-[260px]'>
							<Select
								disabled={true}
								onValueChange={e =>
									setValue(idx, 'data_type', e)
								}
								defaultValue={row.data_type as string}
							>
								<SelectTrigger className='w-[230px] text-[12px] text-[#E0E0E0] font-semibold font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'>
									<SelectValue placeholder='text' />
								</SelectTrigger>
								<SelectContent
									align='end'
									className='bg-white text-black w-[220px]'
									onCloseAutoFocus={e => e.preventDefault()}
								>
									{supabase_datatypes.map((type, idx) => (
										<SelectItem
											className='font-grotesk font-semibold text-sm'
											//textValue='Edmundo'
											key={idx}
											value={type.value}
										>
											{type.label}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</TableCell>
						<TableCell className='w-[260px]'>
							<Select
								disabled={true}
								//disabled={row.pkey !== 'null'}
								//onValueChange={e => setValue(idx, e)}
								//	defaultValue={level.value}
							>
								<SelectTrigger className='w-[230px] text-[12px] text-[#E0E0E0] font-semibold font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'>
									<SelectValue placeholder='NULL' />
								</SelectTrigger>
								<SelectContent
									align='end'
									className='bg-white text-black'
									onCloseAutoFocus={e => e.preventDefault()}
								>
									<SelectItem value='default true'>
										TRUE
									</SelectItem>
									<SelectItem value='default false'>
										FALSE
									</SelectItem>
									<SelectItem value='default now()'>
										NOW()
									</SelectItem>
									{/* <SelectItem value=''>
										EMPTY
									</SelectItem> */}
									<SelectItem value='default ARRAY[]::jsonb[]'>
										{`[  ]`}
									</SelectItem>
								</SelectContent>
							</Select>
						</TableCell>
						<TableCell className='flex items-center gap-[6px] w-[150px] justify-around'>
							<Checkbox
								defaultChecked={row.pkey !== 'null'}
								onCheckedChange={e =>
									e
										? setValue(idx, 'pkey', 'primary key')
										: setValue(idx, 'pkey', 'null')
								}
							/>
							<div className='flex items-center gap-1'>
								<SelectedCount row={row} />
								<AdditionalSettings
									isNullable={row.default}
									isUnique={row.unique}
									isIdentity={row.identity}
									isJsonB={row.is_json}
									pkey={row.pkey}
									id={idx}
								/>
							</div>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

export default HierarchyTable;
