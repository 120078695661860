import { ArrowLeft, X } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { Separator } from '../components/ui/separator';
import { Link, useLoaderData, useLocation } from 'react-router-dom';
import bgImage from '../enso-i.png';
import logo from '../enzo_logo.png';
import KeyFeatues from '../components/appsServices/keyFeatues';
import Requirements from '../components/appsServices/requirements';
import Pricing from '../components/appsServices/pricing';
import Contact from '../components/appsServices/contact';
import { ScrollArea, ScrollBar } from '../components/ui/scrollArea';
import Overview from '../components/appsServices/overview';
import clsx from 'clsx';
import { Button } from '../components/ui/button';
import debounce from '../utils/debounce';
import { TDetailsType, TMiniSidebarNavType } from '../types/index.types';

const Details = () => {
	let data = useLoaderData() as TDetailsType;
	const miniSideBarData: TMiniSidebarNavType = [
		{ id: 'overview', name: 'Overview', component: <Overview /> },
		{ id: 'key_features', name: 'Key Features', component: <KeyFeatues /> },
		{
			id: 'requirements',
			name: 'Requirements',
			component: <Requirements />,
		},
		{ id: 'pricing', name: 'Pricing', component: <Pricing /> },
		{ id: 'contact', name: 'Contact', component: <Contact /> },
	];

	const [visibleSectionId, setVisibleSectionId] = useState<string | null>(
		miniSideBarData[0].id,
	);
	const [isOverlapping, setIsOverlapping] = useState(false);
	const breadCrumbRef = useRef<HTMLDivElement>(null);
	const sideNavRef = useRef<HTMLDivElement>(null);
	const ref = useRef<HTMLDivElement>(null!);
	const sections = useRef<NodeListOf<HTMLDivElement>>();

	const [isScrolling, setIsScrolling] = useState(false);

	function checkIfSideNavIsOverlapping() {
		if (breadCrumbRef.current && sideNavRef.current) {
			const a = breadCrumbRef.current.getBoundingClientRect();
			const b = sideNavRef.current.getBoundingClientRect();

			if (a.top <= b.top + b.height && a.top + a.height > b.top) {
				setIsOverlapping(true);
			} else {
				setIsOverlapping(false);
			}
		}
	}
	function isElementInViewport(el: HTMLElement) {
		const rect = el.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <=
				(window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <=
				(window.innerWidth || document.documentElement.clientWidth)
		);
	}

	useEffect(() => {
		const handleScroll = debounce(() => {
			checkIfSideNavIsOverlapping();
			if (!isScrolling) {
				sections.current = document.querySelectorAll('[data-section]');
				sections.current?.forEach(section => {
					if (isElementInViewport(section)) {
						setVisibleSectionId(section.id);
					}
				});
			}
		}, 50);
		window.addEventListener('scroll', handleScroll, true);
		return () => {
			window.removeEventListener('scroll', handleScroll, true);
		};
	}, [isScrolling]);

	return (
		<div className='h-full overflow-y-auto'>
			<div
				ref={breadCrumbRef}
				className='flex w-full border border-t-0  border-b-white/20 border-x-0 items-center px-6  justify-between h-[47px] bg-mlk-dark-blue'
			>
				<div className='flex gap-2 items-center'>
					<Link
						to='/'
						className='rounded-full w-7 h-7 hover:border hover:border-white/10 flex items-center justify-center p-1'
					>
						<ArrowLeft size={24} className='text-mlk-light-blue' />
					</Link>
					<p className='font-roboto font-medium text-xs leading-[14.06px] text-white'>
						Apps & Services
					</p>
					<Separator
						className='border border-white/20 h-4'
						orientation='vertical'
					/>
					<p className='font-roboto font-medium text-xs leading-[14.06px] text-white'>
						{data.breadcrumb_text}
					</p>
				</div>
				{data.url.includes('Ensō-i') && (
					<div className='flex flex-row gap-2 items-center'>
						<div className='rounded-full w-5 h-5 bg-white flex items-center justify-center p-1'>
							<X size={16} className='text-black' />
						</div>
						<p className='font-roboto font-medium text-xs leading-[14.06px]'>
							NOT INSTALLED
						</p>
					</div>
				)}
			</div>

			{/* Content Area */}
			<ScrollArea
				//style={{ height: `calc(100vh - 8rem)` }}
				className='overflow-y-auto'
			>
				{/* 9.08rem */}
				<div
					className='m-6 flex flex-col gap-6 mb-[49px]'
					style={{ width: `calc(100vw - 128px)` }}
				>
					<div
						className=' h-[256px] bg-cover bg-center px-6 flex flex-col justify-between py-5 '
						style={{ backgroundImage: `url(${bgImage})` }}
					>
						<div className='flex items-start flex-col gap-[15px]  w-3/4 md:w-2/4 lg:w-2/5'>
							<p className='font-medium text-4xl md:text-5xl  leading-[58.25px] text-white font-roboto'>
								{data.header_text}
							</p>
						</div>
						{data.url.includes('Ensō-i') && (
							<div className='self-end text-white'>
								<img
									src={logo}
									//width={169}
									height={32}
									alt='enzo_logo'
									//className='w-[100px] md:w-[169px]'
								/>
							</div>
						)}
					</div>
				</div>

				<div
					ref={sideNavRef}
					className='px-6 py-[25px] flex flex-1 items-start'
				>
					<div
						className={clsx(
							isOverlapping && 'pt-[24px]',
							'flex flex-col w-[170px] justify-start sticky top-0',
						)}
					>
						{miniSideBarData.map((el, idx) => (
							<Button
								variant='link'
								key={el.id}
								onClick={() => {
									setIsScrolling(true);
									const currentNode =
										ref.current.children[idx];

									currentNode.scrollIntoView({
										behavior: 'smooth',
										//block: 'center',
									});
									setTimeout(() => {
										setIsScrolling(false);
										setVisibleSectionId(el.id);
									}, 550);
								}}
								className={clsx(
									visibleSectionId?.includes(el.id)
										? 'border-l-mlk-light-blue font-bold'
										: 'border-l-white/20 font-normal',
									'h-8 border-l-[3px] justify-start font-roboto text-xs rounded-none decoration-transparent leading-8 text-left px-2',
								)}
							>
								{el.name}
							</Button>
						))}
					</div>

					<div
						className='flex flex-col justify-start w-[600px] font-roboto text-white'
						ref={ref}
					>
						{miniSideBarData.map(section => (
							<div
								data-section
								key={section.id}
								id={section.id}
								//className='border border-red-600'
							>
								{section.component}
							</div>
						))}
					</div>
				</div>
			</ScrollArea>
			{/* Hero section */}
		</div>
	);
};

export default Details;
