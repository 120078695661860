import React from 'react';
import { Separator } from '../ui/separator';
import { cn } from '../../utils/cn';

type TProps = {
	label: string;
	className: string;
};
const RateLabel = ({ label, className }: TProps) => {
	return (
		<div
			className={cn(
				'flex absolute flex-col items-center  gap-0',
				className,
			)}
		>
			<Separator
				orientation='vertical'
				className='h-9 border-2 border-dotted border-[#484D5D]'
			/>
			<p className='font-roboto font-bold text-xs leading-[14.06px] text-white'>
				{label}
			</p>
		</div>
	);
};

export default RateLabel;
