import React, { useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { uploadToStorageBucket } from '../requests/services/data-import/hook';
import { parse, ParseResult } from 'papaparse';
import UploadCard from '../components/data-manager/upload-options-card';
import StorageBucketLoadingCard from '../components/data-manager/storagebucket-loading-card';
import DatabaseLoadingCard from '../components/data-manager/database-loading-card';
import useDataImportStore from '../stores/dataImportStore';
import { useQueryClient } from '@tanstack/react-query';

const DataImport = () => {
	const [storage, setStorage] = React.useState<'db' | 'storage'>();
	const [file, setFile] = React.useState<File>();
	const [searchParams, setSearchParams] = useSearchParams();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const setStoreFile = useDataImportStore(state => state.setStoreFile);
	const setTableName = useDataImportStore(state => state.setTableName);
	const setHeaders = useDataImportStore(state => state.setHeaders);
	const setRows = useDataImportStore(state => state.setRows);
	const setTableRows = useDataImportStore(state => state.set_table_rows);
	const page = searchParams.get('page');
	const [error, setError] = React.useState<string | undefined>();

	React.useEffect(() => {
		setSearchParams(undefined);
		setStorage('db');
		//reset the loading states
		queryClient.resetQueries({
			queryKey: [
				'create_dynamic_table',
				'create_dynamic_columns',
				'create_dynamic_table_rows',
				'update_intelligence',
				'upload_to_storage_bucket',
			],
		});
	}, []);

	const { mutate, isPending, isError, isSuccess } = uploadToStorageBucket();
	//const val = useUploadToDatabaseAndStorageBucket(headers);

	const onDrop = React.useCallback(
		(files: File[]) => {
			setFile(files.at(0));
			if (storage === 'storage') {
				setSearchParams({ page: 'sloading' });
				if (files.length > 0) {
					mutate(files.at(0) as File, {
						onError(error, variables, context) {
							setError(error.message);
						},
					});
				}
			}

			if (storage === 'db') {
				if (files.length > 0) {
					setStoreFile(files.at(0)!);
					setTableName(files[0].name);
					parse(files[0], {
						header: true,
						encoding: 'UTF-8',
						//transformHeader: header => header.replace(/ /g, '_'),
						// .replace(/^[.\s]+|[.\s]+$/g, '')
						// //.trim()
						// .replace('.', '_')
						// .replace(/ /g, '_')
						// .replace(':', '_')
						// .replace('/', '_')
						// .replace('__', '_'),
						dynamicTyping: true,
						delimiter: ',',
						skipEmptyLines: true,
						complete: function (
							results: ParseResult<Record<string, unknown>>,
						) {
							const headersArray: any = [];
							const rowsArray: any = [];
							const tableRowsArray: any = [];
							//console.log(results.data);
							results.data.map(d => {
								headersArray.push(Object.keys(d));
								rowsArray.push(Object.values(d));
								tableRowsArray.push(d);
							});

							setRows(rowsArray.at(0));
							setHeaders(headersArray.at(0));
							setTableRows([...tableRowsArray]);
							setTableRows([...tableRowsArray]);
							navigate('/intelligence/data-import/hierarchy');
						},
					});
				}
			}
		},
		[setSearchParams, searchParams, storage],
	);
	const { open, getInputProps } = useDropzone({
		//noClick: true,
		noKeyboard: true,
		onDrop,
		accept: {
			'text/csv': ['.csv'],
		},
	});
	return (
		<div className='w-full h-screen flex items-center justify-center'>
			{/* Upload Options Card */}
			{!page && <UploadCard open={open} setStorage={setStorage} />}
			<input className='hidden' {...getInputProps()} type='file' />
			{/* Loading state Card */}
			{page === 'sloading' && (
				<StorageBucketLoadingCard
					setStorage={setStorage}
					error={error || ''}
					file={file}
					isError={isError}
					isPending={isPending}
					isSuccess={isSuccess}
				/>
			)}

			{/* {page === 'next-steps' && <NextStepsCard />} */}

			{page === 'db-loading' && <DatabaseLoadingCard />}
		</div>
	);
};

export default DataImport;
