import axios, { isAxiosError } from 'axios';

export const searchPostCode = async (lookup: string) => {
	const postcode = lookup.substring(0, 6);
	const house_number = lookup.slice(6);
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_UMCG_BASE_URL}/vioscore/`,
			{
				params: {
					postcode,
					housenumber: house_number,
				},
			},
		);
		return response.data;
	} catch (error) {
		if (isAxiosError(error)) {
			console.log(error.message);
		}
		console.log(error);
	}
};
