import React from 'react';
import HierarchyTable from './hierarchy-table';

const ColumnsView = () => {
	return (
		<div className='flex flex-grow-0 flex-1 justify-start flex-col px-5 pt-5 gap-2 min-w-fit overflow-x-hidden'>
			<p className='mb-4 font-grotesk font-semibold text-[20px] leading-[24.69px]'>
				Columns
			</p>
			<p className='font-grotesk font-normal text-[12px] leading-4 w-[584px]'>
				The following columns will be created from the headers found in
				the first row of your file.Data types have been automatically
				assigned, but you can change these if you wish.{' '}
				<span className='font-bold'>
					Please select one or more primary keys.
				</span>
			</p>
			<div className='px-auto'>
				<HierarchyTable />
			</div>
		</div>
	);
};

export default ColumnsView;
