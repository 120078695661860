import React, { Fragment } from 'react';
import { useLoaderData } from 'react-router-dom';
import { TDetailsType } from '../../types/index.types';

const KeyFeatues = () => {
	let data = useLoaderData() as TDetailsType;
	return (
		<div
			className='flex flex-col py-10 border-t gap-5 border-t-white/20 pt-[30px]'
			//id='key_features'
		>
			<h4 className='font-medium text-3xl leading-[35.16px]'>
				Key Features
			</h4>
			<div className='flex flex-col gap-5'>
				{data.key_features.sections.map((section, idx) => (
					<div key={idx}>
						<h5 className='font-bold mb-2 text-sm leading-[14.06px]'>
							{section.title}
						</h5>
						<p className='font-normal text-xs leading-[18px]'>
							{section.text}
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default KeyFeatues;
