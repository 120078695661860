import React, { useContext } from 'react';
import { cn } from '../../utils/cn';
import { Button } from '../ui/button';
import { Link } from 'react-router-dom';
import { AppsServiceDataType } from '../../types/index.types';
import { SidebarContext } from '../../context/sidebarContext';
import clsx from 'clsx';

interface TProps extends AppsServiceDataType {
	className?: string;
}
const AppsCard = ({
	className,
	icon,
	subtitle,
	description,
	id,
	url,
}: TProps) => {
	const { navCollapsed } = useContext(SidebarContext);
	const isEnzoCard = id.includes('Ensō-i');

	return (
		<div
			className={cn(
				' bg-[#131828] border border-[#313648] rounded-sm shadow flex flex-col flex-shrink-0',
				className,
			)}
		>
			<div className='h-[180px] flex items-center justify-center'>
				{icon}
			</div>
			<div className='p-4 bg-mlk-blue'>
				<div className='w-[150px] flex flex-col gap-1 mb-2'>
					<p className=' text-xl leading-[24.69px] capitalize font-medium  text-white font-grotesk'>
						{id}
					</p>

					<span className='font-normal italic text-sm leading-[17.28px]'>
						{subtitle ? subtitle : 'My Life Kit'}
					</span>
				</div>

				<div className='h-24 mb-8'>
					<p className=' font-normal text-white text-xs leading-4 font-grotesk'>
						{description}
					</p>
				</div>

				<div className='flex flex-row items-center justify-start gap-3'>
					{isEnzoCard ? (
						<>
							<Button
								asChild
								variant='outline'
								className={clsx(
									'border font-grotesk rounded-[4px] font-bold text-xs leading-[14.81px] text-white hover:text-white border-mlk-light-blue',
									navCollapsed
										? 'w-[130px] lg:w-[137px]'
										: 'w-[120px]',
									'bg-mlk-light-blue h-9 hover:bg-mlk-light-blue border border-mlk-light-blue',
								)}
							>
								<Link to={'/details/Ensō-i'}>
									FIND OUT MORE
								</Link>
							</Button>
							<div
								className={clsx(
									navCollapsed ? 'w-[130px]' : 'w-[110px]',
								)}
							></div>
						</>
					) : (
						<>
							<Button
								asChild
								disabled
								variant='outline'
								className={clsx(
									'border font-grotesk rounded-[4px] font-bold text-[6px] md:text-xs leading-[14.81px] text-mlk-light-blue border-mlk-light-blue w-[120px] lg:w-[140px]',

									'bg-[#1A2034] h-9 border border-mlk-light-blue',
								)}
							>
								<Link to={`/details/${id}`}>MORE INFO</Link>
							</Button>

							<Button
								asChild
								variant='outline'
								className={clsx(
									'border font-grotesk rounded-[4px] font-bold text-[6px] md:text-xs leading-[14.81px] text-white hover:text-white border-mlk-light-blue w-[120px] lg:w-[140px]',
									'bg-mlk-light-blue h-9 hover:bg-mlk-light-blue border border-mlk-light-blue',
								)}
							>
								<Link to={url}>LAUNCH</Link>
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default AppsCard;
