import React from 'react';
import { Button } from '../ui/button';
import useDataManagerStore from '../../stores/datamanagerStore';
import clsx from 'clsx';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { THierarchyData } from '../../types/index.types';

const ToggleDown = ({
	index,
	data,
}: {
	index: number;
	data: THierarchyData[];
}) => {
	const moveDown = useDataManagerStore(state => state.moveDown);
	//const data = useDataManagerStore(state => state.items);
	return (
		<Button
			onClick={() => moveDown(index, index + 1)}
			variant='outline'
			size='icon'
			className={clsx(
				index == data.length - 1 && 'hidden',
				'rounded-full border-2 hover:bg-mlk-light-blue hover:text-white text-mlk-light-blue font-semibold border-mlk-light-blue w-5 h-5 p-1',
			)}
		>
			<ArrowDown className='w-3 h-3' strokeWidth={5} />
		</Button>
	);
};

export default ToggleDown;
