import clsx from 'clsx';
import { ArrowLeft } from 'lucide-react';
import {
	Link,
	useLoaderData,
	useLocation,
	useRevalidator,
	useSearchParams,
} from 'react-router-dom';
import { Button } from '../components/ui/button';
import RateLabel from '../components/rate-your-day/rate-label';
import RateVioscore from '../components/rate-your-day/rateVioscore';
import { Inhabitant } from '../types/index.types';
import { updateInhabitantRating } from '../requests/services/rate_your_day/inihabitants';
import useInhabitantStore from '../stores/inhabitantStore';

const InhabitantRating = () => {
	const data = useLoaderData() as Inhabitant;
	const revalidator = useRevalidator();
	const mutation = updateInhabitantRating(data.id);
	let [searchParams, setSearchParams] = useSearchParams();
	const newQueryParams = searchParams.get('rating');
	const currentPostcode = useInhabitantStore(state => state.postcode);

	return (
		<div className='py-7'>
			<div className=' px-8 w-full flex items-center  justify-center'>
				<div className='bg-mlk-blue border flex flex-col rounded-md border-gray-600 w-full'>
					<div className='w-full flex-1'>
						<div className='flex items-center flex-1 h-[69px] mx-[21px] border-b border-b-gray-600 gap-1'>
							<Link
								to={'/ratingApp'}
								className='rounded-full w-8 h-8 hover:border hover:border-white/10 flex items-center justify-center p-1'
							>
								<ArrowLeft
									size={24}
									className='text-mlk-light-blue'
								/>
							</Link>
							<p className='font-roboto font-normal text-2xl leading-[28.13px] text-white'>
								{currentPostcode} :{' '}
								{`${data.first_name} ${data.last_name}`}
							</p>
						</div>
						{/* Rate scale  */}
						<div
							className={clsx(
								newQueryParams && 'hidden',
								'flex w-full  flex-col  h-[calc(100vh_-_210px)] flex-1 items-center justify-center pb-[69px]',
							)}
						>
							<p className='font-roboto font-bold text-2xl leading-[28.13px] mb-[71px] text-white'>
								Rate Your Day
							</p>
							<div className='flex relative items-center gap-5'>
								<RateLabel
									label='Really Bad'
									className='top-10 -left-2'
								/>
								<RateLabel
									label='Really Good'
									className='top-10 -right-3'
								/>

								{Array.from({ length: 11 }, (_, idx) => (
									<Button
										key={idx}
										variant='outline'
										onClick={() => {
											setSearchParams({
												rating: idx.toString(),
											});
											mutation.mutate(idx, {
												onSuccess: () => {
													return revalidator.revalidate();
												},
											});
										}}
										className='focus:bg-mlk-light-blue hover:bg-mlk-light-blue hover:text-white hover:border-mlk-light-blue rounded-full border-2 border-gray-600 text-white'
										size='icon'
									>
										{idx}
									</Button>
								))}
							</div>
						</div>
						{/* pass the lookup id to this component */}
						{newQueryParams !== null ? (
							<RateVioscore tableData={data.rating_history} />
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default InhabitantRating;
