import React from 'react';

const PathIcon = () => {
	return (
		<svg
			width='10'
			height='5'
			viewBox='0 0 10 5'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M5 5.00002L0 0.0250244H10L5 5.00002Z' fill='#00B9F7' />
		</svg>
	);
};

export default PathIcon;
