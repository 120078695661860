import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, Outlet } from 'react-router-dom';
import { z } from 'zod';
import { PasswordResetSchema, resetSchema } from '../../utils/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import useResetStore from '../../stores/passwordResetStore';
import { useAuth } from '../../context/supabaseAuthContext';

const PasswordResetContext = () => {
	const { user } = useAuth();

	const event = useResetStore(state => state.event);
	const data = useResetStore(state => state.data);
	const methods = useForm<PasswordResetSchema>({
		resolver: zodResolver(resetSchema),
		defaultValues: { ...data },
	});
	console.log(event);
	if (!user && event !== 'PASSWORD_RECOVERY') {
		return <Navigate to='/login' replace />;
	}
	return (
		<FormProvider {...methods}>
			<Outlet />
		</FormProvider>
	);
};

export default PasswordResetContext;
