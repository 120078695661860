import React, { FC } from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorPage: FC = () => {
	const error: any = useRouteError();
	console.error(error);

	return (
		<div
			id='error-page'
			className='flex items-center justify-center h-screen w-screen flex-col space-y-10 bg-mlk-dark-blue text-white'
		>
			<h1 className='text-5xl font-bold'>Oops!</h1>
			<p>Sorry, an unexpected error has occurred.</p>
			<p className='text-gray-400'>
				<i>{error.statusText || error.message}</i>
			</p>
		</div>
	);
};

export default ErrorPage;
