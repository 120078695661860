import { isFuture, isToday } from 'date-fns';
import { z } from 'zod';
import capitalize from './capitalize';

const authSchema = z.object({
	username: z
		.string({ required_error: 'Please Enter Your Email' })
		.email({ message: 'Please Enter a Valid Email Address' }),
	password: z
		.string({ required_error: 'This field is required' })
		.min(1, { message: 'Please Enter Your Password' }),
	pin: z
		.string({ required_error: 'Please Enter The Verification Code' })
		.length(6, { message: 'Verification Code has to be Four Digits' }),
});

export type Login = z.infer<typeof authSchema>;

const resetSchema = z
	.object({
		password: z
			.string({ required_error: 'This field is required' })
			.min(6, 'Password Should be at least 6 Characters'),
		confirm: z
			.string({ required_error: 'This field is required' })
			.min(1, 'Please Confirm Your New Password'),
	})
	.superRefine((vals, ctx) => {
		if (vals.confirm !== vals.password) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Passwords do not match',
				path: ['confirm'],
			});
		}
	});

const postCodeSchema = z.object({
	postcode: z
		.string()
		.min(7, { message: 'Please Enter a Valid Postcode + House Number' })
		.trim(),
	// .regex(
	// 	/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2} (\w?[0-9]+[a-zA-Z0-9\- ]*)$/i,
	// 	{
	// 		message: 'This is not a valid address',
	// 	},
	// ),
});

export type postCode = z.infer<typeof postCodeSchema>;
const GenderValues = ['Male', 'Female', 'Other'] as const;
const inhibitantSchema = z.object({
	first_name: z
		.string()
		.min(1, 'This field is required')
		.transform(val => capitalize(val)),
	last_name: z
		.string()
		.min(1, 'This field is required')
		.transform(val => capitalize(val)),

	dob: z
		.object({
			day: z
				.string()
				.length(2, { message: 'Day Input Should be of DD format' })
				.refine(
					val => {
						let n = Number(val);
						return !isNaN(n) && n <= 31 && n > 0;
					},
					{
						message: 'Input Value for Day is Invalid',
					},
				),
			month: z
				.string()
				.length(2, { message: 'Month Input Should be of MM format' })
				.refine(
					val => {
						let n = Number(val);
						return !isNaN(n) && n <= 12 && n > 0;
					},
					{
						message: 'Input Value for Month is Invalid',
					},
				)
				.transform(val => Number(val) - 1),
			year: z
				.string()
				.length(4, { message: 'Year Input Should be of YYYY format' })
				.refine(
					val => {
						let n = Number(val);
						return (
							!isNaN(n) &&
							n <= new Date().getFullYear() &&
							n > 1900
						);
					},
					{
						message: 'Input Value for Year is not Accepted',
					},
				),
		})
		.superRefine((date, ctx) => {
			const parsedDob = new Date(
				+date.year,
				+date.month,
				+date.day,
			).toISOString();

			if (isToday(parsedDob) || isFuture(parsedDob)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Future/Today Date is not Accepted',
				});
			}
		}),

	gender: z.enum(GenderValues, {
		errorMap: (issue, ctx) => {
			if (
				issue.code === 'invalid_enum_value' ||
				issue.code === 'invalid_type'
			) {
				return { message: 'Please Select a Gender' };
			}
			return { message: issue.message ?? '' };
		},
	}),
});

export type TInhibitantSchema = z.infer<typeof inhibitantSchema>;

export type PasswordResetSchema = z.infer<typeof resetSchema>;
export {
	authSchema,
	resetSchema,
	postCodeSchema,
	inhibitantSchema,
	GenderValues,
};
