import { ArrowLeft } from 'lucide-react';
import React, { useMemo } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import HistoryTable from '../components/rate-your-day/historyTable';
import { historyColumns } from '../components/rate-your-day/historyColumns';
import { Inhabitant } from '../types/index.types';
import parsePostcode from '../utils/parsePostcode';

const History = () => {
	const data = useLoaderData() as Inhabitant;
	const sortedTableData = useMemo(
		() =>
			data.rating_history?.sort(
				(a, b) => +new Date(b.created_at) - +new Date(a.created_at),
			),

		[data],
	);
	return (
		<div className='py-7'>
			<div className=' px-8 w-full flex items-center  justify-center'>
				<div className='bg-mlk-blue border flex flex-col rounded-md border-gray-600 w-full'>
					<div className='w-full flex-1'>
						<div className='flex items-center flex-1 h-[69px] mx-[21px] mb-12 border-b border-b-gray-600 gap-1'>
							<Link
								to={'/ratingApp'}
								className='rounded-full w-8 h-8 hover:border hover:border-white/10 flex items-center justify-center p-1'
							>
								<ArrowLeft
									size={24}
									className='text-mlk-light-blue'
								/>
							</Link>
							<p className='font-roboto font-normal text-2xl leading-[28.13px] text-white'>
								{parsePostcode(data.postcode)} :{' '}
								{`${data.first_name} ${data.last_name}`}
							</p>
						</div>
						{/* Rate scale  */}

						{/* pass the lookup id to this component */}
						<div className='py-10 flex items-center justify-center w-full'>
							<HistoryTable
								data={sortedTableData || []}
								columns={historyColumns}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default History;
