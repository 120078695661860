import { ColumnDef } from '@tanstack/react-table';
import { IHistory } from '../../types/index.types';
import Timestamp from '../timestamp';
import HistoryTimestamp from './history-timestamp';

export const historyColumns: ColumnDef<IHistory>[] = [
	{
		accessorKey: 'created_at',
		header: 'Date & Time',
		cell: ({ row }) => <HistoryTimestamp val={row.original.created_at} />,
		size: 100,
	},
	{
		accessorKey: 'rating',
		header: 'Rate Your Day',
		cell: ({ row }) => <div className='pl-10'>{row.original.rating}</div>,
		size: 80,
	},
	{
		accessorKey: 'vioscore',
		header: 'VioScore™',
		cell: ({ row }) => <span>{Math.round(row.original.vioscore)}</span>,
		size: 130,
	},
];
