import React, { FC } from 'react';
import { ButtonType } from '../../types/index.types';

const Button: FC<ButtonType> = ({ children, ...props }) => {
	return (
		<button
			{...props}
			className='flex items-center rounded-lg text-white bg-mlk-light-blue py-3 px-8 text-lg font-medium disabled:opacity-40'
		>
			{children}
		</button>
	);
};

export default Button;
