import React, {
	Dispatch,
	FC,
	FormEventHandler,
	SetStateAction,
	useState,
} from 'react';
import Button from '../form/button';
import { motion } from 'framer-motion';
import { PersonSvg } from '../svgs';
import Spinner from '../loaders/spinner';
import { FormCurrentState } from '../../types/index.types';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { Login } from '../../utils/schema';
import { Input } from '../form/textField';

const UsernameForm = ({
	setFormState,
}: {
	setFormState: Dispatch<SetStateAction<FormCurrentState>>;
}) => {
	const [loading, setLoading] = useState(false);
	const {
		control,
		formState: { errors },
		trigger,
		register,
		handleSubmit,
	} = useFormContext<Login>();
	const onSubmit = async () => {
		//console.log('validating..');
		let validated = await trigger('username');
		//console.log(errors);
		if (validated) {
			setFormState('password');
		}
	};

	return (
		<motion.div
			className='w-full max-w-sm'
			initial={{ opacity: 1 }}
			exit={{ opacity: 0, x: '-100%' }}
			transition={{ duration: 0.5 }}
		>
			{/*UsernameForm form*/}
			<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
				<h1 className='font-bold text-xl mb-8'>Enter your username</h1>
				<div className='relative'>
					<p className='absolute left-5 top-0  -translate-y-1/2 z-2 bg-[#1A2034] px-1 font-medium text-mlk-light-blue'>
						Username
					</p>
					<Input
						{...register('username')}
						placeholder='ie: user@email.com'
					/>

					<div className='pointer-events-none absolute top-1/2 -translate-y-1/2 left-2'>
						{<PersonSvg />}
					</div>
				</div>

				{errors.username && (
					<small className='text-red-500 my-2 block !h-4'>
						{errors.username.message as string}
					</small>
				)}
				<div className='flex space-x-5 justify-between items-center mt-3'>
					<div />
					{/* <p className='text-mlk-light-blue'>
						Forgotten your username?
					</p> */}
					<Button onClick={onSubmit}>
						{/* {loading && <Spinner className='w-[18px]' />} */}
						<span>Next</span>
					</Button>
				</div>
			</form>
		</motion.div>
	);
};

export default UsernameForm;
