import React from 'react';
import { Card, CardContent, CardHeader } from '../ui/card';
import { RadioGroup, RadioGroupItem } from '../ui/radiogroup';
import { Label } from '../ui/label';
import { Separator } from '../ui/separator';
import { Button } from '../ui/button';

type StorageType = 'db' | 'storage' | undefined;
type TProps = {
	setStorage: React.Dispatch<
		React.SetStateAction<'db' | 'storage' | undefined>
	>;
	open: () => void;
};
const UploadCard = ({ setStorage, open }: TProps) => {
	return (
		<Card className='w-[620px] h-[430px] rounded-[4px] border-[#313648] bg-mlk-blue '>
			<CardHeader className='font-grotesk text-[20px] font-semibold leading-[24.69px]'>
				Import a CSV
			</CardHeader>

			<CardContent>
				<RadioGroup
					defaultValue='db'
					className='mb-[30px]'
					onValueChange={e => setStorage(e as StorageType)}
				>
					<div className='flex flex-col gap-[26px]'>
						<div className='flex items-center space-x-2'>
							<RadioGroupItem
								className='border-2 border-mlk-light-blue w-5 h-5 text-mlk-light-blue'
								value='db'
								id='db'
							/>

							<Label
								htmlFor='db'
								className='font-bold text-xs leading-7'
							>
								Import to Database, Intelligence AND Storage
								Bucket
							</Label>
						</div>
						<div className='pl-[33px] pr-[21px]'>
							<ol className='space-y-3 list-decimal list-outside font-grotesk font-normal text-[12px] leading-4'>
								<li>
									The first row of your CSV should be the
									headers of the table, and your headers
									should not include any special characters
									other than hyphens (-) or underscores (_).
									<span className='italic text-white/50'>
										<br />
										Tip: Datetime columns should be
										formatted as YYYY-MM-DD HH:mm:ss
									</span>
								</li>
								<li>
									By default, the headers of your table will
									be imported as data points into:
									<br />
									<br />
									<span className='font-bold'>
										Nederland {'>'} Non-VioScore™ {'>'}{' '}
									</span>
									Undefined {'>'} Undefined <br />
									<br />
									...but you will be able to edit this after
									selecting the file you would like to import.
								</li>
							</ol>
						</div>

						<div className='flex items-center space-x-2'>
							<RadioGroupItem
								value='storage'
								className='border-2 border-mlk-light-blue w-5 h-5 text-mlk-light-blue'
								id='storage'
							/>
							<Label
								htmlFor='storage'
								className='font-bold text-xs leading-7'
							>
								Import to Storage Bucket ONLY
							</Label>
						</div>
					</div>
				</RadioGroup>
				<Separator
					className='border border-[#313648] w-full mb-4'
					orientation='horizontal'
				/>
				<Button
					onClick={open}
					className='font-grotesk text-[12px] leading-[14.81px] font-bold bg-mlk-light-blue w-[137px] h-9 rounded-[4px] hover:bg-mlk-light-blue/90 text-white'
				>
					SELECT FILE
				</Button>
			</CardContent>
		</Card>
	);
};

export default UploadCard;
