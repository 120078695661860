import React, { FC } from 'react';
import Cardiology from '../../assets/icon_cardiology.svg';
import Credit from '../../assets/icon_credit_card.svg';
import Location from '../../assets/icon_location.svg';
import World from '../../assets/icon_world.svg';
import { AlertStreamType, CategoryType } from '../../types/index.types';
import clsx from 'clsx';
import AlertCardSkeleton from '../loaders/AlertCardSkeleton';
import { useFetchRecentAlerts } from '../../requests/services/alerts/hooks';

const Tab: FC<{
	alerts: AlertStreamType[] | undefined;
	category: CategoryType;
	selectAlert: (alert: AlertStreamType) => void;
}> = ({ selectAlert, category, alerts }) => {
	const primaryColor =
		category === 'Health'
			? 'mlk-red'
			: category === 'Credit'
			  ? 'mlk-yellow'
			  : category === 'Environment'
			    ? 'mlk-green'
			    : 'mlk-light-blue';

	const getSignificantEvents = () => {
		// check with significant flag when it is added to the data
		if (alerts && alerts.length < 1) {
			return 'No significant events yet';
		}
	};

	return (
		<div className='border border-gray-600 inline-block rounded-r-lg py-5 px-6 relative h-full'>
			{/*Include colors in code to prevent them from being purged by tailwind*/}
			<i
				className='hidden bg-mlk-red border-mlk-red bg-mlk-yellow border-mlk-yellow bg-mlk-green border-mlk-green bg-mlk-light-blue border-mlk-light-blue'
				aria-hidden
			/>
			<div className='flex space-x-2 items-center mb-4'>
				<img
					src={
						category === 'Health'
							? Cardiology
							: category === 'Credit'
							  ? Credit
							  : category === 'Environment'
							    ? Location
							    : World
					}
					alt='icon'
					className='w-7'
				/>
				<h3 className='uppercase font-semibold text-xl'>{category}</h3>
			</div>
			<div className='space-y-2 text-gray-300'>
				{!alerts
					? [...Array(2)].map((_, i) => <AlertCardSkeleton key={i} />)
					: alerts?.map((alert: any, index: number) => (
							<div
								className='flex space-x-2 items-start'
								key={index}
							>
								<div className='w-7 mt-0.5 flex-none flex items-center justify-center'>
									<div
										className={clsx(
											`border-${primaryColor}`,
											`bg-${primaryColor}`,
											'rounded-sm',
											'border-2 bg-opacity-50 w-4 h-4 flex-none',
										)}
									/>
								</div>
								<p
									className='text-sm hover:underline cursor-pointer'
									onClick={() => selectAlert(alert)}
								>
									{alert.location} - {alert.sub_type}{' '}
									{alert.change_direction === 'UP'
										? 'rose'
										: 'dropped'}{' '}
									from {alert.previous_rating} to{' '}
									{alert.current_rating}
								</p>
							</div>
					  ))}
				{getSignificantEvents() && <p>{getSignificantEvents()}</p>}
			</div>
			<div
				className={clsx(
					`bg-${primaryColor}`,
					'w-1.5 h-[calc(100%_+_2px)] absolute -top-[1px] -left-[1px] margin-0',
				)}
			/>
		</div>
	);
};

export default Tab;
