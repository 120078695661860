import React, { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Settings } from 'lucide-react';
import { Checkbox } from '../ui/checkbox';
import { Button } from '../ui/button';
import useDataImportStore, { ITableData } from '../../stores/dataImportStore';
import clsx from 'clsx';

type TProps = {
	isNullable?: string;
	isUnique?: string;
	isJsonB?: string;
	isIdentity?: string;
	id: number;
	pkey: string;
};
const AdditionalSettings = ({
	isIdentity,
	isJsonB,
	isNullable,
	isUnique,
	id,
	pkey,
}: TProps) => {
	const setValue = useDataImportStore(state => state.reset_table_data_value);
	const [open, setOpen] = useState(false);
	//useEffect(() => {}, [isIdentity, isNullable, pkey]);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger>
				<Settings
					className='w-6 h-6 text-mlk-light-blue hover:cursor-pointer'
					onClick={() => setOpen(true)}
				/>
			</PopoverTrigger>
			<PopoverContent
				className='w-[331px] bg-white rounded-[5px] p-5'
				side='left'
				align='start'
			>
				<div className='grid gap-4'>
					<div className='space-y-2'>
						<h4 className='leading-[22.22px] font-grotesk font-bold text-[18px]'>
							Column Options
						</h4>
					</div>
					{pkey == 'null' && isIdentity == 'null' && (
						<div className='items-top flex space-x-4'>
							<Checkbox
								onCheckedChange={e =>
									e
										? setValue(id, 'default', 'NULL')
										: setValue(id, 'default', 'NOT NULL')
								}
								defaultChecked={isNullable == 'NULL'}
								className='border-2 border-[#979797] checked:bg-mlk-light-blue checked:border-mlk-light-blue checked:text-white'
							/>
							<div className='grid gap-1 w-full'>
								<label className='font-grotesk font-bold text-sm leading-[17.28px]'>
									Is Nullable
								</label>
								<p className='font-grotesk font-normal text-xs leading-[13px]'>
									Specify if the column can assume a NULL
									value if no value is provided.
								</p>
							</div>
						</div>
					)}

					<div className='items-top flex space-x-4'>
						<Checkbox
							className=' border-2 border-[#979797]'
							defaultChecked={isUnique !== 'null'}
							onCheckedChange={e =>
								e
									? setValue(id, 'unique', 'UNIQUE')
									: setValue(id, 'unique', 'null')
							}
						/>
						<div className='grid gap-1'>
							<label
								//htmlFor='terms1'
								className='font-grotesk font-bold text-sm leading-[17.28px]'
							>
								Is Unique
							</label>
							<p className='font-grotesk font-normal text-xs leading-[13px]'>
								Enforce if values in the column should be unique
								across rows.
							</p>
						</div>
					</div>
					{isNullable == 'NOT NULL' && (
						<div className='items-top flex space-x-4'>
							<Checkbox
								className=' border-2 border-[#979797]'
								defaultChecked={isIdentity !== 'null'}
								onCheckedChange={e =>
									e
										? setValue(
												id,
												'identity',
												'generated by default as identity',
										  )
										: setValue(id, 'identity', 'null')
								}
							/>
							<div className='grid gap-1'>
								<label
									//htmlFor='terms1'
									className='font-grotesk font-bold text-sm leading-[17.28px]'
								>
									Is Identity
								</label>
								<p className='font-grotesk font-normal text-xs leading-[13px]'>
									Automatically assign a sequential unique
									number to the column.
								</p>
							</div>
						</div>
					)}

					{pkey == 'null' && isIdentity == 'null' && (
						<div className='items-top flex space-x-4'>
							<Checkbox
								className=' border-2 border-[#979797]'
								defaultChecked={isJsonB !== 'null'}
								onCheckedChange={e =>
									e
										? setValue(id, 'is_json', 'JSONB')
										: setValue(id, 'is_json', 'null')
								}
							/>
							<div className='grid gap-1'>
								<label
									//htmlFor='terms1'
									className='font-grotesk font-bold text-sm leading-[17.28px]'
								>
									Define as Array
								</label>
								<p className='font-grotesk font-normal text-xs leading-[13px]'>
									Define your column as a variable-length
									multidimentional array.
								</p>
							</div>
						</div>
					)}

					<div className='flex gap-2 items-center w-full'>
						<Button
							onClick={() => {
								// ['is_json', 'identity', 'unique', "nullable"].forEach(
								// 	(el: any) => setValue(id, el, 'null'),
								// );
								setOpen(false);
							}}
							variant='outline'
							className='border-mlk-light-blue w-[142px] h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
						>
							CANCEL
						</Button>
						<Button
							onClick={() => setOpen(false)}
							className='border-mlk-light-blue bg-mlk-light-blue text-white w-[141px] h-9 hover:bg-mlk-light-blue/90  rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
						>
							DONE
						</Button>
					</div>
				</div>
			</PopoverContent>
		</Popover>
	);
};

export default AdditionalSettings;
