import { ColumnDef } from '@tanstack/react-table';
import {
	IHistory,
	ILookupTableData,
	Inhabitant,
} from '../../types/index.types';
import Timestamp from '../timestamp';
import HistoryTimestamp from './history-timestamp';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import {
	ChevronRight,
	Loader2,
	MoreVertical,
	Plus,
	PlusCircleIcon,
} from 'lucide-react';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import { useState } from 'react';
import EditInhabitantModal from './edit-inhabitant-modal';
import {
	deleteInhabitant,
	fetchInhabitantById,
} from '../../requests/services/rate_your_day/inihabitants';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '../ui/alert-dialog';

export const LookupColumns: ColumnDef<ILookupTableData>[] = [
	{
		accessorKey: 'vioscore',
		header: 'VioScore™',
		//cell: ({ row }) => <HistoryTimestamp val={row.original.created_at} />,
		size: 120,
	},
	{
		accessorKey: 'first_name',
		header: 'First name',
		//cell: ({ row }) => <div className='pl-10'>{row.original.rating}</div>,
		size: 150,
	},
	{
		accessorKey: 'last_name',
		header: 'Last name',
		size: 150,
	},
	{
		accessorKey: 'dob',
		size: 150,
		header: 'DOB',
		cell: ({ row }) => (
			<span>
				{' '}
				{format(new Date(row.original.dob as string), 'dd/LL/yyyy')}
			</span>
		),
	},
	{
		accessorKey: 'gender',
		size: 100,
		header: 'Gender',
		cell: ({ row }) => (
			<span className='pl-4'>{row.original.gender.charAt(0)}</span>
		),
	},
	{
		id: 'history',
		header: 'History',
		size: 90,
		cell: ({ row }) => {
			const { id } = row.original;
			return (
				<Link
					to={`/ratingApp/history/${id}`}
					className='ml-2 rounded-full w-5 h-5 hover:bg-mlk-light-blue hover:text-mlk-dark-blue bg-transparent border-2 text-mlk-light-blue border-mlk-light-blue  p-1  flex items-center justify-center'
				>
					<ChevronRight className='w-5 h-5' strokeWidth={4} />
				</Link>
			);
		},
	},
	{
		id: 'actions',
		header: 'Add a Rating',
		cell: ({ row }) => {
			const { id } = row.original;
			const [open, setOpen] = useState(false);
			const { data, isSuccess } = fetchInhabitantById(id);
			const delete_mutation = deleteInhabitant(id);
			const [openDialog, setOpenDialog] = useState(false);
			return (
				<div className=' items-center justify-between flex pl-7'>
					<Link
						to={`/ratingApp/${id}`}
						className='rounded-full w-5 h-5 hover:bg-mlk-light-blue hover:text-mlk-dark-blue bg-transparent border-2 text-mlk-light-blue border-mlk-light-blue  p-1  flex items-center justify-center'
					>
						<Plus className='w-5 h-5' strokeWidth={4} />
						{/* <ChevronRight
							size={20}
							strokeWidth={2.5}
							className=' text-mlk-dark-blue'
						/> */}
					</Link>
					<>
						<DropdownMenu>
							<DropdownMenuTrigger asChild className='mr-5'>
								<Button
									variant='ghost'
									size='icon'
									className='h-7 w-7 p-1 hover:border-white/10 hover:border focus-visible:ring-1 rounded-full focus-visible:ring-white/10 focus-visible:border-white/10 border-white/10'
								>
									<span className='sr-only'>Open menu</span>
									<MoreVertical className='text-mlk-light-blue h-4 w-4' />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent
								className='bg-white text-black min-w-[5rem]'
								align='end'
								onCloseAutoFocus={e => e.preventDefault()}
							>
								<DropdownMenuItem onClick={() => setOpen(true)}>
									Edit
								</DropdownMenuItem>
								<DropdownMenuSeparator />
								<DropdownMenuItem
									onSelect={() => setOpenDialog(true)}
								>
									Delete
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
						<AlertDialog
							open={openDialog}
							onOpenChange={setOpenDialog}
						>
							<AlertDialogContent className='bg-white w-[342px]'>
								<AlertDialogHeader>
									<AlertDialogTitle className='text-balck'>
										Delete inhabitant?
									</AlertDialogTitle>
								</AlertDialogHeader>
								<AlertDialogDescription>
									{`Are you sure you want to delete ${row.original.first_name} ${row.original.last_name}?`}
								</AlertDialogDescription>
								<AlertDialogFooter className='items-center justify-items-center gap-2'>
									<AlertDialogCancel className=' w-[120px] rounded-[4px] text-mlk-light-blue  bg-white border-mlk-light-blue'>
										Cancel
									</AlertDialogCancel>
									<Button
										onClick={() => {
											delete_mutation.mutate();
											setTimeout(() => {
												setOpenDialog(false);
											}, 1000);
										}}
										className='text-white bg-mlk-light-blue  w-[120px] rounded-[4px] border-mlk-light-blue'
									>
										{delete_mutation.isPending ? (
											<Loader2 className='mr-2 h-4 w-4 animate-spin' />
										) : (
											'Continue'
										)}
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
					</>
					{data && isSuccess && (
						<EditInhabitantModal
							data={data!}
							id={row.original.id}
							open={open}
							setOpen={setOpen}
						/>
					)}
				</div>
			);
		},
	},
];
