import { AuthChangeEvent, Session, User } from '@supabase/supabase-js';
import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import supabase from '../utils/supabase';
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import useResetStore from '../stores/passwordResetStore';
import useInhabitantStore from '../stores/inhabitantStore';

const SupabaseAuthContext = createContext<{
	user: User | null;
	signOut: () => void;
}>({ user: null, signOut: () => {} });

export const useAuth = () => useContext(SupabaseAuthContext);

const SupabaseAuthProvider = ({ children }: { children: ReactNode }) => {
	const [user, setUser] = useState<User | null>(null);
	const [session, setSession] = useState<Session | null>(null);
	const [loading, setLoading] = useState(true);
	const updateId = useInhabitantStore(state => state.updateId);

	useEffect(() => {
		//Get and Set the user on initial load

		(async () => {
			const {
				data: { session },
				error,
			} = await supabase.auth.getSession();

			let currentUser: any;
			if (error) {
				throw error;
			} //window.location.replace('/login');

			if (session) {
				currentUser = session.user;
				//add custom role to user
				// const { data, error } = await supabase
				// 	.from('user_roles')
				// 	.select('role')
				// 	.eq('user_id', currentUser.id);
				// if (data) {
				// 	currentUser.appRole = data[0].role;
				// }
				// if (error) {
				// 	console.log(error);
				// }
				setLoading(false);

				setUser(currentUser);
				updateId(currentUser.id);
			}
		})();
		//Listen for auth state chnage

		const { data } = supabase.auth.onAuthStateChange((event, session) => {
			if (event == 'PASSWORD_RECOVERY') {
				//setSession(session);
				setUser(session?.user || null);
				setLoading(false);
				useResetStore.setState({ event: event });
				redirect('/password-reset');
			}
			setSession(session);
			setUser(session?.user || null);
			updateId(session?.user.id || '');
			setLoading(false);
		});
		return () => {
			data?.subscription.unsubscribe();
		};
	}, []);
	const signOut = async () => {
		const { error } = await supabase.auth.signOut();
		//	console.log('error: ', error);
		if (!error) {
			setUser(null);
			setSession(null);
			updateId('');
		}
	};

	return (
		<SupabaseAuthContext.Provider value={{ user, signOut }}>
			{!loading ? children : ''}
		</SupabaseAuthContext.Provider>
	);
};

export default SupabaseAuthProvider;
